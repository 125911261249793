<div class="col-md-12">
  <div class="row">
    <div class="form-group col-sm-6">
      <h1> Alumno/a: {{alumno.apellido}} {{alumno.nombre}} - Periodo: {{ mes }} / {{ anio }} </h1>
      <table mat-table [dataSource]="tableRows">
        <ng-container matColumnDef="Concepto" stickyEnd>
          <th mat-header-cell *matHeaderCellDef> <strong> Concepto </strong> </th>
          <td mat-cell *matCellDef="let element" [title]=gettoltip(element) [cdkCopyToClipboard]=gettoltip(element)> {{element.concepto}} </td>
          <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>
        <ng-container matColumnDef="Monto" >
          <th mat-header-cell *matHeaderCellDef> <strong> Monto </strong> </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="!element.editable || !pagar">
                <span>{{element.monto | currency}}</span>
              </ng-container>
              <ng-container *ngIf="element.editable && pagar">
                <input class="currency" (change)="calcularImporte(element, $event)" type="number" [(ngModel)]="element.monto"> 
              </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{ total | currency}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableHeaders"></tr>
        <tr mat-row *matRowDef="let row; columns: tableHeaders;"></tr>
        <tr mat-footer-row *matFooterRowDef="tableHeaders"></tr>
      </table>
    </div>
    <div class="form-group col-sm-6">
      <h1>Importes de la Factura</h1>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <input  matInput [(ngModel)]="pagado" placeholder="Importe pagado ($)"
              name="pagado" type="number"  id="pagado" readonly />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput [(ngModel)]="descuento" placeholder="Descuento ($)"
              name="descuento" type="number" id="descuento" (change)="descuentoChange()" />
          </mat-form-field>
        </div>
        <!-- <div class="col-md-3">
          <mat-form-field>
            <input matInput [(ngModel)]="intereses" placeholder="Intereses ($)"
              name="intereses" type="number" id="intereses" (change)="descuentoChange()" />
          </mat-form-field>
        </div> -->
        <div class="col-md-4">
          <mat-form-field>
            <input  matInput #importeaPagar="ngModel" [(ngModel)]="importePagar" placeholder="Importe a pagar ($)"
              name="importePagar" type="number"  id="importePagar" required />
          </mat-form-field>
          <div class="alert alert-danger" *ngIf="importeaPagar.touched && importeaPagar.invalid">
            Importe es requerido
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width">
            <textarea type="text" matInput placeholder="Descripción" maxlength="250" value="" [(ngModel)]="comentario"
              autocomplete="off"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row pull-right">
        <div class="action-buttons" style="margin-top: 15px;">
          <button type="button" class="btn btn-warning mr-2" (click)="cancelar()">Cancelar</button>
          <button type="button" class="btn btn-primary" *ngIf="pagar" (click)="pagarFactura()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
  
  