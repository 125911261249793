<div class="col-md-12" *ngIf="grado">
    <form #f="ngForm" (ngSubmit)="save()">
        <div class="row">
            <div class="form-group col-sm-6">
                <div class="form-group">
                    <label for="name">Nombre</label>
                    <input #nombre="ngModel" [(ngModel)]="grado.nombre" name="nombre" type="text" class="form-control"
                        id="nombre" required />
                    <div class="alert alert-danger" *ngIf="nombre.touched && nombre.invalid">
                        Nombre es requerido
                    </div>
                </div>
            </div>
            <div class="form-group col-sm-6">
                <label for="categoriaId">Categoria</label>
                <select #categoria="ngModel" [(ngModel)]="categoriaId" name="categoriaId" id="categoriaId"
                    class="form-control" required>
                    <option *ngFor="let c of categorias" [value]="c.id">{{ c.nombre }}</option>
                </select>
                <div class="alert alert-danger" *ngIf="categoria.touched && categoria.invalid">
                    Grado es requerido
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <div class="form-group">
                    <label for="descripcion">Descripcion</label>
                    <input #description="ngModel" [(ngModel)]="grado.descripcion" name="descripcion" type="text"
                        class="form-control" id="descripcion" required />
                </div>
            </div>
        </div>
    </form>
    <div class="action-buttons">
        <button type="button" class="btn btn-primary mr-2" (click)="save()">Guardar</button>
        <button type="button" class="btn btn-danger  mr-2" (click)="delete()">
            Eliminar
        </button>
        <button type="button" class="btn btn-warning mr-2" (click)="volver()">
            Volver
        </button>
    </div>

</div>