<section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/40280652.jpg);">
    <div class="login-box card">
        <div class="card-body">
            <form class="form-horizontal form-material" 
            id="loginform"
            autocomplete="off" 
            [formGroup]="registerForm"
            (ngSubmit)="crearUsuario()">
                <h2 class="text-center"><b>Sistema de Colegios</b></h2>
                <h3 class="box-title m-t-40 m-b-0">Creando su cuenta de sistema</h3>
                <div class="form-group m-t-20">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" placeholder="Nombre" formControlName="nombre">
                    </div>
                    <div class="col text-danger">
                        <p *ngIf="campoNoValido('nombre')">El usuario es requerido</p>
                    
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" placeholder="Email" formControlName="email">
                    </div>
                    <div class="col text-danger">
                        <p *ngIf="campoNoValido('email')">El email es requerido y debe ser válido</p>
                    
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" placeholder="Clave" formControlName="password" >
                    </div>
                    <div class="col text-danger">
                        <p *ngIf="campoNoValido('nombre')">El usuario es requerido</p>
                    
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" placeholder="Confirmar clave"  formControlName="password2">
                    </div>
                    <div class="col text-danger">
                        <p *ngIf="campoNoValido('nombre')">El usuario es requerido</p>
                    
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary p-t-0">
                            <input id="checkbox-signup" type="checkbox" formControlName="terminos">
                            <label for="checkbox-signup"> Acuerdo los terminos <a href="#">Terminos</a></label>
                        </div>
                    </div>
                </div> -->
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Crear cuenta</button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>Tiene ya una cuenta? <a routerLink="/login" class="text-info m-l-5"><b>Ingresar</b></a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>