
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericList } from 'src/app/models/list-item.model';
import { ConstService } from 'src/app/services/const.service';
@Component({
  selector: 'app-factura-afip-modal',
  templateUrl: './factura-afip-modal.component.html',
  styleUrls: ['./factura-afip-modal.component.scss']
})
export class FacturaAfipModalComponent implements OnInit, OnDestroy {
  documentosAfip: GenericList;
  formaDePago: GenericList;
  datos = {} as any;
  subscripciones = [];

  constructor(
    public dialogRef: MatDialogRef<FacturaAfipModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private constService: ConstService) { }

  ngOnInit() {
    this.documentosAfip = this.constService.getTipoDocumentoAfip();
    this.formaDePago = this.constService.getFormapago();
    this.datos.total = this.data.total;
    this.datos.formaPago = this.data.formaPago;
    this.datos.tipodocumento = this.data.tipodocumento;
    this.datos.nroDocumento = this.data.cuilTitular;
    this.datos.razonSocial = this.data.razonSocial;
  }

  aceptar() {
    this.dialogRef.close({ enviar: true, datos: this.datos});
  }

  cancelar() {
    this.dialogRef.close({ enviar: false, datos: this.datos });
  }

  ngOnDestroy() {
    this.subscripciones.forEach(s => s.unsubscribe());
  }
}
