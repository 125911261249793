import { NgModule } from '@angular/core';
import { CommonModule, formatCurrency } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatTableModule} from '@angular/material/table';
import { MatSortModule} from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';

import { TemplateModalComponent } from './lookup/template-modal/template-modal.component';
import { LookupComponent } from './lookup/lookup.component';


@NgModule({
  declarations: [
    LookupComponent,
    TemplateModalComponent
  ],
  exports: [
    LookupComponent,
    TemplateModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatNativeDateModule
  ]
})
export class ComponentsModule { }
