import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Categoria } from '../models/categoria.model';
import { BaseService } from './base.service';
import { FilterExpression, FilterValue, JoinExpression, Pager } from 'src/models/lookup.filter.data';
import { Observable } from 'rxjs';
import { EscuelaService } from './escuela.service';

@Injectable({providedIn: 'root'})
export class CategoriaService extends BaseService<Categoria> {
  escuela: any;
  public escuelaId = 0;
  constructor(protected http: HttpClient, private escuelaService: EscuelaService){
    super(http);
    this.options = { route : 'categorias' };
  }

  getCategoriasPorEscuela(): Observable<any> {
    const filter = new FilterExpression('AND',
      [new FilterValue('escuelaId', 'Equal', [ this.escuelaService.Escuela().id ])],
      new Pager(0, 100)
    );
    return super.getFileredList(filter);
  }

  getCategoriasPorEscuelaYId(id): Observable<any> {
    const filter = new FilterExpression('AND',
     //No es necesario, ya que se busca por pk [new FilterValue('Categoria.escuelaId', 'Equal', [ this.usuarioService.usuario.escuelaId ]),
      [new FilterValue('Categoria.Id', 'Equal', [ id ])],
      null,
      [new JoinExpression('CategoriaConcepto','Categoria.conceptos' ),
       new JoinExpression('Concepto', 'CategoriaConcepto.concepto' )]
    );
    return super.getFileredList(filter);
  }

 
}
