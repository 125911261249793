import { Categoria } from './../../../../models/categoria.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Grado } from 'src/app/models/grado.model';
import { GradoService } from 'src/app/services/grado.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Escuela } from 'src/app/models/escuela.model';
import { AlumnoService } from 'src/app/services/alumno.service';
import { EscuelaService } from 'src/app/services/escuela.service';

@Component({
	selector: 'app-grados-edit',
	templateUrl: './grados-edit.component.html',
	styleUrls: ['./grados-edit.component.css']
})
export class GradosEditComponent implements OnInit, OnDestroy {

	grado: Grado; // avoid getting null by setting to {}
	categorias: Categoria[] = [];
	id;
	categoriaId = 0;
	subscriptciones = [];
	constructor(private gradoService: GradoService,
		private categoriaService: CategoriaService,
		private alumnoService: AlumnoService,
		private router: Router,
		protected escuelaService: EscuelaService,
		private activatedRoute: ActivatedRoute) {

	 this.subscriptciones.push(this.categoriaService.getCategoriasPorEscuela()
		.subscribe(resp => {
			this.categorias = resp.resultList;
		}));
	}

	ngOnInit(): void {
		this.id = this.activatedRoute.snapshot.paramMap.get('id');
		if (this.id && this.id !== 'new') {
			this.subscriptciones.push(this.gradoService.getGradoPorId(this.id)
				.subscribe((resp: any) => {
					if(resp.total === 1){
						this.grado = resp.resultList[0];
						this.categoriaId = this.grado.categoria.id;
					}
				}));
		} else {
			this.grado = { nombre: '', descripcion: '', id: null, escuela: {} as Escuela, categoria: {} as Categoria };
			this.grado.escuela.id = this.escuelaService.Escuela().id;
		}
	}

	save() {
		this.grado.categoria.id = this.categoriaId;
		const _save$: Observable<Grado> = (this.id && this.id !== 'new') ?
			this.gradoService.update(this.grado) :
			this.gradoService.create(this.grado);

			this.subscriptciones.push(_save$.subscribe(resp => {
			this.router.navigate(["grados"]);
		},
			(err) => {
				Swal.fire('Error', err.error.msg, 'error');
			}));
	}

	delete() {
		if (confirm("Esta seguro de eliminar este grado?")) {
			this.subscriptciones.push(this.alumnoService.getAlumnosPorEscuelaYGradoCount(this.id).subscribe(result => {
				if(result.total == 0){
					this.subscriptciones.push(this.gradoService.delete(this.id).subscribe());
					Swal.fire('Informacion', 'El grado se elimino con exito.', 'info');
					this.router.navigate(["grados"]);
				}else{
					Swal.fire('Error', 'El grado todavia tiene alumnos activos.', 'error');
				}
			}));
		}
	}

	volver() {
		this.router.navigate(["grados"]);
	}

	ngOnDestroy(){
		this.subscriptciones.forEach(s => s.unsubscribe());
	}
}
