// truncate.ts
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ConstService } from '../services/const.service';

@Injectable({
	providedIn: 'root'
  })
@Pipe({
  name: 'getDescriptions'
})
export class getDescriptions implements PipeTransform {
  constructor(private constService: ConstService) {
  }

  transform(value: string, type: string): string {
    switch (type) {
      case 'generos':
        return this.constService.getGeneroDesc(Number(value));
      case 'estadofactura':
        return this.constService.getEstadoFacturaDesc(Number(value));
      case 'tipofactura':
        return this.constService.getTipoFacturaDesc(Number(value));
        case 'formaPago':
          return this.constService.getFormapagoDesc(Number(value));
    }
  }
}
