<div class="col-md-12">
    <form #f="ngForm"  class="form p-t-20" (ngSubmit)="save()">
        <div class="row">
            <div class="form-group col-sm-4">
                <mat-form-field>
                    <input matInput [ngModel]="ingresoEgreso.fecha | date:'yyyy-MM-dd'"
                        (change)="setFecha($event.target.value)" name="fecha" type="date"
                        placeholder="Fecha (mes/dia/año)" id="fecha" />
                </mat-form-field>
            </div>
            <div class="form-group col-sm-4">
                <mat-form-field>
                    <input matInput #montox="ngModel" [(ngModel)]="ingresoEgreso.monto" placeholder="Monto ($)" name="monto"
                        type="number" id="monto" required />
                </mat-form-field>
                <div class="alert alert-danger" *ngIf="montox.touched && montox.invalid">
                    Monto es requerido
                </div>
            </div>
            <div class="form-group col-sm-4">
				<mat-checkbox name="esEgreso" id="esEgreso" [(ngModel)]="ingresoEgreso.esEgreso">Es egreso?</mat-checkbox>
			</div>
        </div>
        <div class="row">
            <div class="form-group col-sm-4">
                <mat-form-field>
                    <mat-select placeholder="Grado" [(ngModel)]="gradoId" name="gradoId" id="gradoId" (selectionChange)="gradoChange($event.value)">
                      <mat-option  *ngFor="let c of grados" [value]="c.id" >  {{ c.nombre }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="form-group col-sm-4">
                <mat-form-field>
                    <mat-select placeholder="Alumno" [(ngModel)]="alumnoId" name="alumnoId" id="alumnoId">
                      <mat-option  *ngFor="let c of alumnos" [value]="c.id"> {{ c.apellido }} {{ c.nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="form-group col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Forma de Pago" #formaPago="ngModel" [(ngModel)]="ingresoEgreso.formaPago" name="formaPago" id="formaPago">
                      <mat-option *ngFor="let c of formaDePagoS.list" [value]="c.id"> {{ c.descripcion}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> 
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-4">
                <mat-form-field>
                    <input type="text" matInput #numerox="ngModel" [(ngModel)]="ingresoEgreso.numero"
                        placeholder="Numero de Comprobante" name="numero" id="numero" required />
                </mat-form-field>
                <div class="alert alert-danger" *ngIf="numerox.touched && numerox.invalid">
                    Numero de Comprobante es requerido
                </div>
            </div>
            <!-- <div class="form-group col-sm-4">
                <mat-form-field>
                    <input type="text" matInput #tipox="ngModel" [(ngModel)]="ingresoEgreso.tipo" placeholder="Nombre"
                        name="tipo" id="tipo" required />
                </mat-form-field>
                <div class="alert alert-danger" *ngIf="tipox.touched && tipox.invalid">
                    Nombre es requerido
                </div>
            </div> -->
            <div class="form-group col-sm-4">
                <mat-form-field>
                    <input type="text" matInput [(ngModel)]="ingresoEgreso.cuit" placeholder="Cuit/Cuil" name="cuit" type="text"
                        id="cuit" required />
                </mat-form-field>
            </div>
           
        </div>
        <div class="row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <textarea type="text" matInput placeholder="Descripción *" maxlength="500" name="descripcion" id="descripcion" [(ngModel)]="ingresoEgreso.descripcion"
                  autocomplete="off"></textarea>
                  
              </mat-form-field>
            </div>
          </div>
    </form>
    <div class="action-buttons">
        <button type="button" class="btn btn-primary mr-2" (click)="save()">Guardar</button>
        <button type="button" class="btn btn-warning mr-2" (click)="volver()">
            Volver
        </button>
    </div>

</div>