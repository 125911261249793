<div class="col-md-12">
    <form #f="ngForm" (ngSubmit)="save()">
        <div class="row">
            <div class="form-group col-sm-8">
                <label for="name">Nombre</label>
                <input #nombre="ngModel" [(ngModel)]="concepto.nombre" name="nombre" type="text" class="form-control"
                    id="nombre" required />
                <div class="alert alert-danger" *ngIf="nombre.touched && nombre.invalid">
                    Nombre es requerido
                </div>
            </div>
            <div class="form-group col-sm-4">
                <label for="limporte">Importe</label>
                <input #importe="ngModel" [(ngModel)]="concepto.importe" name="importe" type="number"
                    class="form-control" id="importe" required />
                <div class="alert alert-danger" *ngIf="importe.touched && importe.invalid">
                    Importe es requerido
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-4" style="margin-top: 25px;">
                <mat-checkbox name="esInscripcion" id="esInscripcion" [(ngModel)]="concepto.esInscripcion">Es
                    Inscripcion?</mat-checkbox>
            </div>
            <div class="form-group col-sm-4" style="margin-top: 25px;">
                <mat-checkbox name="modificable" id="modificable" [(ngModel)]="concepto.modificable">Es modificable?
                </mat-checkbox>
            </div>
            <div class="form-group col-sm-4" style="margin-top: 25px;">
                <mat-checkbox name="activo" id="activo" [(ngModel)]="concepto.activo">Activo?</mat-checkbox>
            </div>
        </div>
    </form>
    <div class="action-buttons">
        <button type="button" class="btn btn-primary mr-2" (click)="save()">Guardar</button>
        <button *ngIf="!concepto.activo" type="button" class="btn btn-danger  mr-2" (click)="reactivate()">
            Reactivar
        </button>
        <button *ngIf="concepto.activo" type="button" class="btn btn-danger  mr-2" (click)="inactivate()">
            Inactivar
        </button>
        <button type="button" class="btn btn-warning mr-2" (click)="volver()">
            Volver
        </button>
    </div>

</div>