<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-sm-10">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
            </mat-form-field>
          </div>
          <div class="form-group col-sm-2" style="margin-top: 15px">
            <button mat-flat-button color="primary" routerLink="new">
              <mat-icon>add</mat-icon>
              Nuevo Concepto
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-table #table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="nombre">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Nombre</b></mat-header-cell>
                <mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id><a [routerLink]="['/conceptos/', element.id]">{{
                    element.nombre
                    }}</a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="importe">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Importe</b></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.importe }}
                </mat-cell>
              </ng-container>
              <ng-container *ngIf="dataSource" matColumnDef="modificable">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Modificable</b></mat-header-cell>
                <mat-cell *matCellDef="let element">{{
                  element.modificable ? "Si" : "No"
                  }}</mat-cell>
              </ng-container>
              <ng-container *ngIf="dataSource" matColumnDef="inscripcion">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Es Inscripcion</b></mat-header-cell>
                <mat-cell *matCellDef="let element">{{
                  element.esInscripcion ? "Si" : "No"
                  }}</mat-cell>
              </ng-container>
              <ng-container *ngIf="dataSource" matColumnDef="activo">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Activo</b></mat-header-cell>
                <mat-cell *matCellDef="let element">{{
                  element.activo ? "Si" : "No"
                  }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                </td>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns">
              </mat-row>
            </mat-table>

            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>