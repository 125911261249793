import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AlumnoService } from '../../../../services/alumno.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { take } from 'rxjs/operators';
import { AlumnoList } from '../../../../interfaces/alumno.interface';
import { GradoService } from 'src/app/services/grado.service';

@Component({
	selector: 'app-alumnos-list',
	templateUrl: './alumnos-list.component.html',
	styleUrls: ['alumnos-list.component.scss']
})
export class AlumnosListComponent implements OnInit, AfterViewInit, OnDestroy {
	displayedColumns = ['nombre', 'apellido', 'legajo', 'grado'];
	dataSource = new MatTableDataSource<any>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	length = 0;
	pageIndex = 0;
	subscriptions = [];
	gradoId = 0;
	grados = [];
	stringFilter = '';
	inactivos = false;
	constructor(private alumnoService: AlumnoService, private gradosService: GradoService) { }

	ngOnInit() {
		this.subscriptions.push(this.alumnoService.getAlumnosPorEscuelaYFiltros(this.pageIndex, 10, '').subscribe((fs: AlumnoList) => {
			this.dataSource.data = fs.resultList;
			this.length = fs.total;
			this.paginator.length = this.length;
		}));

		this.gradosService.getGradosPorEscuelaNoPaging().pipe(take(1)).subscribe(c => this.grados = c.resultList);
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
	doFilter(){
		this.pageIndex = 0;
		this.paginator.pageIndex = this.pageIndex;
		this.applyFilter();
	}

  clearFilters(){

  }
	private applyFilter() {
		if(this.stringFilter && this.stringFilter.length > 2){
			//this.stringFilter = this.stringFilter.trim(); // Remove whitespace
			this.stringFilter = this.stringFilter.toLowerCase(); // MatTableDataSource defaults to lowercase matches
			this.getData(this.pageIndex, 10, this.stringFilter);
		}else{
			this.getData(this.pageIndex, 10, '');
		}
	}

	getData(pageIndex, pageSize, filter) {
		const dataFilter = {filterLike: filter, gradoId: this.gradoId, inactivos: this.inactivos};
		this.subscriptions.push(this.alumnoService.getAlumnosPorEscuelaYFiltros(pageIndex, pageSize, dataFilter).subscribe((fs: AlumnoList) => {
			this.dataSource = new MatTableDataSource<any>(fs.resultList);
			this.length = fs.total;
			this.paginator.length = this.length;
			this.dataSource._updateChangeSubscription();
		}));
	}
//https://nils-mehlhorn.de/posts/angular-material-pagination-datasource
	public getServerData(event?: PageEvent) {
		if(this.stringFilter && this.stringFilter.length > 2){
			this.pageIndex = event.pageIndex;
			this.applyFilter();
			return event;
		}else{
			this.getData((event.pageIndex + 1), event.pageSize, '');
			return event;
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
