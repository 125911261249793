<div class="row">
	<div class="col-md-12">
		<div class="row">
			<div class="col-md-2">
				<mat-form-field>
					<input matInput [(ngModel)]="filterLike" (keyup)="applyFilter($event.target.value)"
						placeholder="Filtrar" />
				</mat-form-field>
			</div>
			<div class="col-sm-2">
				<mat-form-field>
					<mat-select name="mes" id="mes" placeholder="Mes" (selectionChange)="cambiarMes($event.value)">
						<mat-option *ngFor="let c of meses.list" [value]="c.id"> {{ c.descripcion}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field>
					<mat-select name="anio" id="anio" (selectionChange)="cambiarAnio($event.value)" placeholder="Año">
						<mat-option *ngFor="let c of anios.list" [value]="c.id">{{ c.descripcion }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="form-group col-sm-2">
				<mat-form-field>
					<mat-select placeholder="Grado" [(ngModel)]="gradoId" name="gradoId" id="gradoId">
						<mat-option *ngFor="let c of grados" [value]="c.id"> {{ c.nombre}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="form-group col-sm-2" style="margin-top: 15px;">
				<mat-checkbox name="soloDeuda" id="soloDeuda" [(ngModel)]="solodeuda">Solo con deuda?</mat-checkbox>
			</div>
			<div class="col-sm-2" style="margin-top: 15px;">
        <button mat-flat-button color="accent" aria-label="Aplicar filtro" (click)="aplicarFiltro()" class="mr-2">
					<mat-icon>manage_search</mat-icon>
           Buscar
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-table #table [dataSource]="dataSource" matSort>
					<ng-container matColumnDef="mes">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Mes - Año</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.mes }} - {{ element.anio }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="pagado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Pagado</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.pagado | currency }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="total">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Total</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.total | currency }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="alumno">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Alumno</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.alumno.apellido }} {{ element.alumno.nombre }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="grado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Grado</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.alumno.grado.nombre }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="esInscripcion">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Es Inscripcion?</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{(element.esInscripcion ? 'Si' : 'No')}}
						</mat-cell>
					</ng-container>
					<!-- <ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let element">
							<button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="pagar(element)">Pagar</button>
						<button mat-menu-item (click)="cancelar(element)">Cancelar</button>
							</mat-menu>
						</td>
					</ng-container> -->
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>
				<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
					(page)="getServerData($event)" [(length)]="length">
				</mat-paginator>
			</div>
		</div>
		<div class="row pull-right">
			<div class="action-buttons" style="margin-top: 15px;">
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelPagina()">
					Exportar esta pagina a Excel
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelTodo()">
					Exportar todo a Excel
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelTodoAgrupadoAlumno()">
					Exportar Agrupado por alumno Excel
				</button>

			</div>
		</div>
	</div>
</div>
