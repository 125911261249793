<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <mat-table #table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="correo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header><b>EMail</b></mat-header-cell>
                        <mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id><a
                                [routerLink]="['/alumnos/', element.id]">{{element.email}}</a>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="nombre">
                        <mat-header-cell *matHeaderCellDef mat-sort-header><b>Nombre</b></mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.nombre }}
                        </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="escuela">
                        <mat-header-cell *matHeaderCellDef mat-sort-header><b>Escuela</b></mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.escuela.nombre }}
                        </mat-cell>
                    </ng-container> -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>

                <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                    [(length)]="length">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>