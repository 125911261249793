import { Grado } from './grado.model';

export class Alumno {

    //auditoria
    constructor(public id: number,public nombre: string,public apellido: string,public dni: string,public telefono1: string,public telefono2: string,public legajo: string,
       public fechaNacimiento: Date,public fechaIngreso: Date,public activo: boolean,public pasaGrado: boolean,public fechaMensaje: Date,public mensaje: string,
       public grado: Grado,public localidad: any, public genero: number, public escuelaId: number, public cuilTitular: string, public tipoDocumentoTitular: number,
       public titularFactura: string, public condificonIvaTitular: number, public direccion: string, public eMail: string)
    { }

}

export enum CondicionIva {
	inscripto = 1,
    consumidorFinal = 2,
    responsableMonotributo = 3,
    exento = 4
}




