<section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/40280652.jpg);">
    <div class="login-box card">
        <div class="card-body">
            <form class="form-horizontal"
                id="loginform"
                autocomplete="off"
                [formGroup]="loginForm"
                (submit)="login()">
                <h2 class="text-center"><b>Sistema de Colegios</b></h2>
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <mat-form-field>
                          <input matInput formControlName="email" type="text" required placeholder="Usuario" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                      <mat-form-field>
                        <input matInput formControlName="password" type="password" required placeholder="Clave" />
                      </mat-form-field>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary pull-left p-t-0">
                            <input id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue" formControlName="remember">
                            <label for="checkbox-signup"> Recordarme </label>
                        </div>
                        <!-- <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Olvido la clave?</a>
                        -->
                      </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-md btn-block text-uppercase" type="submit">
                            Ingresar
                        </button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        No tiene una cuenta? <a href="/register" class="text-primary m-l-5"><b>Registrarse</b></a>
                    </div>
                </div>
            </form>
            <form class="form-horizontal" id="recoverform" action="index.html">
                <div class="form-group ">
                    <div class="col-xs-12">
                        <h3>Recuperar clave</h3>
                        <p class="text-muted">Ingrese su email para recibir los pasos para recuperar su clave! </p>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                      <mat-form-field>
                        <input matInput type="text" placeholder="Email" />
                      </mat-form-field>
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-primary btn-md btn-block text-uppercase waves-effect waves-light" type="submit">Reiniciar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
