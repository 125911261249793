import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { ComponentsModule } from '../components/components.module';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PerfilComponent } from './perfil/perfil.component';
import { UsuariosComponent } from './maestros/usuarios/usuarios.component';
import { GradosListComponent } from './maestros/grados/list/grados-list.component';

import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { GradosEditComponent } from './maestros/grados/edit/grados-edit.component';
import { AlumnosListComponent } from './maestros/alumnos/list/alumnos-list.component';
import { AlumnosEditComponent } from './maestros/alumnos/edit/alumnos-edit.component';
import { DateInputCustomComponent } from './controls/date-input/date-input-custom.component';
import { EscuelaListComponent } from './maestros/escuela/list/escuela-list.component';
import { EscuelaEditComponent } from './maestros/escuela/edit/escuela-edit.component';
import { ConceptoListComponent } from './maestros/conceptos/list/concepto-list.component';
import { ConceptoEditComponent } from './maestros/conceptos/edit/concepto-edit.component';
import { FacturasalumnoComponent } from './facturas/facturasalumno/facturasalumno.component';
import { FacturaModalComponent } from './facturas/factura.modal/facturamodal.component';
import { CuentaCorrienteAlumnoComponent } from './facturas/cuentacorrientealumno/cuentacorrientealumno.component';
import { CategoriaEditComponent } from './maestros/categoria/editor/categoria-edit.component';
import { CategoriaListComponent } from './maestros/categoria/lista/categoria-list.component';
import { MesAnioModalComponent } from './controls/mesanio-modal/mesanio.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GenerarcuentaalumnoComponent } from './facturas/generarcuentaalumno/generarcuentaalumno.component';
import { MesanioComponent } from './controls/mesanio/mesanio.component';
import { FacturasListComponent } from './facturas/facturas-list/facturas-list.component';
import { FacturaAfipModalComponent } from './facturas/factura-afip-modal/factura-afip-modal.component';
import { MatDividerModule } from '@angular/material/divider';
import { AlumnosGradoComponent } from './reportes/alumnos-grado/alumnos-grado.component';
import { CuentasCorrientesComponent } from './facturas/cuentascorrientes/cuentascorrientes.component';
import { GradoalumnopasajeComponent } from './maestros/grados/acciones/gradoalumnopasaje.component';
import { getDescriptions } from '../pipes/getDescriptions';
import { IngresoEgresoListComponent } from './maestros/ingresoegreso/list/ingresoegreso-list.component';
import { IngresoEgresoEditComponent } from './maestros/ingresoegreso/edit/ingresoegreso-edit.component';
import { ActualizarSaldosModalComponent } from './maestros/categoria/actualizarSaldos.modal/actualizarsaldosmodal.component';
import {MatRippleModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LoaderComponent } from '../shared/loader/loader.component';
import { AgGridModule } from 'ag-grid-angular';
import { RemoteGridBindingDirective } from './controls/ag-grid/remote-grid-binding.directive';

@NgModule({
  declarations: [
    DashboardComponent,
    PagesComponent,
    AccountSettingsComponent,
    PerfilComponent,
    UsuariosComponent,
    GradosListComponent,
    GradosEditComponent,
    AlumnosListComponent,
    AlumnosEditComponent,
    DateInputCustomComponent,
    EscuelaListComponent,
    EscuelaEditComponent,
    ConceptoListComponent,
    ConceptoEditComponent,
    FacturasalumnoComponent,
    CuentaCorrienteAlumnoComponent,
    CuentasCorrientesComponent,
    FacturaModalComponent,
    CategoriaEditComponent,
    CategoriaListComponent,
    MesAnioModalComponent,
    GenerarcuentaalumnoComponent,
    MesanioComponent,
    FacturasListComponent,
    AlumnosGradoComponent,
    GradoalumnopasajeComponent,
    getDescriptions,
    FacturaAfipModalComponent,
    IngresoEgresoListComponent,
    IngresoEgresoEditComponent,
    ActualizarSaldosModalComponent,
    LoaderComponent,
    RemoteGridBindingDirective
  ],
  exports: [
    DashboardComponent,
    PagesComponent,
    AccountSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule ,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRippleModule,
    ClipboardModule,
    AgGridModule.withComponents([])
  ],
  entryComponents:[FacturasalumnoComponent, MesAnioModalComponent]
})
export class PagesModule { }
