import { Factura, TipoFactura } from './../../../models/factura.model';
import { SnackBarService } from './../../../services/snackBar.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { CategoriaConcepto } from '../../../models/categoria-concepto.model';
import { CuentaCorrienteService } from '../../../services/cuentacorriente.service';
import { CuentaCorriente, CuentaCorrienteConcepto } from '../../../models/cuentacorriente.model';
import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { map, mergeMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Alumno } from 'src/app/models/alumno.model';
import { MesAnioModalComponent } from '../../controls/mesanio-modal/mesanio.component';

import { config, of } from 'rxjs';
import { FacturaModalComponent } from '../factura.modal/facturamodal.component';
import { GenericList } from 'src/app/models/list-item.model';
import { ConstService } from 'src/app/services/const.service';
import { Categoria } from 'src/app/models/categoria.model';
import { Configuracion, ConfiguracionesNombre } from 'src/app/models/configuracion.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FacturaService } from 'src/app/services/factura.service';
import { isObject } from 'lodash';
@Component({
  selector: 'app-cuentacorrientealumno',
  templateUrl: './cuentacorrientealumno.component.html',
  styleUrls: ['./cuentacorrientealumno.component.css']
})
export class CuentaCorrienteAlumnoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() alumno = {} as Alumno;
  _actualizarCC: CuentaCorriente;
  get allowDay(): CuentaCorriente {
    return this._actualizarCC;
  }
  @Input() set actualizarCC(value: CuentaCorriente) {
    if (value && value !== null) {
      this._actualizarCC = value;
      this.actualizarAnulacionCC();
    }
  }

  displayedColumns = ['mes', 'pagado', 'total', 'esInscripcion', 'id'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() agregarFactura: EventEmitter<Factura> = new EventEmitter();
  @Output() actualizarAlumno: EventEmitter<Alumno> = new EventEmitter();
  ccsAlumno = [];
  conceptos = [] as CategoriaConcepto[];
  subscriptions = [];
  anios = new GenericList();
  anioSelected = '';
  constructor(private cuentaCorrienteService: CuentaCorrienteService, private dialog: MatDialog,
    private constService: ConstService, private categoriaService: CategoriaService, private facturaService: FacturaService,
    private snackBarService: SnackBarService) { }

  ngOnInit(): void {
    this.anios = this.constService.getAnios();
    if (this.alumno.id > 0) {
      this.subscriptions.push(this.cuentaCorrienteService.getCCPorAlumno(this.alumno.id, 0, 0).pipe(take(1)).subscribe(result => {
        this.ccsAlumno = result.resultList;
        this.setDataSource();
      }));
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setDataSource() {
    this.dataSource.data = this.ccsAlumno;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: any, filter: string) => !filter || data.anio == parseInt(filter);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  filtrarAnio(value) {
    this.anioSelected = value;
    this.dataSource.filter = this.anioSelected;
  }

  chooseColor(row) {
    if (Number(row.pagado) < Number(row.total)) {
      return "#d50b0b" 
    } else
      return "#rgba(0, 0, 0, 0)"
  }

  ver(cc: CuentaCorriente) {
    const dialogRef = this.dialog.open(FacturaModalComponent, {
      height: '80%',
      width: '80%',
      data: { cc, alumno: this.alumno, pagar: false }
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
    }));
  }
  pagar(cc: CuentaCorriente) {
    if (Number(cc.total) > Number(cc.pagado)) {
      const dialogRef = this.dialog.open(FacturaModalComponent, {
        height: '80%',
        width: '80%',
        data: { cc, alumno: this.alumno, pagar: true }
      });

      this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
        if (result.status === true) {
          const index = this.ccsAlumno.findIndex(cca => cca.id === cc.id);
          if (index > -1) {
            this.ccsAlumno[index].pagado = result.cc.pagado;
          }
          if (result.actualizarAlumno) {
            this.actualizarAlumno.emit(this.alumno);
          }
          this.snackBarService.openSnackBar(result.mensaje, 'Facturas');
          result.factura = result.factura;
          this.agregarFactura.emit(result.factura);
          //aca hay que ver de validar que no vuelva a pagar de nuevo lo mismo, mas porque si falla afip lo apretan de nuevo.
        }
        else {
          Swal.fire(result.mensaje,'error');
        }
      }));
    } else {
      this.snackBarService.openSnackBar('Ya se encuentra todo pagado!.', 'Facturas');
    }
  }
  imprimir(cc) {
  }
  cancelar(cc) {
  }

  actualizarSaldos(cc: CuentaCorriente) {
    let total = 0;
    this.subscriptions.push(this.facturaService.getFacturaPorCC(cc.id).subscribe(facturas => {
      total = facturas.resultList.map(t => (t.tipo === TipoFactura.notaCredito ? (Number(t.monto) * -1) : Number(t.monto))).reduce((acc, value) => acc + Number(value), 0);
      if (total !== Number(cc.pagado)) {
        const confirmOp = {
          title: "Confirmación",
          text: `¿Esta seguro de actualizar el saldo? El monto pagado actual es: $ ${cc.pagado} y la suma de facturas activas da un total de : $ ${total}`,
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        } as SweetAlertOptions;
        Swal.fire(confirmOp)
          .then((result) => {
            if (result.isConfirmed) {
              cc.pagado = total;
              this.subscriptions.push(this.cuentaCorrienteService.update(cc).subscribe((result: any) => {
                if (result.ok === true) {
                  this.snackBarService.openSnackBar('Se actualizo el monto pagado con exito!', 'Saldos');
                }
              }));
            }
          });
      } else {
        this.snackBarService.openSnackBar('Los montos son iguales!', 'Saldos');
      }
    }));
  }

  generarCuenta() {
    const dialogRef = this.dialog.open(MesAnioModalComponent, {
      height: '300px',
      width: '500px'
    });

    let categoriaSubs = this.categoriaService.getCategoriasPorEscuelaYId(this.alumno.grado.categoria.id);
    let closeSubscribe = dialogRef.afterClosed();
    this.subscriptions.push(closeSubscribe.pipe(
      mergeMap(resp => {
        if (resp.action === 'ok') {
          return categoriaSubs.pipe(
            map((result: any) => {
              if (!result.resultList || result.resultList?.length === 0 || (result.resultList[0] as Categoria).conceptos.length === 0) {
                this.snackBarService.openSnackBar('Este alumno tiene una categoria que no tiene conceptos!', 'Alumno');
              } else {
                return {
                  status: true,
                  conceptos: (result.resultList[0] as Categoria).conceptos.map(conceptoCategoria => {
                    // return new CuentaCorrienteConcepto(null, conceptoCategoria.importe, {id: conceptoCategoria.id});
                    return { monto: conceptoCategoria.importe, categoriaConcepto: { id: conceptoCategoria.id } };
                  }),
                  resp
                }
              }

            })
          )
        } else {
          let conceptos = [] as CuentaCorrienteConcepto[];
          return of({
            status: false,
            conceptos,
            resp
          });
        }
      })
    ).subscribe(r => {
      let totalConceptos = 0;
      r.conceptos.forEach(c => {
        totalConceptos += Number(c.monto);
      })
      if (r.status) {
        const ccs = [] as CuentaCorriente[];
        let desde = r.resp.result.desde as Date;
        const configuracion = JSON.parse(localStorage.getItem('configuracion')) as Configuracion[];
        const indexMesInscripcion = configuracion.findIndex(c => c.nombre === ConfiguracionesNombre[ConfiguracionesNombre.MesInscripcion]);
        let mesInscripcion = -1;
        if (indexMesInscripcion > -1) {
          mesInscripcion = parseInt(configuracion[indexMesInscripcion].valor);
        }
        for (let i = 0; i <= r.resp.result.meses; i++) {
          let mes = (desde.getMonth() + 1);
          let anio = desde.getFullYear();
          if (this.verificarExistenciaCC(mes, anio) === false) {
            ccs.push({
              // ver de el caso de subscripcion.
              id: null, mes, anio, fecha: new Date(),
              total: totalConceptos, pagado: 0, activo: true, comentario: '', esInscripcion: (mesInscripcion === mes), alumno: { id: this.alumno.id },
              escuelaId: this.alumno.escuelaId, conceptos: r.conceptos
            });
          }
          //proximo mes.
          desde = new Date(desde.setMonth(desde.getMonth() + 1));
        }
        if (ccs.length > 0) {
          this.subscriptions.push(this.cuentaCorrienteService.createCCs(ccs).subscribe((rccs: any) => {
            this.ccsAlumno = rccs.result;
            this.subscriptions.push(this.cuentaCorrienteService.getCCPorAlumno(this.alumno.id, 0, 0).pipe(take(1)).subscribe(result => {
              this.ccsAlumno = result.resultList;
              this.setDataSource();
            }));

            this.snackBarService.openSnackBar('Se genero la cuenta corriente con éxito', 'Alumno');
          }));
        }
      }
    }));
  }

  puedePagar(factura) {
    return Number(factura.total) > Number(factura.pagado);
  }
  verificarExistenciaCC(mes, anio) {
    return this.ccsAlumno.findIndex(cc => cc.mes === mes && cc.anio === anio) > -1;
  }

  actualizarAnulacionCC() {
    const index = this.ccsAlumno.findIndex(cc => cc.id === this._actualizarCC.id);
    if (index > -1) {
      this.ccsAlumno[index].pagado = this._actualizarCC.pagado;
    }
  }
}
