import { Alumno } from './../../../models/alumno.model';
import { EstadoFactura, FacturaList } from './../../../models/factura.model';
import { CategoriaConcepto } from './../../../models/categoria-concepto.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FacturaService } from '../../../services/factura.service';
import { FacturaReporteService } from '../../../services/factura-reporte.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ImpresionService } from 'src/app/services/impresion.service';
import { Factura } from 'src/app/models/factura.model';
import { Escuela } from 'src/app/models/escuela.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlumnoService } from 'src/app/services/alumno.service';
import { AlumnoList } from 'src/app/interfaces/alumno.interface';
@Component({
  selector: 'app-facturas-list',
  templateUrl: './facturas-list.component.html',
  styleUrls: ['./facturas-list.component.css']
})
export class FacturasListComponent implements OnInit {
  displayedColumns = ['cae', 'numero', 'mes', 'alumno', 'montopagar', 'pagado', 'fechapago', 'formaPago', 'tipo', 'estado', 'comentario'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  facturas = [];
  conceptos = [] as CategoriaConcepto[];
  subscriptions = [];
  pageIndex = 0;
  pageSize = 0;
  length=0;
  desde = new Date();
  hasta = new Date();
  filtroCae = '';
  inputAlumno = new FormControl();
  filteredAlumno: Observable<Alumno[]>;
  escuela: Escuela;
  alumnos: Array<Alumno> = [];
  alumnoSelectedId = 0;
  constructor(private facturaService: FacturaService, private impresionService: ImpresionService, private facturaReporteService: FacturaReporteService,
     private alumnoService: AlumnoService) { }

  ngOnInit(): void {
    this.escuela = JSON.parse(localStorage.getItem('escuela')) as Escuela;
    this.filteredAlumno = this.inputAlumno.valueChanges
		.pipe(
		  startWith(''),
		  map(value => this._filter(value))
		);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private _filter(value: string): Alumno[] {
		if(typeof value === 'string') {
			const filterValue = value.toLowerCase();
			return this.alumnos.filter(p => p.nombre.toLowerCase().includes(filterValue) || p.apellido.toLowerCase().includes(filterValue) || p.legajo.toLowerCase().includes(filterValue));
		}
	}

  displayFn(option) {
    if(option)
      return `${option.apellido} ${option.nombre}`;
    else return '';
    }

    
  doFilterAlumnos(){
		if(this.inputAlumno.value && this.inputAlumno.value.length > 3){
			let stringFilter = this.inputAlumno.value.toLowerCase(); // MatTableDataSource defaults to lowercase matches
			this.getData(this.pageIndex, 20, stringFilter);
		}
	}

  getData(pageIndex, pageSize, filter) {
		const dataFilter = {filterLike: filter, gradoId: 0, inactivos: false};
		this.subscriptions.push(this.alumnoService.getAlumnosPorEscuelaYFiltros(pageIndex, pageSize, dataFilter).subscribe((fs: AlumnoList) => {
			this.alumnos = fs.resultList;
		}));
	}

  alumnoSelect(alumno){
		this.alumnoSelectedId = alumno.value.id;
	}

  aplicarFiltro(){
    if(!!this.inputAlumno.value){

    }else{
      this.alumnoSelectedId = 0;
    }
    this.subscriptions.push(this.facturaService.getFacturaEscuelaYFiltro(this.escuela.id, this.pageIndex, this.pageSize, this.filtroCae.trimRight().trimLeft(), 
    new Date(this.desde), new Date(this.hasta), false, this.alumnoSelectedId).subscribe((fs: FacturaList) => {
      this.dataSource = new MatTableDataSource<any>(fs.resultList);
      this.length = fs.total;
			this.dataSource._updateChangeSubscription();
		}));
	}

  imprimir(fc: Factura) {
    this.subscriptions.push(this.facturaService.getFacturaPorIdConRelaciones(fc.id).subscribe(result => {
      if(result.resultList.length === 1){
        this.impresionService.generatePDF('', result.resultList[0], {});
      }

    }));
  }

  anular(fc: Factura) {
    if (confirm('Esta seguro de anular esta factura?')) {
      fc.fechaAnulacion = new Date();
      fc.estado = EstadoFactura.anulada;

      if (confirm('Desea actualizar la cuenta corriente para este periodo?')) {
        let cuenta = fc.cuentaCorriente;
        cuenta.pagado = Number(cuenta.pagado) - Number(fc.monto);
      }else{

      }
    }
  }

  getServerData(event?:PageEvent){
    this.pageIndex =  event.pageIndex + 1;
    this.pageSize =  event.pageSize + 1;
		this.aplicarFiltro();
		return event;
  }
  desdeChange(evento: Date){
		this.desde = evento;
  }
  hastaChange(evento: Date){
		this.hasta = evento;
  }

  // Reporte que da un listado de facturas con el filtro aplicado.
  getExcelFacturas(){
    this.facturaReporteService.getExcelFacturas(this.escuela.id, this.filtroCae,  new Date(this.desde), new Date(this.hasta), true, this.alumnoSelectedId);
  }
  // Reporte que da un listado de facturas con deuda con el filtro aplicado.
  getExcelFacturasConDeuda(){
    this.facturaReporteService.getExcelFacturasConDeuda(this.escuela.id, this.filtroCae, new Date(this.desde), new Date(this.hasta), true);
  }
  // Reporte que da un listado de facturas agrupdas por alumno con el filtro aplicado.
  getExcelReporteAgrupadoAlumno(){
    //this.cuentaCorrienteService.getCuentaCorrienteEscuelaYFiltroConDeuda(this.escuela.id, this.filtroTexto, new Date(this.desde), new Date(this.hasta), true);
  }

  // Reporte que da un listado de facturas a futuro, lo que no tiene factura es una cc sin pagar.
  getExcelAranceles(){
    this.facturaReporteService.getExcelAranceles(this.escuela.id, new Date(this.desde).getFullYear());
  }

  getExcelCajaFechas(){
    this.facturaReporteService.getExcelFacturasIngresoEgreso(this.escuela.id, this.filtroCae, new Date(this.desde), new Date(this.hasta), true, false);
  }

  getExcelFacturasCajaDiaria(){
    let desde = new Date(this.desde);
    // desde = startOfDay(desde);
    // let hasta = endOfDay(desde);
    // let hasta = desde.setHours(23);
    // hasta = desde.setMinutes(59);
    // hasta = desde.setSeconds(59);
    // const d = new Date(hasta);
    //let fecha = this.getDate(this.desde);
    this.facturaReporteService.getExcelFacturasIngresoEgreso(this.escuela.id, this.filtroCae, desde.toISOString().substring(10,0), null, true, true);
  }

  getDate(fecha){
    var targetTime = new Date(fecha);
    //get the timezone offset from local time in minutes
    var tzDifference = targetTime.getTimezoneOffset();
    //convert the offset to milliseconds, add to targetTime, and make a new Date
    var offsetTime = new Date(targetTime.getTime() - tzDifference * 60 * 1000);

    return offsetTime;
  }


  // getExcelFacturasIngresoEgresoDia(){
  //   const fechadesde = new Date(this.desde);
  //   let fecha2 = fechadesde.setHours(0);
  //   fecha2 = fechadesde.setMinutes(0);
  //   fecha2 = fechadesde.setSeconds(1);
  //   let hasta = fechadesde.setHours(23);
  //   hasta = fechadesde.setMinutes(59);
  //   hasta = fechadesde.setSeconds(59);
  //   const fh = new Date(hasta);
  //   const fd = new Date(fecha2);
  //   this.facturaReporteService.getExcelFacturasIngresoEgreso(this.escuela.id, this.filtroTexto, fd, fh, true);
  // }

  // getDate(fecha){
  //   var targetTime = new Date(fecha);
  //   //get the timezone offset from local time in minutes
  //   var tzDifference = targetTime.getTimezoneOffset();
  //   //convert the offset to milliseconds, add to targetTime, and make a new Date
  //   var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
  //   return offsetTime;
  // }
}
