import { environment } from 'src/environments/environment';

const base_url = environment.base_url;

export class Usuario {

    constructor(public nombre: string,
                public email: string,
                public img: string,
                public password: string,
                public google: string,
                public rol: string,
                public id: string,
                public escuelaId: number
        ) { }

        get imageUrl(): string {


        if (this.img){
            if (this.img.includes('https')){
                return this.img;
            }
            return  `${base_url}/upload/usuarios/${this.img}`;
        }
        return  `${base_url}/upload/usuarios/no-image`;

    }
}