<div class="container col-md-12">
  <div class="row">
    <div class="form-group col-sm-10">
      <mat-form-field>
        <mat-select
          placeholder="Grado"
          #gradoIds="ngModel"
          [(ngModel)]="gradoId"
          name="gradoId"
          id="gradoId"
        >
          <mat-option *ngFor="let c of grados" [value]="c.id">
            {{ c.descripcion }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <small
        class="text-danger font-14"
        *ngIf="gradoIds.touched && gradoIds.invalid"
      >
        Grado es requerido</small
      >
    </div>
    <div class="form-group col-sm-2" style="margin-top: 15px">
      <button mat-flat-button color="primary" (click)="generarReporte()">
        <mat-icon>add</mat-icon>
        Generar Reporte
      </button>
    </div>
  </div>
</div>
