<div class="row">
	<div class="col-md-12">
		<div class="row" style="margin-top: 8px;">
			<div class="form-group col-sm-2">
				<mat-form-field>
					<input matInput [(ngModel)]="filtroCae" placeholder="CAE" />
				</mat-form-field>
			</div>

			<div class="form-group col-sm-2">
				<mat-form-field>
					<input matInput placeholder="Alumno" [matAutocomplete]="auto" [formControl]="inputAlumno"
						(keyup)="doFilterAlumnos()" />
				</mat-form-field>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="alumnoSelect($event.option)"
					[displayWith]="displayFn">
					<mat-option *ngFor="let option of alumnos" [value]="option">
						{{option.apellido}} {{option.nombre}} ({{option.legajo}})
					</mat-option>
				</mat-autocomplete>
			</div>
			<div class="form-group col-sm-2">
				<mat-form-field>
					<input matInput [(ngModel)]="filtroCae" placeholder="CAE" />
				</mat-form-field>
			</div>
			<div class="form-group col-sm-2">
				<mat-form-field>
					<input matInput [ngModel]="desde | date:'yyyy-MM-dd'" (change)="desdeChange($event.target.value)"
						name="desde" type="date" placeholder="Desde" id="desde" />
				</mat-form-field>
			</div>
			<div class="form-group col-sm-2">
				<mat-form-field>
					<input matInput placeholder="Hasta" [ngModel]="hasta | date:'yyyy-MM-dd'"
						(change)="hastaChange($event.target.value)" name="hasta" type="date" id="hasta" />
				</mat-form-field>
			</div>
			<div class="col-sm-2">
				<button mat-flat-button color="accent" aria-label="Aplicar filtro" (click)="aplicarFiltro()"
					class="mr-2">
					<mat-icon>manage_search</mat-icon>
					Buscar
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-table #table [dataSource]="dataSource" matSort>
					<ng-container matColumnDef="cae">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Cae</b></mat-header-cell>
						<mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id>{{ element.cae }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="numero">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Numero</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.numero }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="mes">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Mes/Año</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.mes }} / {{ element.anio }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="alumno">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Alumno</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.alumno.apellido }} {{ element.alumno.nombre }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="montopagar">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>A Pagar</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.cuentaCorriente.total | currency }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="pagado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Pagado</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.monto | currency }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="fechapago">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha Pago</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.fechaPago | date:"dd/MM/yyyy":"+3" }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="formaPago">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Forma Pago</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.formaPago | getDescriptions:'formaPago' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="tipo">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Tipo</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.tipo | getDescriptions:'tipofactura' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="estado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Estado</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.estado | getDescriptions:'estadofactura' }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="comentario">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Comentario</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.comentario }}
						</mat-cell>
					</ng-container>
					<!-- <ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let element">
							<button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="imprimir(element)">Imprimir</button>
								<button mat-menu-item (click)="anular(element)">Anular</button>
							</mat-menu>
						</td>
					</ng-container> -->
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>
				<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
					(page)="getServerData($event)" [(length)]="length">
				</mat-paginator>
			</div>
		</div>
		<div class="row">

		</div>
		<div class="row pull-right" style="margin-top: 15px;">
			<div class="action-buttons">
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelFacturas()">
					<!-- <mat-icon>grid_on</mat-icon> -->
					Exportar a Excel
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelAranceles()">
					Excel Aranceles
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelFacturasConDeuda()">
					Excel de Facturas con Deuda
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelFacturasCajaDiaria()">
					Excel de Caja diaria (F.desde)
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="getExcelCajaFechas()">
					Excel de Caja
				</button>
				<!-- <button type="button" class="btn btn-warning mr-2" (click)="getExcelFacturasFuturo()">
				Excel de Deuda a Futuro
			  </button> -->
			</div>
		</div>
	</div>
</div>