import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GenericList } from 'src/app/models/list-item.model';
import { AlumnoService } from 'src/app/services/alumno.service';
import { ConstService } from 'src/app/services/const.service';
import { FunctionService } from 'src/app/services/common/functions.service';
import { CuentaCorrienteService } from 'src/app/services/cuentacorriente.service';
import { Observable, of, pipe } from 'rxjs';
import { ProcessInfo } from 'src/app/models/processInfo.model';
import { delay, mergeMap, pluck, repeat, take, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generarcuentaalumno',
  templateUrl:'./generarcuentaalumno.component.html',
  styleUrls: ['./generarcuentaalumno.component.css']
})
export class GenerarcuentaalumnoComponent implements OnInit, OnDestroy {
  @ViewChild('processLog') processLog: ElementRef;
  mesSelectedHasta = 1;
  anioDesde = new Date().getFullYear();
  // anioSelectedHasta = new Date().getFullYear();
  mesDesde = new Date().getMonth() + 1;
  anios = new GenericList();
  meses = new GenericList();
  cantidad = 0;
  cantidadMeses = 0;
  processId: number = 0;
  lastIdProcessLog?: number = null;
  totalCcs = 0;
  subscriptions = [];
  polling: Observable<ProcessInfo[]>;
  constructor(private constService: ConstService, private alumnoService: AlumnoService, private functionService: FunctionService,
    private cuentaCorrienteService: CuentaCorrienteService) { }

  ngOnDestroy(): void {
     if (this.subscriptions)
      this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
    this.anios = this.constService.getAnios();
    this.meses = this.constService.getMeses(false);
    this.alumnoService.getAlumnosPorEscuelaCount().pipe(take(1)).subscribe(result => {
      this.cantidad = Number(result.total);
    });

    // this.polling = of<ProcessInfo[]>([])
    //   .pipe(
    //   mergeMap(() => this.cuentaCorrienteService.getMessageLog<ProcessInfo[]>(this.processId, this.lastIdProcessLog)
    //   .pipe(delay(2000))),
    //   pluck('resultList'),
    //   tap((process: ProcessInfo[]) => {
    //         process.forEach(x => {
    //           this.processLog.nativeElement.value += x.message + '\n';
    //           if (!this.lastIdProcessLog || x.id > this.lastIdProcessLog){
    //             this.lastIdProcessLog = x.id;
    //           }
    //         });
    //     }
    //   ),
    //   delay(1000),
    //   //takeWhile(x => x.filter(x => x.completed).length > 0)
    //   repeat()
    // );
  }
   generarSaldos(){
    this.processLog.nativeElement.value = '';
      this.cuentaCorrienteService.generarPorPeriodo(this.mesDesde, this.anioDesde)
        .pipe(take(1))
        .subscribe((result: any) => {
            if (result.ok) {
              this.processLog.nativeElement.value = result.resultLog;
              Swal.fire('Informacion','Proceso finalizado con exito', 'info');
            } else {
              Swal.fire('Error',result.message, 'error');
            }
      },
      (err) => {
        Swal.fire('Error', err.error.message, 'error');
      });
    // }else{
    //   alert('El periodo desde debe ser mayor al periodo hasta!.');
    // }
  }
}
