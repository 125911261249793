<div class="row">
	<div class="col-md-12">
		<mat-form-field>
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
		</mat-form-field>
		<mat-table #table [dataSource]="dataSource" matSort>
			<!-- <ng-container matColumnDef="fecha">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha</b></mat-header-cell>
				<mat-cell *matCellDef="let element"><a [routerLink]="['/admin/cajas/', element._id]">{{ element.fecha | date:'shortDate' }}</a>
				</mat-cell>
			</ng-container> -->
			<ng-container matColumnDef="nombre">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Nombre</b></mat-header-cell>
				<mat-cell *matCellDef="let element"><a [routerLink]="['/escuelas/', element.id]">{{ element.nombre }}</a>
				</mat-cell>
            </ng-container>
            <ng-container matColumnDef="cuit">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Cuit</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.cuit }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="telefono1">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Telefono 1</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.telefono1 }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef> </th>
				<td mat-cell *matCellDef="let element">
				</td>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>

		<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
		</mat-paginator>
	</div>
</div>
