import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Alumno } from 'src/app/models/alumno.model';
import { AlumnoService } from 'src/app/services/alumno.service';
import { GenericList } from 'src/app/models/list-item.model';
import { ConstService } from '../../../../services/const.service';
import { GradoService } from '../../../../services/grado.service';
import { Grado } from '../../../../models/grado.model';
import { take } from 'rxjs/operators';
import { EscuelaService } from 'src/app/services/escuela.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
@Component({
	selector: 'app-alumnos-edit',
	templateUrl: './alumnos-edit.component.html',
	styleUrls: ['./alumnos-edit.component.scss']
})
export class AlumnosEditComponent implements OnInit, OnDestroy {

	alumno: Alumno; // avoid getting null by setting to {}
	id;
	grados = [];
	categorias = [];
	generos = new GenericList();
	gradoId = 0;
	subscriptiones = [];
	actualizarCCData = null;
	facturaNueva = null;
	tiposDocumentoAfip = new GenericList();
	condifionesIva = new GenericList();
	
	constructor(private alumnoService: AlumnoService, private router: Router, private activatedRoute: ActivatedRoute,
		private constService: ConstService, private gradosService: GradoService, private escuelaService: EscuelaService) {
		this.generos = this.constService.getGeneros();
		this.tiposDocumentoAfip = this.constService.getTipoDocumentoAfip();
		this.condifionesIva = this.constService.getCondicionIva();
		
	}

	ngOnInit() {
		this.id = this.activatedRoute.snapshot.paramMap.get('id');
		this.gradosService.getGradosPorEscuelaNoPaging().pipe(take(1)).subscribe(c => this.grados = c.resultList);
		if (this.id && this.id !== 'new') {
			this.activatedRoute.data.subscribe(data => {
				if(data.alumno.resultList.length > 0){
					this.alumno = data.alumno.resultList.length == 1 ? data.alumno.resultList[0] as Alumno : null;
					this.gradoId = this.alumno.grado.id;
				}else{
					Swal.fire('Error', 'El/la alumno/a esta inactivo o no existe.', 'error');
				}
				
			});
		} else {
			this.id = null;
			this.alumno = {
				id: 0, nombre: '', apellido: '', dni: '', telefono1: '', telefono2: '', legajo: '',
				fechaNacimiento: new Date(), fechaIngreso: new Date(), activo: true, pasaGrado: true, fechaMensaje: new Date(), mensaje: '',
				grado: {} as Grado, localidad: {}, genero: 0, escuelaId: this.escuelaService.Escuela().id, cuilTitular: '', tipoDocumentoTitular: 0,
				titularFactura: '', condificonIvaTitular: 0, direccion: '', eMail: ''
			};
		}
	}

	save() {
		const g = this.grados.find(g => g.id === this.gradoId);
		if (g) {
			this.alumno.grado = g;
		}
		const _save$: Observable<Alumno> = this.id ?
			this.alumnoService.update(this.alumno) :
			this.alumnoService.create(this.alumno);

		this.subscriptiones.push(_save$.subscribe(resp => {
			this.router.navigate(["alumnos"]);
		},
			(err) => {
				Swal.fire('Error', err.error.msg, 'error');
			}));
	}

	delete() {
		const confirmOp = {
			title: "Confirmación",
			text: `¿Esta seguro de inactivar este alumno/a?`,
			icon: 'question',
			showCancelButton: true,
			showConfirmButton: true
		  } as SweetAlertOptions;
		  Swal.fire(confirmOp)
			.then((result) => {
			  if (result.isConfirmed) {
				this.subscriptiones.push(this.alumnoService.delete(this.id).subscribe(r => {
					this.router.navigate(["alumnos"]);
				}));
			  }else{
			  }
			});
	}

	
	activar() {
		const confirmOp = {
			title: "Confirmación",
			text: `¿Esta seguro de volver a activar este alumno/a?`,
			icon: 'question',
			showCancelButton: true,
			showConfirmButton: true
		  } as SweetAlertOptions;
		  Swal.fire(confirmOp)
			.then((result) => {
			  if (result.isConfirmed) {
				this.alumno.activo = true;
				this.subscriptiones.push(this.alumnoService.update(this.alumno).subscribe(r => {
					this.router.navigate(["alumnos"]);
				}));
			  }else{
			  }
			});
	}

	

	volver() {
		this.router.navigate(["alumnos"]);
	}

	setFechaNacimiento(evento: Date) {
		this.alumno.fechaNacimiento = evento;
	}

	setFechaIngreso(evento: Date) {
		this.alumno.fechaNacimiento = evento;
	}

	ngOnDestroy() {
		this.subscriptiones.forEach(s => s.unsubscribe());
	}

	actualizarCC(cc) {
		this.actualizarCCData = cc;
	}

	agregarFactura(factura) {
		this.facturaNueva = factura;
	}

	actualizarAlumno(alumno) {
		this.subscriptiones.push(this.alumnoService.update(this.alumno).subscribe(resp => {
			//this.router.navigate(["alumnos"]);
		},
			(err) => {
				Swal.fire('Error', err.error.msg, 'error');
			}));
	}
}
