import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GenericList} from '../../../models/list-item.model';
import { ConstService } from '../../../services/const.service';
@Component({
  selector: 'app-mesanio',
  templateUrl: './mesanio.component.html',
  styleUrls: ['./mesanio.component.css']
})
export class MesAnioModalComponent implements OnInit {
  anioSelectedHasta = new Date().getFullYear();
  mesSelectedHasta = 1;
  anioSelectedDesde = new Date().getFullYear();
  mesSelectedDesde = 1;
  anios = new GenericList();
  meses = new GenericList();

  constructor(public dialogRef: MatDialogRef<MesAnioModalComponent>,  @Inject(MAT_DIALOG_DATA) public data: any, private constService: ConstService) { }

  ngOnInit(): void {
    this.anios = this.constService.getAnios();
    this.meses = this.constService.getMeses();
  }

  cambiarAnioDesde(value){
    this.anioSelectedDesde = value;
  }

  cambiarMesDesde(value){
    this.mesSelectedDesde = value;
  }

  cambiarAnioHasta(value){
    this.anioSelectedHasta = value;
  }

  cambiarMesHasta(value){
    this.mesSelectedHasta = value;
  }

  monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
   }

  aceptar(){
    if(this.mesSelectedHasta !== 0 && this.anioSelectedHasta !== 0){
      let desde = new Date(this.anioSelectedDesde, this.mesSelectedDesde - 1, 1);
      let hasta = new Date(this.anioSelectedHasta, this.mesSelectedHasta - 1, 1);
      let diferencia =  this.monthDiff(desde, hasta);
      this.dialogRef.close({action: 'ok', result: {mesDesde: this.mesSelectedDesde, anioDesde: this.anioSelectedDesde,
        mesHasta: this.mesSelectedHasta, anioHasta: this.anioSelectedHasta, meses: diferencia, desde: desde, hasta: hasta}});
    }else{
      alert('Debe seleccionar mes y año!.');
    }
  }
  cancelar(){
    this.dialogRef.close({action: 'cancel', result: null});
  }
}
