import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EscuelaService } from 'src/app/services/escuela.service';

import Swal from 'sweetalert2';

import { Concepto } from '../../../../models/concepto.model';
import { ConceptoService } from '../../../../services/concepto.service';

@Component({
  selector: 'app-concepto-edit',
  templateUrl: './concepto-edit.component.html',
  styleUrls: ['./concepto-edit.component.css']

})
export class ConceptoEditComponent implements OnInit {

  public concepto: Concepto = new Concepto(null, '', 0, new Date(), true, false, false, 0);
  id;
  escuelaActualId = 1;
  constructor(private service: ConceptoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected escuelaService: EscuelaService) {
    this.escuelaActualId = this.escuelaService.Escuela().id;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id && this.id !== 'new') {
      this.service.getById<Concepto>(this.id)
        .subscribe((resp: any) => {
          this.concepto = resp.result;
        });
    } else {
      this.concepto = new Concepto(null, '', 0, new Date(), true, false, false, this.escuelaActualId);
    }
  }

  // tslint:disable-next-line: typedef
  save() {
    
    const save$: Observable<Concepto> = (this.id && this.id !== 'new') ?
      this.service.update(this.concepto) :
      this.service.create(this.concepto);

    save$.subscribe(resp => {
      this.router.navigate(['/conceptos']);
    },
      (err) => {
        Swal.fire('Error', err.error.msg, 'error');
      });
  }

  reactivate(): void {
    if (confirm('Esta seguro de reactivar el registro?')) {
      this.service.reactivate(this.id)
        .subscribe();
      this.router.navigate(['/conceptos']);
    }
  }

  inactivate(): void {
    if (confirm('Esta seguro de inactivar el registro?')) {
      this.service.inactivate(this.id)
        .subscribe();
      this.router.navigate(['/conceptos']);
    }
  }

  volver(): void {
    this.router.navigate(['/conceptos']);
  }

  setFechaAlta(evento: Date) {
    this.concepto.fechaAlta = evento;
  }


}
