import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  subscripciones = [];
  public loginForm = this.fb.group({
    email: [localStorage.getItem('email'), [Validators.required, Validators.email]],
    password: ['', Validators.required],
    remember: [false]
  });
  public auth2: any;
  constructor(private router: Router,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.renderButton();
  }

  login(): void {
    this.subscripciones.push(this.usuarioService.login(this.loginForm.value)
      .subscribe(resp => {
        if (this.loginForm.get('remember').value) {
          localStorage.setItem('email', this.loginForm.get('email').value)
        } else {
          localStorage.removeItem('email');
        }

        this.router.navigateByUrl('/');
      }, (err) => {
        Swal.fire('Error', err.error.msg, 'error');
      }));
  }

  //onSuccess(googleUser) {
  //  console.log('Logged in as: ' + googleUser.getBasicProfile().getName());
  //  let id_token = googleUser.getAuthResponse().id_token;
  //  console.log('Token is: ' + id_token);

  //}

  //onFailure(error) {
  //  console.log(error);
  //}
  renderButton() {
    gapi.signin2.render('my-signin2', {
      'scope': 'profile email',
      'width': 240,
      'height': 50,
      'longtitle': true,
      'theme': 'dark',
      // 'onsuccess': this.onSuccess,
      //'onfailure': this.onFailure
    });

    this.startApp();
  }

  async startApp() {
    //await this.usuarioService.googleInit();
    //this.auth2 = this.usuarioService.auth2;
    //this.attachSignin(document.getElementById('my-signin2'));

  }

  attachSignin(element) {
    console.log(element.id);
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const id_token = googleUser.getAuthResponse().id_token;
        this.subscripciones.push(this.usuarioService.loginGoogle(id_token).subscribe(
          resp => {
            this.ngZone.run(() => {
              this.router.navigateByUrl('/');
            });
          }));

      }, (error) => {
        alert(JSON.stringify(error, undefined, 2));
      });
  }

  ngOnDestroy() {
    this.subscripciones.forEach(s => s.unsubscribe());
  }
}
