import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  menu: any[] = [
    {
      titulo: 'Facturacion',
      icono: 'mdi mdi-coin',
      submenu: [
        { titulo: '', url: '/' },
        { titulo: 'Facturas', url: 'facturas' },
        { titulo: 'Generar Saldos', url: 'generarcuentaalumno' },
        { titulo: 'Ingresos-Egresos', url: 'ingresoegreso' },
        { titulo: 'Ver Saldos', url: 'saldos' },
      ]
    },
    {
      titulo: 'Maestros',
      icono: 'mdi mdi-settings',
      submenu: [
        { titulo: 'Alumnos', url: 'alumnos' },
        { titulo: 'Categorias', url: 'categorias' },
        { titulo: 'Conceptos', url: 'conceptos' },
        { titulo: 'Escuelas', url: 'escuelas' },
        { titulo: 'Grados', url: 'grados' },
        { titulo: 'Usuarios', url: 'usuarios' }
      ]
    },
    {
      titulo: 'Reportes',
      icono: 'mdi mdi-chart-bar',
      submenu: [
        { titulo: 'Alumnos por Grado', url: 'alumnosporgrado' }
      ]
    }
  ];
  constructor() { }
}
