import { Component, OnInit } from '@angular/core';
import { Escuela } from 'src/app/models/escuela.model';
import { EscuelaService } from 'src/app/services/escuela.service';

import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/models/usuario.model';
import { Router, Route } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit {

  public usuario: Usuario;
  public escuela: Escuela;

  constructor(private usuarioService: UsuarioService, protected escuelaService: EscuelaService, private router: Router) {
    this.usuario = usuarioService.usuario;
    this.escuela = escuelaService.Escuela();
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.usuarioService.logout();
  }

  applyFilter(filterValue: string){
    //ver de obtener todas las rutas y buscar sobre eso.
    // for (var i = 0; i < this.router.config.length; i++) {
    //   var routePath:string = this.router.config[i].path;
    //   console.log(routePath);
    // }
  }
}
