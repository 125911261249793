import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FileUploadService } from 'src/app/services/file-upload.service';

import Swal from 'sweetalert2';

import { Escuela } from '../../../../models/escuela.model';
import { EscuelaService } from '../../../../services/escuela.service';

@Component({
  selector: 'app-escuela-edit',
  templateUrl: './escuela-edit.component.html',
  styleUrls: ['./escuela-edit.component.css']
})
export class EscuelaEditComponent implements OnInit {

  public escuela: Escuela = new Escuela(0, '','','','','', '', '', '', '','', '', '', '', '', ''); 
  id;
  public imagenSubir: File;
  public imgTemp: any;

  constructor(private service: EscuelaService,
              private fileUploadService: FileUploadService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id && this.id !== 'new') {
          this.service.getById<Escuela>(this.id)
            .subscribe((resp: any) => {
              this.escuela = resp.result;
            });
        }else{
          this.escuela = new Escuela(0, '','','','','', '', '', '', '','', '', '', '', '', '');
        }
  }

  // tslint:disable-next-line: typedef
  save() {
    const save$: Observable<Escuela> = this.id ?
      this.service.update(this.escuela) :
      this.service.create(this.escuela);
    if(this.id > 0){
      localStorage.setItem('escuela', JSON.stringify(this.escuela));
    }
    save$.subscribe(resp => {
      this.router.navigate(['/escuelas']);
    },
    (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    });
  }

  delete(): void {
    if (confirm('Esta seguro de eliminar este grado?')) {
      this.service.delete(this.id);
      this.router.navigate(['/escuelas']);
    }
  }

  volver(): void {
    this.router.navigate(['/escuelas']);
  }

  public cambiarImagen(file: File){
    this.imagenSubir = file;

    if (!file) { return this.imgTemp = null; }

    const reader = new FileReader();
    const url64 = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.imgTemp = reader.result;
      // console.log('HOLAR');
      // console.log( reader.result);
    };
  }

  public subirImagen(){
    this.fileUploadService.actualizarFotoPerfil(this.imagenSubir, 'escuelas', this.id);
    //ver aca que guarde la imagen en la escuela.
  }

}
