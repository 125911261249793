import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IngresoEgresoService } from '../../../../services/ingresosegreso.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { take } from 'rxjs/operators';
import { IngresoEgreso } from 'src/app/models/ingresoegreso.model';
import { ResultList } from '../../../../interfaces/generic.interface';
import { ImpresionService } from 'src/app/services/impresion.service';

@Component({
  selector: 'app-ingresoegreso-list',
  templateUrl: './ingresoegreso-list.component.html',
  styleUrls: ['./ingresoegreso-list.component.css']
})
export class IngresoEgresoListComponent implements OnInit, AfterViewInit {
  displayedColumns = ['numero', 'fecha', 'formaPago', 'cuit', 'monto','grado','alumno', 'id', 'accion'];
  dataSource = new MatTableDataSource<IngresoEgreso>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: IngresoEgresoService, private impresion: ImpresionService) { }

  ngOnInit() {
    this.service.getIngresoEgresosPorEscuela(0, 100)
      .pipe(take(1))
      .subscribe((fs: ResultList<IngresoEgreso>) => {
        this.dataSource.data = fs.resultList;
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  imprimir(ie: IngresoEgreso) {
    this.impresion.generateIngresoEgresoPDF(ie).then(result => {
    });
  }

  getExcel(){
    this.service.getExcelIngresosEgresos();
  }
  // getData(pageIndex, pageSize, filter) {
	// 	const dataFilter = {filterLike: filter, gradoId: this.gradoId, inactivos: this.inactivos};
	// 	this.subscriptions.push(this.alumnoService.getAlumnosPorEscuelaYFiltros(pageIndex, pageSize, dataFilter).subscribe((fs: AlumnoList) => {
	// 		this.dataSource = new MatTableDataSource<any>(fs.resultList);
	// 		this.length = fs.total;
	// 		this.paginator.length = this.length;
	// 		this.dataSource._updateChangeSubscription();
	// 	}));
	// }

  // public getServerData(event?: PageEvent) {
	// 	if(this.stringFilter && this.stringFilter.length > 2){
	// 		this.pageIndex = event.pageIndex;
	// 		this.applyFilter();
	// 		return event;
	// 	}else{
	// 		this.getData((event.pageIndex + 1), event.pageSize, '');
	// 		return event;
	// 	}
	// }
}




