import { ConstService } from './../../../../services/const.service';
import {CuentaCorrienteService} from '../../../../services/cuentacorriente.service';
import {Configuracion} from '../../../../models/configuracion.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { GenericList } from '../../../../models/list-item.model';
import { GradoService } from 'src/app/services/grado.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-actualizar-saldos-modal',
  templateUrl: './actualizarsaldosmodal.component.html',
  styleUrls: ['./actualizarsaldosmodal.component.css']
})
export class ActualizarSaldosModalComponent implements OnInit, OnDestroy {
  subscriptions = [];
  categoriaId = 0;
  anio = 0;
  mes = 0;
  anios = new GenericList();
  meses = new GenericList();
  categoriaNombre : string;
  loading = false;
  soloEsteFecha = false;
  soloEsteGrado = false;
  grados = [];
  gradoId = 0;
  constructor(public dialogRef: MatDialogRef<ActualizarSaldosModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private cuentaCorrienteService: CuentaCorrienteService, private dialog: MatDialog, private constService: ConstService,private gradosService: GradoService,) {
    this.categoriaId = parseInt(data.categoriaId);
    this.anios = this.constService.getAnios();
    this.meses = this.constService.getMeses();
    this.categoriaNombre = data.nombreCategoria;
  }

  ngOnInit(): void {
    this.gradosService.getGradoPorCategoria(this.categoriaId).pipe(take(1)).subscribe((c:any) => this.grados = c.resultList);
    const configuracion = JSON.parse(localStorage.getItem('configuracion')) as Configuracion[];
  }

  cancelar() {
    this.dialogRef.close({ status: false });
  }


  cambiarMes(mes: number){
		this.mes = mes;
  }
  cambiarAnio(anio: number){
		this.anio = anio;
  }

  actualizarSaldos() {
      const confirmOp = {
        title: "Confirmación",
        text: "Este proceso actualizara los valores de todos los saldos que corresponden con esta Categoria, desea continuar?",
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true
      } as SweetAlertOptions;
      Swal.fire(confirmOp)
        .then((result) => {
          if(result.isConfirmed){
            this.loading = true;
            this.cuentaCorrienteService.actualizarSaldosCategoria(this.mes, this.anio, this.categoriaId, this.gradoId, this.soloEsteFecha, this.soloEsteGrado).subscribe(result => {
              this.loading = false;
              Swal.fire('Guardado', 'Los saldos fueron actualizados con exito!', 'success')
              .then((result) => {
                if (result.isConfirmed)
                this.dialogRef.close({ status: true });
              });
            })
          }
        });
    }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
