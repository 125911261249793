<div id="main-wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="page-wrapper">
      <div class="container-fluid">
        <app-loader></app-loader>  
        <!--Mi Sistema de Rutas-->
        <app-breadcrumbs></app-breadcrumbs>
        <mat-divider></mat-divider>
        <router-outlet></router-outlet>
      </div>
      <footer class="footer">
        © <span id="myText"></span> Administrador de Colegios
    </footer>
    </div>
  </div>
  <script>
    var fecha = new Date();
    document.getElementById("myText").innerHTML = fecha.getFullYear();
  </script>