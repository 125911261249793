import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Grado } from '../models/grado.model';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { FilterExpression, FilterValue, JoinExpression, OrderExpression, Pager } from 'src/models/lookup.filter.data';
import { Escuela } from '../models/escuela.model';
import { EscuelaService } from './escuela.service';

@Injectable({ providedIn: 'root' })
export class GradoService extends BaseService<Grado> {
 
  constructor(protected http: HttpClient, protected escuelaService: EscuelaService) {
    super(http);
    this.options = { route: 'grados' };
    
  }

  getGradosPorEscuela(pageIndex, size, filterLike): Observable<any> {
    const orLikes = new FilterExpression('OR',
			[
				new FilterValue('Grado.nombre', 'Like', [filterLike]),
				new FilterValue('Grado.descripcion', 'Like', [filterLike])
			]);

    const filter = new FilterExpression(
      'AND',
      [new FilterValue('Grado.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
      new Pager((pageIndex * size) , size),
      [ new JoinExpression('Categoria', 'Grado.categoria')],
      !!filterLike ? [orLikes] : [],
      [new OrderExpression('Grado.nombre', true)]);
    return super.getFileredList(filter);
  }

  getGradosPorEscuelaNoPaging(): Observable<any> {
    const filter = new FilterExpression(
      'AND',
      [new FilterValue('Grado.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
      null,
      [ new JoinExpression('Categoria', 'Grado.categoria')],
      [],
      [new OrderExpression('Grado.nombre', true)]);
    return super.getFileredList(filter);
  }


  getGradoPorId(id): Observable<any> {
    const filter = new FilterExpression(
      'AND',
      [new FilterValue('Grado.escuelaId', 'Equal', [this.escuelaService.Escuela().id]),
      new FilterValue('Grado.id', 'Equal', [id])],
      null,
      [ new JoinExpression('Categoria', 'Grado.categoria')],);
    return super.getFileredList(filter);
  }

  getGradoPorCategoria(categoriaId): Observable<any> {
    const filter = new FilterExpression(
      'AND',
      [new FilterValue('Grado.escuelaId', 'Equal', [this.escuelaService.Escuela().id]),
      new FilterValue('Grado.categoria.id', 'Equal', [categoriaId])],null,[],null, [new OrderExpression('Grado.nombre', true)]);
    return super.getFileredList(filter);
  }

  pasarGrado(gradoActualId: number, gradoDestinoId: number): Observable<any> {
    const url = `${this.url}/pasaje/`; 
    return this.http.post<any>(url, 
      { actual: gradoActualId, destino: gradoDestinoId }, 
      this.headers);
  }
}
