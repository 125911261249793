import { CategoriaConcepto } from './../models/categoria-concepto.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { Concepto } from '../models/concepto.model';
import { Observable } from 'rxjs';
import { FilterExpression, FilterValue, OrderExpression, Pager } from 'src/models/lookup.filter.data';
import { UsuarioService } from './usuario.service';
import { ResultList } from '../interfaces/generic.interface';
import { EscuelaService } from './escuela.service';

@Injectable({
  providedIn: 'root'
})
export class ConceptoService extends BaseService<Concepto> {
  constructor(protected http: HttpClient, protected escuelaService: EscuelaService) {
    super(http);
    this.options = { route : 'conceptos' };
  }

  getConceptosPorCategoriasYEscuela(categoriaId): Observable<CategoriaConcepto[]> {
    const filter = new FilterExpression('AND',
      [new FilterValue('categoriaId', 'Equal', [ categoriaId ])],
      new Pager(0, 100),
      null,
      [
        new FilterExpression('OR',
        [
          new FilterValue('escuelaId', 'Equal', [ this.escuelaService.Escuela().id ]),
          new FilterValue('escuelaId', 'IsNull', [])
        ])
      ]
    );
    return super.getFileredList(filter);
  }

  getConceptosPorEscuela(): Observable<ResultList<Concepto>> {
    const filter = new FilterExpression('OR',
      [new FilterValue('escuelaId', 'Equal', [ this.escuelaService.Escuela().id ]),
      new FilterValue('escuelaId', 'IsNull', [])],
      new Pager(0, 100),[],null, [new OrderExpression('nombre', true)]
    );
    return super.getFileredList(filter);
  }
}
