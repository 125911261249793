import { CategoriaConcepto } from '../../../models/categoria-concepto.model';
import { CuentaCorrienteService } from '../../../services/cuentacorriente.service';
import { CuentaCorrienteList } from '../../../models/cuentacorriente.model';
import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Alumno } from 'src/app/models/alumno.model';
import { GenericList } from 'src/app/models/list-item.model';
import { ConstService } from 'src/app/services/const.service';
import { GradoService } from 'src/app/services/grado.service';
import { FacturaReporteService } from 'src/app/services/factura-reporte.service';
@Component({
  selector: 'app-cuentascorrientes',
  templateUrl: './cuentascorrientes.component.html',
  styleUrls: ['./cuentascorrientes.component.css']
})
export class CuentasCorrientesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() alumno = {} as Alumno;
  displayedColumns = ['mes', 'pagado', 'total','alumno', 'grado', 'esInscripcion'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ccsAlumno = [];
  conceptos = [] as CategoriaConcepto[];
  subscriptions = [];
  anios = new GenericList();
  meses = new GenericList();
  anio = 0;
  mes = 0;
  gradoId = 0;
  grados = [];
  solodeuda = false;
  filterLike = '';
  length = 0;
  pageIndex = 0;
  pageSize = 10;
  constructor(private cuentaCorrienteService: CuentaCorrienteService, private dialog: MatDialog,
    private constService: ConstService, private gradosService: GradoService, private facturaReporteService: FacturaReporteService) { }

  ngOnInit(): void {
    this.anios = this.constService.getAnios();
    this.meses = this.constService.getMeses();
    this.gradosService.getGradosPorEscuelaNoPaging().pipe(take(1)).subscribe(c => this.grados = c.resultList);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: any, filter: string) => !filter || data.anio == parseInt(filter);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getFiltro(){
    return {filterLike: this.filterLike, pageIndex: this.pageIndex, size: this.pageSize, gradoId: this.gradoId, mes: this.mes, anio: this.anios, solodeuda: this.solodeuda };
  }
  setDataSource(fs){
    this.dataSource = new MatTableDataSource<any>(fs.resultList);
    this.length = fs.total;
    this.dataSource._updateChangeSubscription();
  }

  aplicarFiltro(){
    this.subscriptions.push(this.cuentaCorrienteService.getCuentasCorrientesConFiltros(this.getFiltro()).subscribe(result => {
      this.setDataSource(result);
    }));
  }

  cambiarMes(mes: number){
		this.mes = mes;
  }
  cambiarAnio(anio: number){
		this.anio = anio;
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getServerData(event?: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
		this.aplicarFiltro();
		return event;
  }
  
  getExcelPagina(){
    this.facturaReporteService.getExcelSaldos(this.dataSource.data);
  }

  getExcelTodo(){
    let filtro = this.getFiltro();
    filtro.pageIndex = null;
    filtro.size = null;

    this.subscriptions.push(this.cuentaCorrienteService.getCuentasCorrientesConFiltros(filtro).subscribe(result => {
      this.facturaReporteService.getExcelSaldos(result.resultList);
    }));
  }

  getExcelTodoAgrupadoAlumno(){
    let filtro = this.getFiltro();
    filtro.pageIndex = null;
    filtro.size = null;

    this.subscriptions.push(this.cuentaCorrienteService.getCuentasCorrientesConFiltros(filtro).subscribe(result => {
      this.facturaReporteService.getExcelSaldosAgrupadoAlumno(this.procesarGrupoAlumno(result));
    }));
  }

  procesarGrupoAlumno(datos: CuentaCorrienteList){
    let datosAgrupados = [];
    datos.resultList.forEach(d => {
      const index = datosAgrupados.findIndex(a => a.id === d.alumno.id);
      if(index > -1){
        datosAgrupados[index].css.push(d);
      }else{
        datosAgrupados.push({id: d.alumno.id, alumno: `${d.alumno.apellido} ${d.alumno.nombre}`, grado: d.alumno.grado.nombre, legajo: d.alumno.legajo, css: [d]});
      }
    });

    return datosAgrupados;
  }
}

