import { environment } from '../../environments/environment';

const base_url = environment.base_url;

export class Escuela {
    public imagenBase64 : string;
    //auditoria
    constructor(public id: number,
                public nombre: string,
                public cuit: string,
                public direccion: string,
                public dipregep: string,
                public telefono1: string,
                public telefono2: string,
                public subencion1: string,
                public subencion2: string,
                public ingresosBrutos: string,
                public fechaInscripcionAfip: string,
                public otro: string,
                public titularColegio: string,
                public imagen?: string,
                public imagebase64?: any,
                public puntoventa? :string
                )
    { }

    get imageUrl(): string {


        if (this.imagen){
            if (this.imagen.includes('https')){
                return this.imagen;
            }
            return  `${base_url}/upload/escuelas/${this.imagen}`;
        }
        return  `${base_url}/upload/escuelas/no-image`;

    }
}
