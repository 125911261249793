import { Component, OnDestroy } from '@angular/core';
import { Router, ActivationEnd, Data } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: [
  ]
})
export class BreadcrumbsComponent implements OnDestroy  {

  public titulo = '';
  public modulo= '';
  public tituloSub$: Subscription;

  constructor(private router: Router) {
      this.tituloSub$ = this.getArgumentosRuta()
        .subscribe((data) => {
          if(data){
            this.titulo = data.title;
            this.modulo = data.module;
          }
          document.title = `Sistema de Colegios - ${this.titulo}`;
        });
   }
  ngOnDestroy(): void {
    this.tituloSub$.unsubscribe();
  }

  private getArgumentosRuta(): Observable<Data> {
    return this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild === null),
        map((event: ActivationEnd) => event.snapshot.data)
      );
  }
}
