export class FilterValue {
        constructor(public field: string,
                    public op: string,
                    public values: any[],
            ) { }
    
    }
    
    export class FilterData {
    
        constructor(public entityName: string,
                    public operator: string,
                    public filterValues: FilterValue[],
                    public skip: number,
                    public take: number
            ) { }
    }
    
    
    export class Pager {
            constructor(public skip: number,
                        public take: number
            ) { }
    }
    
    export class FilterExpression {
            constructor(public operator: string,
                        public filters: FilterValue[],
                        public pager?: Pager,
                        public joins? : JoinExpression[],
                        public expressionChilds? : FilterExpression[],
                        public allOrderBys? : OrderExpression []
                        
            ) { }
    }

    export class JoinExpression {
        constructor(public relatedEntity: string,
                    public relatedProperty: string, 
                    public joinType: string = 'JOIN', 
        ) { }
}

export class OrderExpression {
        constructor(public field: string,
                    public ascending: boolean 
        ) { }
}