import { CuentaCorriente } from 'src/app/models/cuentacorriente.model';
import { environment } from '../../environments/environment';
import { Alumno } from './alumno.model';
const base_url = environment.base_url;

export class Factura {
    constructor(
        public id: number,
        public mes: number,
        public anio: number,
        public fechaPago: Date,
        public monto: Number,
        public tipo: number,
        public tipoComprobante: Number,
        public descuento: number,
        public intereses: number,
        public comentario: string,
        public numero: string,
        public cae: string,
        public fechaVtoCae: string,
        public fechaProcesoAfip: string,
        public estado: number,
        public descontada: boolean,
        public fechaAnulacion: Date,
        public escuelaId: number,
        public alumno: Alumno,
        public formaPago: number,
        public cuentaCorriente: CuentaCorriente,
        public facturaAsociadaId: number,
        public ptoVenta: number)
    { }
}

export interface FacturaList {
    total: number;
    resultList: Factura[];
}

export enum EstadoFactura {
	activa = 1,
	anulada = 2
}

export enum TipoFactura {
	factura = 1,
	notaCredito = 2
}

export enum TipoComprobante {
    facturaA = 1,
    notaDebitoA = 2,
    notaCreditoA = 3,
    facturaB = 6,
    notaDeditoB = 7,
    notaCreditoB = 8,
    facturaC = 11,
    reciboC = 15,
    notaCreditoC =13
}


export enum FormaPago {
    efectivo = 1,
    cheque = 2,
    deposito = 3,
    tarjeta = 4,
    transferencia = 5,
}
