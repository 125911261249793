import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { GradoService } from '../../../../services/grado.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { take, filter } from 'rxjs/operators';
import { Grado } from 'src/app/models/grado.model';
import { ResultList } from '../../../../interfaces/generic.interface';
import { MatDialog } from '@angular/material/dialog';
import { GradoalumnopasajeComponent } from '../acciones/gradoalumnopasaje.component';

@Component({
	selector: 'app-grados-list',
	templateUrl: './grados-list.component.html',
	styleUrls: ['grados-list.component.scss']
})
export class GradosListComponent implements OnInit, AfterViewInit, OnDestroy {
	displayedColumns = ['nombre', 'descripcion', 'categoria', 'id'];
	dataSource = new MatTableDataSource<any>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	length = 0;
	pageIndex = 0;
	subscriptions = [];
	constructor(private dialog: MatDialog,
				private gradoService: GradoService) { }

	ngOnInit() {
		this.subscriptions.push(this.gradoService.getGradosPorEscuela(this.pageIndex, 10, '').pipe(take(1)).subscribe((fs: ResultList<Grado>) => {
			this.dataSource.data = fs.resultList;
			this.length = fs.total;
			this.paginator.length = this.length;
		}));
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.getData(0,10, filterValue);
	}

	getData(pageIndex, pageSize, filter) {
		this.subscriptions.push(this.gradoService.getGradosPorEscuela(pageIndex, pageSize, filter).subscribe((fs: ResultList<Grado>) => {
			this.dataSource = new MatTableDataSource<any>(fs.resultList);
			this.length = fs.total;
			this.paginator.length = this.length;
			this.dataSource._updateChangeSubscription();
		}));
	}
	public getServerData(event?: PageEvent) {
		this.getData((event.pageIndex + 1), event.pageSize, '');
		return event;
	}
	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	pasarSiguiente(grado: Grado) {
		const dialogRef = this.dialog.open(GradoalumnopasajeComponent, {
		height: '400px',
		width: '500px',
		data: { grado }
		});
	}
}


// import {
//   Component,
//   OnInit,
//   ViewChild,
//   AfterViewInit,
//   OnDestroy,
// } from '@angular/core';
// import { GradoService } from '../../../../services/grado.service';
// import { MatPaginator, PageEvent } from '@angular/material/paginator';
// import { MatTableDataSource } from '@angular/material/table';
// import { MatSort } from '@angular/material/sort';
// import { Grado } from 'src/app/models/grado.model';
// import { MatDialog } from '@angular/material/dialog';
// import { GradoalumnopasajeComponent } from '../acciones/gradoalumnopasaje.component';
// import { ColDef, ColumnApi, GridApi, GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
// import { RemoteGridApi } from 'src/app/pages/controls/ag-grid/remote-grid-api';
// import { Observable, of } from 'rxjs';

// @Component({
//   selector: 'app-grados-list',
//   templateUrl: './grados-list.component.html',
//   styleUrls: ['grados-list.component.scss'],
// })
// export class GradosListComponent
//   implements OnInit, AfterViewInit, OnDestroy, RemoteGridApi
// {
//   displayedColumns = ['nombre', 'descripcion', 'categoria', 'id', 'action'];
//   dataSource = new MatTableDataSource<any>();
//   rowData = [];
//   @ViewChild(MatPaginator) paginator: MatPaginator;
//   @ViewChild(MatSort) sort: MatSort;
//   length = 0;
//   pageIndex = 0;
//   subscriptions = [];
//   columnDefs: ColDef[] = [
//     { field: 'nombre', sortable: true, headerName: 'Nombre',
//       cellRenderer: this.getLink.bind(this),
//       width: 200,
//       filter: 'agTextColumnFilter',
//       filterParams: {
//         buttons: ['reset', 'apply'],
//       },
//       // valueGetter:  function(params) {return params.data?.id;},
//       // refData : function(params) {
//       //   return params.colDef.hide ? '' : params.value.name;
//       // }
//       // cellRenderer: function(params) {
//       //   return `<a href="/invoice/${params.value}">`+ params.value+'</a>'
//       //   //return `<a [routerLink]="[/grados/${params.value}]">${params.value}</a>`
//       // }
//     },
//     { field: 'descripcion', sortable: true, width: 300, filter: 'agTextColumnFilter', },
//     { field: 'categoria.nombre', sortable: true, width: 200, filter: 'agTextColumnFilter', headerName: 'Categoria'},
//     { field: 'id', hide: true },
//     {  field: 'id', headerName: 'Accion', hide: false, cellRenderer: this.getAction.bind(this)}
    
//   ];
//   //https://www.ag-grid.com/javascript-data-grid/filter-provided/
//   gridApi!: GridApi;
//   private gridColumnApi!: ColumnApi;
//   gridOptions = {
//     pagination: true,
//     rowModelType: 'infinite',
//     cacheBlockSize: 17,
//     paginationPageSize: 17,
//     getRowId: params => params.data.id,
//   };
//   remoteGridBinding = this;

//   constructor(private dialog: MatDialog, private gradoService: GradoService) {}

//   ngOnInit() {
//     // this.subscriptions.push(this.gradoService.getGradosPorEscuela(this.pageIndex, 10, '').pipe(take(1)).subscribe((fs: ResultList<Grado>) => {
//     // 	this.dataSource.data = fs.resultList;
//     //   this.rowData = fs.resultList;
//     // 	this.length = fs.total;
//     // 	// this.paginator.length = this.length;
//     // }));
//   }
//   getLink(params){
//     return `<a href="/grados/${params.data?.id}">`+ params.value +'</a>';
//   }
//   getAction(params){
//     return `<button mat-icon-button color="primary" (click)="pasarSiguiente(${params.data?.id})" title="Edit Contact"><mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" style="cursor: pointer;" aria-hidden="true">home</mat-icon></button>`;
//     //return `<a href="javascript: void(0);" (click)="pasarSiguiente(${params.data})"><mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">home</mat-icon></a>`
//     //return `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" style="cursor: pointer;" (click)="pasarSiguiente(${params.data})" aria-hidden="true">home</mat-icon>`;

//     // return `<a href="/grados/${params.data?.id}">`+ params.value +'</a>';
//     // return `<a href="javascript: void(0);" (click)="pasarSiguiente(${params.data})"><mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">home</mat-icon></a>`
//   }
  

//   sizeToFit() {
//     this.gridApi.sizeColumnsToFit();
//   }

//   autoSizeAll(skipHeader: boolean) {
//     const allColumnIds: string[] = [];
//     this.gridColumnApi.getAllColumns()!.forEach((column) => {
//       if (column.getColId() !== 'id') allColumnIds.push(column.getId());
//     });
//     this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
//   }

//   onGridReady(params: GridReadyEvent) {
//     this.gridApi = params.api;
//     this.gridColumnApi = params.columnApi;
//   }

//   getData(params: IGetRowsParams): Observable<{ resultList; total }> {
    
//     return this.gradoService.getGradosPorEscuela(this.gridApi.paginationGetCurrentPage(), this.gridOptions.paginationPageSize, '');
//   }
//   afterGetData(data){

//   }

//   ngAfterViewInit() {
   
//   }

  
//   ngOnDestroy() {
//     this.subscriptions.forEach((s) => s.unsubscribe());
//   }

//   pasarSiguiente(grado: Grado) {
//     const dialogRef = this.dialog.open(GradoalumnopasajeComponent, {
//       height: '400px',
//       width: '500px',
//       data: { grado },
//     });
//   }
// }
