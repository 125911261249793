import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { EscuelaService } from '../../../../services/escuela.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { take } from 'rxjs/operators';
import { ResultList } from '../../../../interfaces/generic.interface';
import { Escuela } from 'src/app/models/escuela.model';
// import { GradoList } from '../../../../interfaces/grado.interface';

@Component({
  selector: 'app-escuela-list',
  templateUrl: './escuela-list.component.html',
  styleUrls: ['./escuela-list.component.css']
})
export class EscuelaListComponent implements OnInit, AfterViewInit {
    displayedColumns = ['nombre', 'cuit', 'telefono1'];
	dataSource = new MatTableDataSource<Escuela>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
  	@ViewChild(MatSort) sort: MatSort;
	admin = false;
  constructor(private service: EscuelaService) { }
  
  ngOnInit() {
	  if(this.admin){
		this.service.all<ResultList<Escuela>>().pipe(take(1)).subscribe((fs: ResultList<Escuela>) => {
			this.dataSource.data = fs.resultList;
		});
	  }else {
		const escuela = JSON.parse(localStorage.getItem('escuela')) as Escuela;
		this.service.getById<Escuela>(escuela.id).pipe(take(1)).subscribe((fs: any) => {
			this.dataSource.data = [fs.result];
		});
	  }
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}



