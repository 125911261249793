<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-sm-10">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
            </mat-form-field>
          </div>
          <div class="form-group col-sm-2" style="margin-top: 15px">
            <button mat-flat-button color="primary" routerLink="new">
              <mat-icon>add</mat-icon>
              Nuevo Grado
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-table #table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="nombre">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Nombre</b></mat-header-cell>
                <mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id><a [routerLink]="['/grados/', element.id]">{{
                    element.nombre
                    }}</a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="categoria">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Categoria</b></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.categoria.nombre }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="descripcion">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b>Descripcion</b></mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.descripcion }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="pasarSiguiente(element)">
                      Pasar de Grado
                    </button>
                  </mat-menu>
                </td>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
              (page)="getServerData($event)" [(length)]="length">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-sm-10">
          </div>
          <div class="form-group col-sm-2" style="margin-top: 15px">
            <button mat-flat-button color="primary" routerLink="new">
              <mat-icon>add</mat-icon>
              Nuevo Grado
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 grid-wrapper">
            <ag-grid-angular style="width: 95%; height: 500px" class="ag-theme-alpine" [columnDefs]="columnDefs"
            [remoteGridBinding]="remoteGridBinding" [gridOptions]="gridOptions" (remoteGridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
