import { MatDialog } from '@angular/material/dialog';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ResultList } from 'src/app/interfaces/generic.interface';
import { CategoriaConcepto, CategoriaConceptoSimple } from 'src/app/models/categoria-concepto.model';
import { Categoria } from 'src/app/models/categoria.model';
import { Concepto } from 'src/app/models/concepto.model';
import { CategoriaService } from 'src/app/services/categoria.service';
import { ConceptoService } from '../../../../services/concepto.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable } from 'rxjs';
import { ActualizarSaldosModalComponent } from 'src/app/pages/maestros/categoria/actualizarSaldos.modal/actualizarsaldosmodal.component'
import { EscuelaService } from 'src/app/services/escuela.service';

@Component({
  selector: 'app-catetoria-edit',
  templateUrl: './categoria-edit.component.html',
  styleUrls: ['./categoria-edit.component.css']
})
export class CategoriaEditComponent implements OnInit, OnDestroy {
  //private data: CategoriaConcepto[] = [];
  //public dataSource = new MatTableDataSource<CategoriaConcepto>();
  public dataSource = new BehaviorSubject<AbstractControl[]>([]);
  public displayColumns = ['concepto', 'inscripcion', 'importe', 'actions'];
  public categoriaForm: FormGroup;
  //public conceptosFrom: FormArray;
  public conceptos: Concepto[] = [];
  subscripciones = [];
  public id;
  public importeTotal = 0;
  private categoria = null;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoriaService: CategoriaService,
    private conceptoSerice: ConceptoService,
    protected escuelaService: EscuelaService,
    
    private dialog: MatDialog) {
    
    this.subscripciones.push(this.conceptoSerice.getConceptosPorEscuela()
      .subscribe((fs: ResultList<Concepto>) => {
        this.conceptos = fs.resultList;
      })
    );
  }


  ngOnInit(): void {

    this.categoriaForm = this.formBuilder.group({
      id: [0],
      nombre: ['', Validators.required],
      esInscripcion: [''],
      descripcion: ['', Validators.required],
      //  escuela: [this.usuarioService.escuela, Validators.required],
      conceptos: this.formBuilder.array([], this.customGroupValidation)
    });

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id && this.id !== 'new') {

      this.categoriaService.getCategoriasPorEscuelaYId(this.id)
        .pipe(
          take(1),
          map((result: any) => {
            return (result.resultList[0] as Categoria);
          }))
        .subscribe((categoria: Categoria) => {
          this.importeTotal = 0;
          this.categoriaForm.patchValue(categoria);
          this.categoria = categoria;
          categoria.conceptos.forEach((x: any) => {
            this.addRow(new CategoriaConceptoSimple(x.id, x.concepto.id, x.concepto.esInscripcion, x.importe), false)
            this.importeTotal += parseFloat(x.importe);
          });
        });
    } else {
      this.addRow(new CategoriaConceptoSimple(0, 0, false, 0), false);
    }
  }

  agregarConcepto() {
    this.addRow(new CategoriaConceptoSimple(0, 0, false, 0), false);
  }
  getConceptosForm(): FormArray {
    return (this.categoriaForm.get('conceptos') as FormArray);
  }

  ngOnDestroy(): void {
    if (this.subscripciones) {
      this.subscripciones.forEach(s => s.unsubscribe());
    }
  }

  removeFromIndex(index) {
    const confirmOp = {
      title: "Confirmación",
      text: `¿Desea quitar este concepto de la categoria? Esto hara, que a futuro, no se actualice este concepto para esta categoria en el caso de modificar los valores!`,
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true
    } as SweetAlertOptions;
    Swal.fire(confirmOp)
      .then((result) => {
        if (result.isConfirmed) {
          this.getConceptosForm().removeAt(index);
          this.updateView();
        } else {
        }
      });
  }

  addRow(d?: CategoriaConceptoSimple, noUpdate?: boolean) {
    const row = this.formBuilder.group({
      'id': [d && d.id ? d.id : 0, []],
      'esInscripcion': d.esInscripcion ? "Si" : "No",
      'concepto': [d && d.concepto ? d.concepto : null, []],
      'importe': [d && d.importe ? d.importe : 0, []]
    });
    this.getConceptosForm().push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }

  updateView() {
    this.dataSource.next(this.getConceptosForm().controls);
  }
  actualizarTotal(){
    this.importeTotal = 0;
    this.categoriaForm.value.conceptos.forEach((x: any) => {
      this.importeTotal += parseFloat(x.importe);
    });
  }

  onConceptoChange(concepto, index) {
    const _len = this.getConceptosForm().controls ? this.getConceptosForm().controls.length : 0;
    if (index < 0 || index > _len)
      return;
    const importe = this.conceptos.find(x => x.id == concepto).importe;

    const formControls = (this.getConceptosForm().controls[index] as FormGroup).controls;

    const importeFC = formControls.importe;
    if (importeFC && (!importeFC.value || importeFC.value === 0)) {
      importeFC.setValue(importe);
    } 
  }

  customGroupValidation(formArray) {
    let isError = false;
    var result = _.groupBy(formArray.controls, c => c.controls.concepto ? c.controls.concepto.value : null);
    for (let prop in result) {
      if (result[prop].length > 1) {

        isError = true;
        _.forEach(result[prop], function (item: FormGroup) {
          item.controls.concepto.setErrors({ 'incorrect': true });
        });
      } else {
        const conceptoFC = (result[prop][0] as FormGroup).controls.concepto;

        if (conceptoFC.invalid) {
          conceptoFC.setErrors(null);
        }
      }
    }
    if (isError) { return { 'error': 'Entidad duplicada' } }
  }

  volver(): void {
    this.router.navigate(['/categorias']);
  }

  guardar(): void {
    const cat = this.categoriaForm.value;
    const categoria = new Categoria(cat.nombre, cat.descripcion, this.escuelaService.Escuela(), cat.id, []);
    cat.conceptos.forEach(x =>
      categoria.conceptos.push(
        new CategoriaConcepto(x.id,
          new Concepto(x.concepto, '', 0, new Date(), true, false, false, 0),
          x.importe)));


    const apply$: Observable<Categoria> = (this.id && this.id !== 'new') ?
      this.categoriaService.update(categoria) :
      this.categoriaService.create(categoria);

    apply$.subscribe(resp => {
      Swal.fire('Guardado', 'Cambios fueron guardados!', 'success')
        .then((result) => {
          if (result.isConfirmed)
            if (this.id !== 'new') {
              const confirmOp = {
                title: "Confirmación",
                text: `¿Desea actualizar los saldos para esta categoria?`,
                icon: 'question',
                showCancelButton: true,
                showConfirmButton: true
              } as SweetAlertOptions;
              Swal.fire(confirmOp)
                .then((result) => {
                  if (result.isConfirmed) {
                    this.actualizarSaldos();
                  } else {
                    this.volver();
                  }
                });
            } else {
              this.volver();
            }
        });
    },
      (err) => {
        Swal.fire('Error', err.error.msg, 'error');

      });
  }

  public conceptoDescripcion(concepto) {
    if (concepto.modificable) {
      return `${concepto.nombre} (Mod)(${concepto.id})`;
    } else {
      return concepto.nombre;
    }
  }

  actualizarSaldos() {
    const data = { categoriaId: this.id, nombreCategoria: this.categoriaForm.value.nombre }
    const dialogRef = this.dialog.open(ActualizarSaldosModalComponent, {
      height: '400px',
      width: '700px',
      data: data
    });
    this.subscripciones.push(dialogRef.afterClosed().subscribe(result => {
      if (result && result) {
        this.volver();
      }
    }));
  }
}


