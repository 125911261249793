import { TipoComprobante } from './../models/factura.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Factura, FacturaList } from '../models/factura.model';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/internal/Observable';
import { FilterExpression, FilterValue, JoinExpression, OrderExpression, Pager } from 'src/models/lookup.filter.data';
import { EscuelaService } from './escuela.service';

@Injectable({ providedIn: 'root' })
export class FacturaService extends BaseService<any> {
	subscriptions = [];
	escuelaIdent = '';
	constructor(protected http: HttpClient, protected escuelaService: EscuelaService) {
		super(http);
		this.options = { route: 'facturas' };

		this.escuelaIdent = this.escuelaService.Escuela().cuit + this.escuelaService.Escuela().id
		let datacuit = localStorage.getItem(this.escuelaIdent);
		if (datacuit) {
			let dato = JSON.parse(datacuit);
			const now = new Date().getTime();
			const diff = now - parseInt(dato.exp);
			if (diff > 0) {
				this.autenticarAfip();
			}
		} else {
			this.autenticarAfip();
		}
	}

	private autenticarAfip() {
		//determinar como saber si el servicio esta activo o no.
		this.subscriptions.push(this.checkLoginAfip().subscribe(result => {
			if (result.autentidadofip) {
				let dato = { estado: 'autenticadoafi', exp: new Date().getTime() };
				localStorage.setItem(this.escuelaIdent, JSON.stringify(dato));
			}
		}));
	}

	private getJoinsFactura() {
		return [new JoinExpression('Alumno', 'Factura.alumno'),
		new JoinExpression('Grado', 'Alumno.grado'),
		new JoinExpression('CuentaCorriente', 'Factura.cuentaCorriente'),
		new JoinExpression('CuentaCorrienteConcepto', 'CuentaCorriente.conceptos'),
		new JoinExpression('CategoriaConcepto', 'CuentaCorrienteConcepto.categoriaConcepto'),
		new JoinExpression('Concepto', 'CategoriaConcepto.concepto')
		];
	}

	getOrFilter(filterLike) {
		return new FilterExpression('OR',
			[
				new FilterValue('alumno.nombre', 'Like', [filterLike]),
				new FilterValue('alumno.apellido', 'Like', [filterLike]),
				new FilterValue('alumno.legajo', 'Like', [filterLike]),
				new FilterValue('factura.cae', 'Like', [filterLike])
			]);
	}
	getFacturaPorAlumno(alumnoId, escuelaId, pageIndex = 0, size = 100): Observable<FacturaList> {
		let pager = null;
		if (size !== 0) {
			pager = new Pager((pageIndex * size), size)
		}
		const filter = new FilterExpression('AND',
			[new FilterValue('Factura.alumnoId', 'Equal', [alumnoId]),
			new FilterValue('Factura.escuelaId', 'Equal', [escuelaId])],
			pager,
			[new JoinExpression('Alumno', 'Factura.alumno'),
			new JoinExpression('CuentaCorriente', 'Factura.cuentaCorriente')
			],
			[],
			[
				new OrderExpression('Factura.anio', false),
				new OrderExpression('Factura.mes', false)
			]);
		return super.getFileredList(filter);
	}

	getFacturaPorAnio(escuelaId, anio, pageIndex = 0, size = 1000): Observable<FacturaList> {
		let pager = null;
		if (size !== 0) {
			pager = new Pager((pageIndex * size), size)
		}
		const filter = new FilterExpression('AND',
			[new FilterValue('Factura.anio', 'Equal', [anio]),
			new FilterValue('Factura.escuelaId', 'Equal', [escuelaId])],
			pager,
			[new JoinExpression('Alumno', 'Factura.alumno'),
			new JoinExpression('Grado', 'Alumno.grado')]
		);
		return super.getFileredList(filter);
	}

	getFacturaEscuelaYFiltro(escuelaId, pageIndex, size, filterLike, desde, hasta, exportarExcel, alumnoId): Observable<FacturaList> {
		let filtersExtra = [];
		let joinExcel = [];
		if (filterLike && filterLike !== '') {
			filtersExtra.push(new FilterExpression('OR',
			[
				new FilterValue('factura.cae', 'Like', [filterLike])
			]));
		}
		if(alumnoId > 0){
			filtersExtra.push(new FilterExpression('OR',
			[
				new FilterValue('Factura.alumno.id', 'Equal', [alumnoId])
			]));			
		}
		if (desde.getTime() !== hasta.getTime()) {
			const between = new FilterExpression('OR',
				[
					new FilterValue('Factura.fechaPago', 'Between', [desde, hasta])
				]);
			filtersExtra.push(between);
		}
	// 	else{
    //   const between = new FilterExpression('OR',
	// 			[
	// 				new FilterValue('Factura.fechaPago', 'Equal', [desde])
	// 			]);
	// 		filtersExtra.push(between); 
    // }
		if (exportarExcel) {
			joinExcel.push(new JoinExpression('Grado', 'alumno.Grado'));
		}
		const filter = new FilterExpression('AND',
			[new FilterValue('Factura.escuelaId', 'Equal', [escuelaId])],
			(pageIndex !== null && size !== null) ? new Pager((pageIndex * size), size) : null,
			[new JoinExpression('Alumno', 'Factura.alumno'),
			new JoinExpression('CuentaCorriente', 'Factura.cuentaCorriente')],
      filtersExtra);

		if (exportarExcel) {
			filter.joins.push(new JoinExpression('Grado', 'Alumno.grado'));
		}

		return super.getFileredList(filter);
	}

	//unificar partes con getFacturaEscuelaYFiltro
	getFacturaEscuelaYFiltroConDeuda(escuelaId, filterLike, desde, hasta, exportarExcel): Observable<FacturaList> {
		let filtersExtra = [];
		let joinExcel = [];
		if (filterLike && filterLike !== '') {
			filtersExtra.push(this.getOrFilter(filterLike));
		}
		if (hasta && desde.getTime() !== hasta.getTime()) {
			const between = new FilterExpression('OR',
				[
					new FilterValue('Factura.fechaPago', 'Between', [desde, hasta])
				]);
			filtersExtra.push(between);
		}else{
      const between = new FilterExpression('OR',
				[
					new FilterValue('Factura.fechaPago', 'Equal', [desde])
				]);
			filtersExtra.push(between);
    }
		if (exportarExcel) {
			joinExcel.push(new JoinExpression('Grado', 'alumno.Grado'));
		}
		const filter = new FilterExpression('AND',
			[new FilterValue('Factura.escuelaId', 'Equal', [escuelaId]),
			new FilterValue('CuentaCorriente.total', 'GreatEq', ['CuentaCorriente.pagado'])],
			null,
			[new JoinExpression('Alumno', 'Factura.alumno'),
			new JoinExpression('CuentaCorriente', 'Factura.cuentaCorriente', 'LEFT'),
			new JoinExpression('Grado', 'Alumno.grado')],
      filtersExtra);

      return super.getFileredList(filter);
	}

	getFacturaPorIdConRelaciones(facturaId): Observable<FacturaList> {
		const filter = new FilterExpression('AND',
			[new FilterValue('Factura.id', 'Equal', [facturaId])],
			new Pager(0, 100),
			this.getJoinsFactura()
		);
		return super.getFileredList(filter);
	}

	getFacturaPorCC(ccId): Observable<FacturaList> {
		const filter = new FilterExpression('AND',
			[new FilterValue('Factura.cuentaCorrienteId', 'Equal', [ccId]),
			new FilterValue('Factura.estado', 'Equal', [1])]);
		return super.getFileredList(filter);
	}

	crearPago(data) {
		data.escuelaCuit = this.escuelaService.Escuela().cuit;
		data.empresa = this.escuelaService.Escuela().id;
		return this.http.post<Factura>(`${this.url}/facturar`, data, { headers: new HttpHeaders().set('x-token', this.token) });
	}

	enviarAfip(data) {
		data.escuelaCuit = this.escuelaService.Escuela().cuit;
		data.empresa = this.escuelaService.Escuela().id;
    	return this.http.post<Factura>(`${this.url}/enviarafip`, data, { headers: new HttpHeaders().set('x-token', this.token) });
	}

	enviarEmail(data) {
		data.colegio = this.escuelaService.Escuela().nombre;
		data.direccion = this.escuelaService.Escuela().direccion;
		data.telefono = `${this.escuelaService.Escuela().telefono1} - ${this.escuelaService.Escuela().telefono2}`;

    	return this.http.post<Factura>(`${this.url}/enviarEmail`, data, { headers: new HttpHeaders().set('x-token', this.token) });
	}

	checkLoginAfip() {
		let datos = { cuit: this.escuelaService.Escuela().cuit };
		return this.http.post<any>(`${this.urlAfip}/afip/autenticar`, datos);
	}


	imprimir() {

	}
}
