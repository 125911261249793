<div class="row">
  <div class="col-md-6">
    <div class="card" style="height:500px">
      <div class="card-body">
        <h4 class="card-title">Mes y año a procesar</h4>
        <div class="row">
          <div class="form-group col-sm-2">
            <mat-form-field>
              <mat-select #mesessd="ngModel" placeholder="Mes" [(ngModel)]="mesDesde" name="mesDesde" id="mesDesde">
                <mat-option *ngFor="let c of meses.list" [value]="c.id"> {{ c.descripcion}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <small class="text-danger font-14" *ngIf="mesessd.touched && mesessd.invalid"> Mes es requerido</small>
          </div>
          <div class="form-group col-sm-2">
            <mat-form-field>
              <mat-select #aniossd="ngModel" placeholder="Año" [(ngModel)]="anioDesde" name="anioDesde" id="anioDesde">
                <mat-option *ngFor="let c of anios.list" [value]="c.id"> {{ c.descripcion}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <small class="text-danger font-14" *ngIf="aniossd.touched && aniossd.invalid"> Año es requerido</small>
          </div>
        </div>
        <div class="row pull-right">
          <div class="action-buttons">
            <button type="button" class="btn btn-success mr-2" (click)="generarSaldos()">
              Ejecutar
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" style="height:500px">
      <div class="card-body">
        <h4 class="card-title">Detalle del Proceso</h4>
        <textarea #processLog id="infoLog" name="infoLog" class="form-control" style="height:380px;"
          readonly></textarea>
      </div>
    </div>
  </div>
</div>