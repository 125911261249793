
<div class="card">
    <div class="card-body">
        <h4 class="card-title">Pasaje de Grado de Alumnos</h4>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="gradoO">Actual</label>
                    <!-- <div class="input-group">
                        <select #origenId="ngModel" [(ngModel)]="gradoOrigenId" name="origenId" id="origenId">
                            <option *ngFor="let grado of grados" 
                                [value]="grado.id">{{grado.nombre}}
                            </option>
                        </select>
                        <div class="input-group-addon"><i class="ti-home"></i></div>   
                    </div> -->
        
                    <div class="input-group" >
                        <input type="text"  class="form-control"  #origen="ngModel" [(ngModel)]="gradoActual" name="origen" id="origen" readonly>
                        <div class="input-group-addon"><i class="ti-home"></i></div>   
                    </div>
                </div>
                <div class="form-group">
                    <label for="gradoD">Nuevo</label>
                    <div class="input-group">
                        <select #destinoId="ngModel"  class="form-control" [(ngModel)]="gradoDestinoId" name="destinoId" id="destinoId">
                            <option *ngFor="let grado of grados" 
                                [value]="grado.id">{{grado.nombre}}
                            </option>
                        </select>
                        <div class="input-group-addon"><i class="ti-pin2"></i></div>   
                    </div>
                </div>
            </div>
        </div>
            
        <div class="text-right">
            <button type="button" (click)="apply()" class="btn btn-success waves-effect waves-light m-r-10" [disabled]="!puedePasarGrado">Aplicar</button>
            <button type="button" (click)="close()" class="btn btn-inverse waves-effect waves-light">Cancelar</button>
        </div>
            
    </div>
</div>
