import { CuentaCorriente } from 'src/app/models/cuentacorriente.model';
import { EstadoFactura, TipoComprobante, TipoFactura } from './../../../models/factura.model';
import { CategoriaConcepto } from './../../../models/categoria-concepto.model';
import { CuentaCorrienteService } from './../../../services/cuentacorriente.service';
import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FacturaService } from '../../../services/factura.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Alumno } from 'src/app/models/alumno.model';
import { ImpresionService } from 'src/app/services/impresion.service';
import { Factura } from 'src/app/models/factura.model';
import { merge } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { FacturaAfipModalComponent } from '../factura-afip-modal/factura-afip-modal.component';
import { MatDialog } from '@angular/material/dialog';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-facturasalumno',
  templateUrl: './facturasalumno.component.html',
  styleUrls: ['./facturasalumno.component.css']
})
export class FacturasalumnoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() alumno = {} as Alumno;
  displayedColumns = ['cae', 'numero', 'periodo', 'monto', 'fechapago', 'tipo', 'formaPago', 'estado', 'comentario', 'id'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() anularFactura: EventEmitter<CuentaCorriente> = new EventEmitter();
  @Output() actualizarAlumno: EventEmitter<Alumno> = new EventEmitter();
  facturas = [];
  conceptos = [] as CategoriaConcepto[];
  subscriptions = [];

  _nuevaFactura: Factura;
  get agregarFactura(): Factura {
    return this._nuevaFactura;
  }
  @Input() set agregarFactura(value: Factura) {
    if (value && value !== null) {
      this._nuevaFactura = value;
      this.agregarFacturas();
    }
  }

  constructor(private facturaService: FacturaService, private cuentaCorrienteService: CuentaCorrienteService,
    private impresionService: ImpresionService, private snackBarService: SnackBarService, private dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.alumno.id > 0) {
      this.subscriptions.push(this.facturaService.getFacturaPorAlumno(this.alumno.id, this.alumno.escuelaId).subscribe(resutl => {
        this.facturas = resutl.resultList;
        this.dataSource.data = resutl.resultList;
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  imprimir(fc: Factura, accion) {
    let extra = { grado: this.alumno.grado, alumno: this.alumno, facturasAnteriores: [] };
    this.facturaService.getFacturaPorIdConRelaciones(fc.id).subscribe(result => {
      if (result.resultList.length === 1) {
        this.facturaService.getFacturaPorCC(fc.cuentaCorriente.id).subscribe(result2 => {
          extra.facturasAnteriores = result2.resultList.filter(f => f.id < fc.id && f.tipo === TipoFactura.factura);
          this.impresionService.generatePDF(accion, result.resultList[0], extra).then(result => {

          });
        });
      }
    });
  }

  enviarEmail(fc: Factura) {
    if(!!this.alumno.eMail){
      let extra = { grado: this.alumno.grado, alumno: this.alumno, facturasAnteriores: [] };
      this.facturaService.getFacturaPorIdConRelaciones(fc.id).subscribe(result => {
        if (result.resultList.length === 1) {
          this.facturaService.getFacturaPorCC(fc.cuentaCorriente.id).subscribe(result2 => {
            extra.facturasAnteriores = result2.resultList.filter(f => f.id < fc.id && f.tipo === TipoFactura.factura);
            this.impresionService.generatePDF('email', result.resultList[0], extra).then(result => {
              const dataPrint = {
                titularFactura: this.alumno.titularFactura, alumno: `${this.alumno.apellido} ${this.alumno.nombre} `,
                fechaPago: new Date(fc.fechaPago).toISOString().substring(10, 0), dataFile: result, destinatario: this.alumno.eMail
              };
              this.subscriptions.push(this.facturaService.enviarEmail(dataPrint).subscribe((result: any) => {
                if (result.result) {
                  this.snackBarService.openSnackBar(result.mensaje, 'Facturas');
                } else {
                  this.snackBarService.openSnackBar(result.mensaje, 'Facturas');
                }
              }));
            });
          });
        }
      });
    }else{this.snackBarService.openSnackBar('El alumno/a no tienen una direccion de correo asignada!', 'Facturas');}
    
  }

  anular(fc: Factura) {

    const confirmOp = {
      title: "Confirmación",
      text: "¿Esta seguro de anular esta factura?",
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true
    } as SweetAlertOptions;
    Swal.fire(confirmOp)
      .then((result) => {
        if (result.isConfirmed) {
          fc.fechaAnulacion = new Date();
          fc.estado = EstadoFactura.anulada;
          const obsFactura = this.facturaService.update(fc);
          confirmOp.text = 'Desea actualizar la cuenta corriente del alumno para este periodo?';
          Swal.fire(confirmOp)
            .then((result) => {
              if (result.isConfirmed) {
                fc.descontada = true;
                fc.cuentaCorriente.pagado = Number(fc.cuentaCorriente.pagado) - Number(fc.monto);
                const obsCC = this.cuentaCorrienteService.update(fc.cuentaCorriente);
                const obsFinal = obsFactura.pipe(merge(obsCC));
                this.subscriptions.push(obsFinal.subscribe(result => {
                  this.mostrarSnack(fc.cuentaCorriente);
                }));
              } else {
                fc.descontada = false;
                this.subscriptions.push(obsFactura.subscribe(result => {
                  this.mostrarSnack(fc.cuentaCorriente);
                }));
              }

              confirmOp.text = 'Desea generar la Nota de Credito?';
              Swal.fire(confirmOp)
                .then((result) => {
                  if (result.isConfirmed) {
                    let fcNC = { ...fc };
                    fcNC.estado = EstadoFactura.activa;
                    fcNC.numero = '';
                    fcNC.fechaPago = new Date();
                    fcNC.tipo = TipoFactura.notaCredito;
                    fcNC.tipoComprobante = TipoComprobante.notaCreditoC;
                    fcNC.fechaAnulacion = null;
                    fcNC.id = 0;
                    fcNC.facturaAsociadaId = fc.id;
                    fcNC.cae = '';
                    fcNC.comentario = 'Nota de credito';
                    this.facturaService.create(fcNC).subscribe((result: any) => {
                      fcNC.id = result.result.id;
                      this.facturas.push(fcNC);
                      this.dataSource.data = this.facturas;
                    });
                  }

                });
            });
        }
      });
  }



  enviarAfip(factura: Factura) {
    const data = { factura, tipodocumento: this.alumno.tipoDocumentoTitular, cuilTitular: this.alumno.cuilTitular, nombreTitular: '' }
    const dialogRefFacturaAfipModal = this.dialog.open(FacturaAfipModalComponent, {
      height: '500px',
      width: '800px',
      data: {
        razonSocial: this.alumno.titularFactura, tipodocumento: this.alumno.tipoDocumentoTitular, cuilTitular: this.alumno.cuilTitular, comentario: '',
        total: factura.monto
      }
    });
    this.subscriptions.push(dialogRefFacturaAfipModal.afterClosed().subscribe(result => {
      if (result && result.enviar === true) {
        data.tipodocumento = result.datos.tipodocumento;
        data.cuilTitular = result.datos.nroDocumento;
        data.nombreTitular = result.datos.razonSocial;
        //data.tipoComprobante = ;

        factura.comentario = result.datos?.comentario ?? '';
        let guardarAlumno = false;
        if (this.alumno.titularFactura !== result.datos.razonSocial) {
          guardarAlumno = true;
          this.alumno.titularFactura = result.datos.razonSocial;
        }
        if (this.alumno.tipoDocumentoTitular !== result.datos.tipodocumento) {
          guardarAlumno = true;
          this.alumno.tipoDocumentoTitular = result.datos.tipodocumento;
        }
        if (this.alumno.cuilTitular !== result.datos.nroDocumento) {
          guardarAlumno = true;
          this.alumno.cuilTitular = result.datos.nroDocumento
        }
        this.subscriptions.push(this.facturaService.enviarAfip(data).subscribe((result: any) => {
          if (result.result) {
            factura = result.factura;
            let index = this.facturas.findIndex(f => f.id === factura.id);
            if (index > -1) {
              this.facturas[index] = factura;
              this.dataSource.data = this.facturas;
            }

            if (guardarAlumno) {
              this.actualizarAlumno.emit(this.alumno);
            }
            //ver aca de que si le paso la factura, si tiene que llamar dentro de imprimir al service por id.
            this.imprimir(factura, 'open');
            this.snackBarService.openSnackBar(result.msj, 'Facturas');
          } else {
            this.snackBarService.openSnackBar(result.msj, 'Facturas');
          }
        }));
      }
    }));

  }

  mostrarSnack(cuentaCorriente) {
    this.snackBarService.openSnackBar('Se anulo la factura!', 'Facturas');
    this.anularFactura.emit(cuentaCorriente)
  }

  agregarFacturas() {
    this.facturas.push(this._nuevaFactura);
    this.dataSource.data = this.facturas;
  }
}
