<div class="col-md-12" *ngIf="alumno">
    <mat-tab-group>
        <mat-tab label="Datos del Alumno">
            <form #f="ngForm">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input matInput #nombre="ngModel" [(ngModel)]="alumno.nombre" name="nombre" type="text"
                                    id="nombre" required placeholder="Nombre"/>
                                </mat-form-field>
                                <small class="text-danger font-14" *ngIf="nombre.touched && nombre.invalid">
                                    Nombre es requerido
                                </small>
                            </div>
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input matInput #apellido="ngModel" [(ngModel)]="alumno.apellido" name="apellido" type="text"
                                        id="apellido" required placeholder="Apellido" />
                                </mat-form-field>
                                <small class="text-danger font-14" *ngIf="apellido.touched && apellido.invalid"> Apellido es requerido </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-8">
                                <mat-form-field>
                                    <input matInput #direccion="ngModel" [(ngModel)]="alumno.direccion" name="direccion" type="text"
                                    id="direccion" required placeholder="Direccion"/>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-4">
                                <mat-form-field>
                                    <input matInput #eMail="ngModel" [(ngModel)]="alumno.eMail" name="eMail" type="text"
                                    id="eMail" required placeholder="EMail"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput #dni="ngModel" [(ngModel)]="alumno.dni" name="dni" type="text"
                                    id="dni" required placeholder="Documento" />
                                </mat-form-field>
                                <small class="text-danger font-14" *ngIf="dni.touched && dni.invalid"> Docuemento es requerido
                                </small>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput #legajo="ngModel" [(ngModel)]="alumno.legajo" name="legajo" type="text"
                                    id="legajo" required placeholder="Legajo" />
                                </mat-form-field>
                                <small class="text-danger font-14" *ngIf="legajo.touched && legajo.invalid"> Legajo es requerido
                                </small>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput [ngModel]="alumno.fechaNacimiento | date:'yyyy-MM-dd'" (change)="setFechaNacimiento($event.target.value)" name="fechaNacimiento"
                                        type="date" placeholder="Fecha Nacimiento (mes/dia/año)" id="fechaNacimiento" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput [ngModel]="alumno.fechaIngreso | date:'yyyy-MM-dd'" (change)="setFechaIngreso($event.target.value)" name="fechaIngreso"
                                        type="date" placeholder="Fecha Ingreso (mes/dia/año)" id="fechaIngreso" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <!-- <label for="direccion">Direccion</label>
                                    <input
                                        #direccion="ngModel"
                                        [(ngModel)]="alumno.direccion"
                                        name="direccion"
                                        type="text"
                                        class="form-control"
                                        id="direccion"
                                        required
                                    />
                                    <div class="alert alert-danger" *ngIf="direccion.touched && direccion.invalid"> Direccion es requerido </div>  -->
                            </div>
                            <!-- <div class="form-group col-sm-6">
                                <label for="localidad">Localidad</label>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput #telefono1="ngModel" [(ngModel)]="alumno.telefono1" name="telefono1" type="tel"
                                        placeholder="Telefono" id="telefono1" required />
                                </mat-form-field>
                                <small class="text-danger font-14" *ngIf="telefono1.touched && telefono1.invalid"> Telefono es
                                    requerido </small>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput #telefono1="ngModel" [(ngModel)]="alumno.telefono2" name="telefono2" type="tel"
                                    placeholder="Telefono Alternativo" id="telefono2" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <mat-select placeholder="Genero" #geneross="ngModel" [(ngModel)]="alumno.genero" name="genero" id="genero">
                                      <mat-option  *ngFor="let c of generos.list" [value]="c.id"> {{ c.descripcion}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <small class="text-danger font-14"  *ngIf="geneross.touched && geneross.invalid">  Genero es requerido</small>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <mat-select placeholder="Grado" #gradoIds="ngModel" [(ngModel)]="gradoId" name="gradoId" id="gradoId">
                                      <mat-option  *ngFor="let c of grados" [value]="c.id"> {{ c.nombre}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <small class="text-danger font-14"  *ngIf="gradoIds.touched && gradoIds.invalid"> Grado es requerido</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="alumno.titularFactura" name="titularFactura" type="text"
                                        placeholder="Titular Factura" id="titularFactura" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="alumno.cuilTitular" name="cuilTitular" type="text"
                                        placeholder="Cuil/Cuit Titular" id="cuilTitular" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <mat-select placeholder="Tipo Documento Titular"  [(ngModel)]="alumno.tipoDocumentoTitular" name="tipoDocumentoTitular" id="tipoDocumentoTitular">
                                        <mat-option  *ngFor="let c of tiposDocumentoAfip.list" [value]="c.id"> {{ c.descripcion}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-3">
                                <mat-form-field>
                                    <mat-select placeholder="Cond. Iva Titular"  [(ngModel)]="alumno.condificonIvaTitular" name="condificonIvaTitular" id="condificonIvaTitular">
                                        <mat-option  *ngFor="let c of condifionesIva.list" [value]="c.id"> {{ c.descripcion}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- <input type="text" class="form-control adzones-form-input-type-text" formControlName="productUpc"
                                           maxlength="13" name="productUpc" placeholder="Upc" numbersOnly
                                           [class.is-invalid]="priceMaintenaceForm.get('productUpc').touched && priceMaintenaceForm.get('productUpc').invalid" />

                                    <div *ngIf="priceMaintenaceForm.get('productUpc').touched && priceMaintenaceForm.get('productUpc').errors?.isNotNumber" class="invalid-field">
                                        Before saving, you need to validate the Upc (only numbers allowed)
                                    </div>
                                    <div class="invalid-field" *ngIf="priceMaintenaceForm.get('productUpc').touched && priceMaintenaceForm.get('productUpc').errors?.required">
                                        Product UPC is required
                                    </div> -->
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">

                            </div>
                            <div class="form-group col-sm-6">

                            </div>
                        </div>
                    </div>
                </div>

            </form>

        </mat-tab>
        <mat-tab label="Estado de cuenta">
            <app-cuentacorrientealumno [hidden]="alumno == null" [alumno]="alumno" (agregarFactura)="agregarFactura($event)" (actualizarAlumno)="actualizarAlumno($event)"  [actualizarCC]="actualizarCCData"></app-cuentacorrientealumno>
        </mat-tab>
        <mat-tab label="Historial de pagos">
            <app-facturasalumno [hidden]="alumno == null" [alumno]="alumno" [agregarFactura]="facturaNueva" (actualizarAlumno)="actualizarAlumno($event)" (anularFactura)="actualizarCC($event)"></app-facturasalumno>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="row pull-right">
    <button type="button" class="btn btn-primary mr-2" (click)="save()" [hidden]="alumno == null">Guardar</button>
    <button type="button" class="btn btn-danger  mr-2" *ngIf="alumno.activo" (click)="delete()" [hidden]="alumno == null">
        Inactivar
    </button>
    <button type="button" class="btn btn-danger  mr-2" *ngIf="!alumno.activo" (click)="activar()" [hidden]="alumno == null">
        Activar
    </button>
    <button type="button" class="btn btn-warning mr-2" (click)="volver()">
        Volver
    </button>
</div>
