import { Alumno } from "./alumno.model";
import { Grado } from "./grado.model";

export class IngresoEgreso {

    constructor(public id: number,
                public descripcion: string,
                public monto: number,
                public fecha: Date,
                public escuelaId: number,
                public esEgreso: boolean,
                public cuit: string,
                public tipo: string,
                public numero: string,
                public alumno: Alumno,
                public grado: Grado,
                public formaPago: number)
    { }
}

export interface IngresoEgresoList {
    total: number;
    resultList: IngresoEgreso[];
}


