import { IngresoEgreso, IngresoEgresoList } from '../models/ingresoegreso.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as fs from 'file-saver';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { FilterExpression, FilterValue, JoinExpression, OrderExpression, Pager } from 'src/models/lookup.filter.data';
import { ResultList } from '../interfaces/generic.interface';
import { EscuelaService } from './escuela.service';
import { Workbook } from 'exceljs';
import { getDescriptions } from '../pipes/getDescriptions';

@Injectable({
  providedIn: 'root'
})
export class IngresoEgresoService extends BaseService<IngresoEgreso> {
	fuenteTitulo = 'Verdana,sans-serif';
	tipoFormatoExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	fuenteRow = 'Comic Sans MS';

  constructor(protected http: HttpClient, protected escuelaService: EscuelaService, protected getDescriptions: getDescriptions) {
    super(http);
    this.options = { route : 'ingresosegresos' };

  }

  getIngresoEgresosPorEscuela(pageIndex, size): Observable<ResultList<IngresoEgreso>> {
    const filter = new FilterExpression('AND',
      [new FilterValue('IngresoEgreso.EscuelaId', 'Equal', [ this.escuelaService.Escuela().id ])],
      new Pager((pageIndex * size), size),
      [new JoinExpression('Grado', 'IngresoEgreso.grado', 'LEFT'),
      new JoinExpression('Alumno', 'IngresoEgreso.alumno', 'LEFT')],
      null,
      [new OrderExpression('fecha', false)]
    );
    return super.getFileredList(filter);
  }

  getIngresoEgresosYFiltro(desde, hasta): Observable<IngresoEgresoList> {
    let filtersExtra = [];
    let joinExcel = [];
    if (hasta && desde.getTime() !== hasta?.getTime()) {
        const between = new FilterExpression('OR',
        [
            new FilterValue('fecha', 'Between', [desde, hasta])
        ]);
        filtersExtra.push(between);
    }
    else{
      const between = new FilterExpression('OR',
				[
					new FilterValue('fecha', 'Equal', [desde])
				]);
			filtersExtra.push(between);
    }

    const filter = new FilterExpression('AND',
        [new FilterValue('escuelaId', 'Equal', [ this.escuelaService.Escuela().id ])],
        null,
        null,
        filtersExtra,
        [new OrderExpression('fecha', false)]);

    return super.getFileredList(filter);
  }

  getExcelIngresosEgresos() {
		this.getIngresoEgresosPorEscuela(0, 1000).subscribe(result => {
			if(result.resultList.length > 0){
				const columns = ['Numero', 'Fecha', 'Forma Pago', 'Cuit', 'Monto', 'Es Egreso', 'Grado', 'Alumno', 'Comentario'];
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Ingresos - Egresos');
				const titleRow = worksheet.addRow(columns);
				titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true,  size: 10 };
				let datos: IngresoEgreso[] = [];

				result.resultList.forEach((f: IngresoEgreso) => {
					worksheet.addRow([f.numero, f.fecha, this.getDescriptions.transform(f.tipo, 'formaPago'), f.cuit, f.monto, (f.esEgreso ? 'Si': 'No'), f.grado?.nombre, 
          (!f.alumno ? '' : `${f.alumno?.apellido} ${f.alumno?.nombre}`), f.descripcion]);
				});
				this.escribirArchivoExcel(workbook, 'Aranceles.xlsx');
			}
		});
	}

  escribirArchivoExcel(workbook: Workbook, nombreArchivo){
		workbook.xlsx.writeBuffer().then(data => {
			const blob = new Blob([data], { type: this.tipoFormatoExcel });
			fs.saveAs(blob, nombreArchivo);
	  });
	}

  
}
