import { ResultList } from './../interfaces/generic.interface';
import { AlumnoService } from './alumno.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ConstService } from './const.service';
import { Alumno } from '../models/alumno.model';

@Injectable({
	providedIn: 'root'
  })
export class AlumnoReporteService implements OnDestroy {
	suscription;
	fuenteTitulo = 'Verdana,sans-serif';
	tipoFormatoExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	fuenteRow = 'Comic Sans MS';
	constructor(private alumnoService: AlumnoService) {}

	ngOnDestroy() {
		this.suscription.unsubscribe();
	}

	// obtiene los productos habilitados y genera la info para obtener stock
	getAlumnosPorGrado(gradoId) {
		this.alumnoService.getAlumnosPorEscuelaYGrado(gradoId).subscribe(result => {
			const columns = ['Alumno', 'Legajo', 'Grado'];
			const workbook = new Workbook();
			const worksheet = workbook.addWorksheet('Alumnos por Grado');
			const titleRow = worksheet.addRow(columns);
			titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
			worksheet.addRow([]);
			result.resultList.forEach((a: Alumno) => {
				worksheet.addRow([`${a.apellido} ${a.nombre}`, a.legajo, a.grado.nombre]);
			});

			this.escribirArchivoExcel(workbook, 'Alumnos_Grado.xlsx');
		});
	}

	// // reporte de entrega con detalle de ordenes
	

	// parsearSalesEarn = (data: any) => {
	// 	if (data.op) {
	// 		const columns = ['Producto', 'Fecha', 'Compra', 'Venta', 'Diferencia', 'Ultima compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ventas por Fecha');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(data.op, (pack: OrderPackage) => {
	// 			_.forEach(pack.orders, (order) =>{
	// 				_.forEach(order.shoppingCart.items, (item) =>{
	// 					let product = null; 
	// 					_.forEach(data.prods, (p: Product) => {
	// 						if(p.key === item.key){
	// 							product = p;
	// 						}
	// 					});	 
	// 					worksheet.addRow([item.title, this.helperService.obtenerFechaCorta(pack.datePlaced), this.helperService.validarNumero(product.buyprice), this.helperService.validarNumero(item.price), 
	// 						(this.helperService.validarNumero(item.price) - this.helperService.validarNumero(product.buyprice)), this.helperService.obtenerFechaCorta(product.lastDateBuy)]);
	// 				});
	// 			});
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Productos_Ganancias.xlsx');
	// 	}
	// }

	// parsearSales = (data: any) => {
	// 	if(data.op){
	// 		const columns = ['Producto', 'Precio Compra', 'Precio Venta', 'Diferencia', 'Ultima compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ventas por Fecha');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(data.op, (pack: OrderPackage) => {
	// 			_.forEach(pack.orders, (order) =>{
	// 				_.forEach(order.shoppingCart.items, (item) =>{
	// 					let product = null; 
	// 					_.forEach(data.prods, (p: Product) => {
	// 						if(p.key === item.key){
	// 							product = p;
	// 						}
	// 					});	 
	// 					worksheet.addRow([item.title, this.helperService.validarNumero(product.buyprice), this.helperService.validarNumero(item.price), 
	// 						(this.helperService.validarNumero(item.price) - this.helperService.validarNumero(product.buyprice)), this.helperService.obtenerFechaCorta(product.lastDateBuy)]);
	// 				});
	// 			});
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Ventas.xlsx');
	// 	}
	// }

	// parsearOrdenes = (data: any) => {
	// 	const orders = data.val() as Order [];
	// 	if (orders) {
	// 		const columns = ['Cliente', 'Fecha Compra', 'Estado', 'Total'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ordenes por Fecha');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(orders, (pack: Order) => {
	// 			worksheet.addRow([pack.shipping.name, this.helperService.obtenerFechaCorta(pack.datePlaced), this.constService.getOrderStatusDesc(pack.status),pack.total]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Ordenes.xlsx');
	// 	}
	// }

	// parsearOrdenesToProvider = (data: any) => {
	// 	if (data.orders) {
	// 		const columns = ['Producto', 'Cantidad','Proveedor', 'Telefono', 'Fecha Compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ordenes a pedir al Proveedor');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		let renglones = new Array();
	// 		_.forEach(data.orders, (order: Order) => {
	// 			_.forEach(order.shoppingCart.items, (item) => {
	// 				// item.key -> product
	// 				let proveedor = '';
	// 				let proveedorTelefono = '';
	// 				const producto = data.products[item.key];
	// 				if(producto){
	// 					const providerKey = data.providers[producto.providerkey];
	// 					if(providerKey){
	// 						proveedor = providerKey.name;
	// 						proveedorTelefono = providerKey.telephone;

	// 						const index = renglones.findIndex(x => x.productKey === producto.key && x.providerKey === producto.providerkey);
	// 						if(index > -1){
	// 							renglones[index].quantity = renglones[index].quantity + item.quantity; 
	// 						}else{
	// 							renglones.push({productKey: producto.key,providerKey: producto.providerkey,producto: item.title, datePlaced: this.helperService.obtenerFechaCorta(order.datePlaced), quantity: item.quantity, proveedor, proveedorTelefono});
	// 						}
	// 					}
	// 				}
	// 			});
	// 		});
	// 		renglones.forEach(r => {
	// 			worksheet.addRow([r.producto, r.quantity, r.proveedor, r.proveedorTelefono, r.datePlaced]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Ordenes.xlsx');
	// 	}
	// }

	// // parsea los daots para generar  el reporde de productos stock.
	// parsearProductosParaStock = (snap: any) => {
	// 	const products = snap.val() as Product [];
	// 	if (products) {
	// 		const title = ['Producto', 'Precio Compra', 'Precio Venta', 'Stock', 'Ultima Compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Stock de Productos');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		//ajustar aca pq ahora hay que tomar las specs para generar el reporte de stock
	// 		// _.forEach(products, (p: Product) => {
	// 		// 	worksheet.addRow([p.title, p.buyprice, p.price, p.stock, this.helperService.obtenerFechaCorta(p.lastDateBuy)]);
	// 		// })
	// 		this.escribirArchivoExcel(workbook, 'Producto_Stock.xlsx');
	// 	}
	// }

	// generardetalleEntregasExcel = (snap: any) => {
	// 	const orderPacks = snap.val() as OrderPackage [];
	// 	const workbook = new Workbook();
	// 	const worksheet = workbook.addWorksheet('Entregas');
	// 	_.forEach(orderPacks, (orderPack: OrderPackage) => {
	// 		let fecha = orderPack.dateEstimated;
	// 		if(fecha === undefined || fecha === null){
	// 			fecha = new Date().getTime();
	// 		}
	// 		const title = ['Detalle de entrega', 'Fecha estimada',  this.helperService.obtenerFechaCorta(fecha), 'Estado', this.constService.getOrderPackageStatusDesc(orderPack.status)];
	// 		const header = ['Cliente', 'Direccion',  'Total de la orden($)']; // necesito 3 valores, mapea columna con valores por cantidad. 
	// 		const detail = ['', 'Producto', 'Cantidad', 'Total($)'];
	// 		// https://github.com/ngdevelop-tech/angular-6-export-an-excel-file

			
	// 		let row;
	// 		let detailRow;
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteRow, family: 4, bold: true };
	// 		titleRow.eachCell((cell, number) => {
	// 		});
	// 		worksheet.addRow([]);
	// 		let direccion;
	// 		_.forEach(orderPack.orders, (order: Order) => {
	// 			row = worksheet.addRow(header);
	// 			row.font = { name: this.fuenteRow, family: 4, bold: true };
	// 			direccion = `${order.shipping.address} entre ${order.shipping?.addressLine1} / ${order.shipping?.addressLine2} , ${order.shipping.city}`;
	// 			row = worksheet.addRow([order.shipping.name, direccion, order.total]);
	// 			detailRow = worksheet.addRow(detail);
	// 			detailRow.font = { name: this.fuenteRow, family: 4, bold: false };
	// 			_.forEach(order.shoppingCart.items, (item) => {
	// 				worksheet.addRow(['', item.title, item.quantity, (item.price * item.quantity)]);
	// 			});

	// 			worksheet.addRow([]);
	// 		});
	// 	});
	// 	this.escribirArchivoExcel(workbook, 'Entregas.xlsx');
	// }

	// // parsea las entregas para generar el reporte de la entrega y su detalle de ordens.
	// generardetalleEntregaExcel = (snap: any) => {
	// 	const orderPack = snap.val() as OrderPackage;
	// 	let fecha = orderPack.dateEstimated;
	// 	if(fecha === undefined || fecha === null){
	// 		fecha = new Date().getTime();
	// 	}
	// 	const title = ['Detalle de entrega', 'Fecha estimada', this.helperService.obtenerFechaCorta(fecha)];
	// 	const header = ['Cliente', 'Direccion', 'Total de la orden($)']; // necesito 3 valores, mapea columna con valores por cantidad. 
	// 	const detail = ['', 'Producto', 'Cantidad', 'Total($)'];
	// 	// https://github.com/ngdevelop-tech/angular-6-export-an-excel-file

	// 	const workbook = new Workbook();
	// 	const worksheet = workbook.addWorksheet('Entrega');
	// 	let row;
	// 	let detailRow;
	// 	const titleRow = worksheet.addRow(title);
	// 	titleRow.font = { name: this.fuenteRow, family: 4, bold: true };
	// 	titleRow.eachCell((cell, number) => {
	// 		// cell.fill = {
	// 		//   type: 'pattern',
	// 		//   pattern: 'solid',
	// 		//   fgColor: { argb: 'FFFFFF00' },
	// 		//   bgColor: { argb: 'FF0000FF' }
	// 		// };
	// 		//F:\Development\EShop\node_modules\exceljs\index.d.ts
	// 		//cell.alignment = { shrinkToFit:true }; -> ajusta el texto para que entre en la celda
	// 	});
	// 	//worksheet.mergeCells('A1:C1',);
	// 	worksheet.addRow([]);
	// 	let direccion;
	// 	_.forEach(orderPack.orders, (order: Order) => {
	// 		row = worksheet.addRow(header);
	// 		row.font = { name: this.fuenteRow, family: 4, bold: true };
	// 		direccion = `${order.shipping.address} entre ${order.shipping?.addressLine1} / ${order.shipping?.addressLine2} , ${order.shipping.city}`;
	// 		row = worksheet.addRow([order.shipping.name, direccion, order.total]);
	// 		detailRow = worksheet.addRow(detail);
	// 		detailRow.font = { name: this.fuenteRow, family: 4, bold: true };
	// 		_.forEach(order.shoppingCart.items, (item) => {
	// 			worksheet.addRow(['',item.title, item.quantity, (item.price * item.quantity)]);
	// 		});
	// 	});
	// 	this.escribirArchivoExcel(workbook, 'Entregas.xlsx');
	// }

	// parsearClientes = (snap: any) => {
	// 	const clients = snap.val() as Client [];
	// 	if (clients) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefone'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Clientes');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		let direccion;
	// 		worksheet.addRow([]);
	// 		_.forEach(clients, (p: Client) => {
	// 			direccion = `${p.address} entre ${p?.addresslat1} / ${p?.addresslat2} , ${p?.city}`;
	// 			worksheet.addRow([p.name, p.code, direccion, p.telephone]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Clientes.xlsx');
	// 	}
	// }

	// // este reporte deberia ir a gestion y traer la info de ahi, no a google.
	// parsearClientesConSaldo = (snap: any) => {
	// 	const clients = snap.val() as Client [];
	// 	if (clients) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefone', 'Saldo'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Clientes');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		let direccion;
	// 		worksheet.addRow([]);
	// 		_.forEach(clients, (p: Client) => {
	// 			direccion = `${p.address} entre ${p?.addresslat1} / ${p?.addresslat2} , ${p?.city}`;
	// 			worksheet.addRow([p.name, p.code, direccion, p.telephone, p.saldo]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'ClientesSaldo.xlsx');
	// 	}
	// }

	// parsearProveedores = (snap: any) => {
	// 	const proveedores = snap.val() as Provider [];
	// 	if (proveedores) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefono'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Proveedores');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(proveedores, (p: Provider) => {
	// 			worksheet.addRow([p.name, p.codigo, p.direccion, p.telephone]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Proveedores.xlsx');
	// 	}
	// }

	// parsearProveedoresConSaldo = (snap: any) => {
	// 	const proveedores = snap.val() as Provider [];
	// 	if (proveedores) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefono', 'Saldo'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Proveedores');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(proveedores, (p: Provider) => {
	// 			worksheet.addRow([p.name, p.codigo, p.direccion, p.telephone, p.saldo]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'ProveedoresSaldo.xlsx');
	// 	}
	// }

	// // parsea los daots para generar  el reporde de productos stock.
	// generarExcelResumenCaja = (datos: any) => {
	// 	if (datos) {
	// 		const title = ['Fecha', 'Ingresos', 'Egresos', 'Direrencia'];
	// 		const titleMovimientos = ['Fecha', 'Numero', 'Monto', 'Tipo', 'Ingreso?', 'CAE'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Resumen de Cajas', {state: 'visible'});
	// 		worksheet.state = 'visible';
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		_.forEach(datos.datos, (p: any) => {
	// 			worksheet.addRow([this.helperService.obtenerFechaCorta(p.fecha), p.ingresos, p.egresos, p.diferencia]);
	// 			const nombreSheet = `Movimientos_Caja_${this.helperService.obtenerFechaCortaGuion(p.fecha)}`;
	// 			const worksheetC = workbook.addWorksheet(nombreSheet, {state: 'visible'});
	// 			worksheetC.state = 'visible';
	// 			// detalle de movimientos por caja
	// 			worksheetC.addRow(titleMovimientos);
	// 			const movs = p.movimientos;
	// 			_.forEach(movs, (mov: any) => {
	// 				worksheetC.addRow([this.helperService.obtenerFechaCorta(mov.fecha), mov.numero,  mov.total, this.constService.getFormaMovimiento(mov.forma), 
	// 					(p.ingreso ? 'Si' : 'No'),  mov.afipCAE]);
	// 			});
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Resumen_Cajas.xlsx');
	// 	}
	// }

	// // parsea los daots para generar  el reporde de productos stock.
	// generarExcelMovimientos = (datos: any) => {
	// 	if (datos) {
	// 		const title = ['Fecha', 'Numero', 'Monto', 'Tipo', 'Ingreso?', 'CAE'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Resumen de Movimientos');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		_.forEach(datos, (p: any) => {
	// 			worksheet.addRow([this.helperService.obtenerFechaCorta(p.fecha), p.numero,  p.total, this.constService.getFormaMovimiento(p.forma), 
	// 				(p.ingreso ? 'Si' : 'No'),  p.afipCAE]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Resumen_Movimientos.xlsx');
	// 	}
	// }

	// generarExcelMovimientosDetallado = (datos: any) => {
	// 	if (datos) {
	// 		const title = ['Fecha', 'Numero', 'Monto', 'Tipo', 'Ingreso?', 'CAE'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Resumen de Movimientos');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		_.forEach(datos.movimientos, (p: any) => {
	// 			worksheet.addRow([this.helperService.obtenerFechaCorta(p.fecha), p.numero,  p.total, this.constService.getFormaMovimiento(p.forma), 
	// 				(p.ingreso ? 'Si' : 'No'),  p.afipCAE]);
	// 		});
	// 		if (datos.cheques) {
	// 			const titleCheque = ['Fecha', 'Numero', 'Monto', 'Vencimiento', 'Deposito', 'Banco', 'Emisor', 'Receptor', 'Ingreso?', 'Propio?', 'Rechazado?'];
	// 			const worksheetC = workbook.addWorksheet(`Cheques`, {state: 'visible'});
	// 			worksheetC.addRow(titleCheque);
	// 			worksheetC.state = 'visible';
	// 			_.forEach(datos.cheques, (c: any) => {
	// 				worksheetC.addRow([this.helperService.obtenerFechaCorta(c.fechaEmision), c.numero,  c.total, this.helperService.obtenerFechaCorta(c.fechaVencimiento), 
	// 					this.helperService.obtenerFechaCorta(c.fechaDeposito), c.banco, c.emisor, c.receptor, (c.ingreso ? 'Si' : 'No'), (c.esPropio ? 'Si' : 'No'), 
	// 					(c.rechazado ? 'Si' : 'No')]);
	// 			});
	// 		}
	// 		if (datos.tarjetas) {
	// 			const titleTarjeta = ['Fecha', 'Numero', 'Monto', 'Banco', 'Es debito automatico', 'Titular', 'Ingreso?'];
	// 			const worksheetT = workbook.addWorksheet(`Tarjetas`, {state: 'visible'});
	// 			worksheetT.addRow(titleTarjeta);
	// 			worksheetT.state = 'visible';
	// 			_.forEach(datos.tarjetas, (t: any) => {
	// 				worksheetT.addRow([this.helperService.obtenerFechaCorta(t.fecha), t.numeroTarjeta,  t.total, t.banco, (t.esDebitoAutomatico ? 'Si' : 'No'), t.titularTarjeta,
	// 					 (t.ingreso ? 'Si' : 'No')]);
	// 			});
	// 		}
	// 		if (datos.depositos) {
	// 			const titleDepositos = ['Fecha', 'Orden', 'Monto', 'Banco',  'Ingreso?'];
	// 			const worksheetD = workbook.addWorksheet(`Depositos`, {state: 'visible'});
	// 			worksheetD.addRow(titleDepositos);
	// 			worksheetD.state = 'visible';
	// 			_.forEach(datos.depositos, (t: any) => {
	// 				worksheetD.addRow([this.helperService.obtenerFechaCorta(t.fecha), t.numeroOrden, t.total, t.banco, (t.ingreso ? 'Si' : 'No')]);
	// 			});
	// 		}
	// 		if (datos.transferencias) {
	// 			const titleTransferencias = ['Fecha', 'Orden', 'Monto', 'Banco', 'Emisor', 'Receptor', 'Ingreso?'];
	// 			const worksheetTR = workbook.addWorksheet(`Transferencias`, {state: 'visible'});
	// 			worksheetTR.addRow(titleTransferencias);
	// 			worksheetTR.state = 'visible';
	// 			_.forEach(datos.transferencias, (t: any) => {
	// 				worksheetTR.addRow([this.helperService.obtenerFechaCorta(t.fecha), t.numeroOrden, t.total, t.banco, t.emisor, t.receptor, (t.ingreso ? 'Si' : 'No')]);
	// 			});
	// 		}

	// 		this.escribirArchivoExcel(workbook, 'Resumen_Movimientos.xlsx');
	// 	}
	// }

	escribirArchivoExcel(workbook: Workbook, nombreArchivo){
		workbook.xlsx.writeBuffer().then(data => {
			const blob = new Blob([data], { type: this.tipoFormatoExcel });
			fs.saveAs(blob, nombreArchivo);
	  });
	}
}
//https://github.com/exceljs/exceljs
//basics queries https://www.youtube.com/watch?v=sKFLI5FOOHs