<div class="row">
	<div class="col-md-12">
		<div class="row">
			<div class="form-group col-sm-3">
				<mat-form-field>
					<input matInput [(ngModel)]="stringFilter" (keyup)="doFilter()" placeholder="Filtrar" />
				</mat-form-field>
			</div>
			<div class="form-group col-sm-3">
				<mat-form-field>
					<mat-select placeholder="Grado" [(ngModel)]="gradoId" name="gradoId" id="gradoId">
						<mat-option *ngFor="let c of grados" [value]="c.id"> {{ c.nombre}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="form-group col-sm-2" style="margin-top: 15px;">
				<mat-checkbox name="inactivos" id="inactivos" [(ngModel)]="inactivos">Ver Inactivos</mat-checkbox>
			</div>
			<div class="form-group col-sm-4" style="margin-top: 15px;">
				<button mat-flat-button color="accent" aria-label="Aplicar filtro" (click)="doFilter()" class="mr-2">
					<mat-icon>manage_search</mat-icon>
					Buscar
				</button>
				<button mat-flat-button color="primary" routerLink="new">
					<mat-icon>add</mat-icon>
					Nuevo Alumna/o
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-table #table [dataSource]="dataSource" matSort>
					<ng-container matColumnDef="nombre">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Nombre</b></mat-header-cell>
						<mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id><a
								[routerLink]="['/alumnos/', element.id]">{{element.nombre}}</a>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="apellido">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Apellido</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.apellido }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="legajo">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Legajo</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.legajo }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="grado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Grado</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.grado.nombre }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let element">
							<!-- <button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
					</button> -->
							<!-- <mat-menu #menu="matMenu">
						<button mat-menu-item [routerLink]="['alumnos/', element._id]">Editar</button>
						<hr> -->
							<!-- <button mat-menu-item (click)="habilitarIn(element)">{{(element.activo ? 'Inhabilitar' : 'Habilitar')}}</button> -->
							<!-- </mat-menu>  -->
						</td>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>

				<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
					(page)="getServerData($event)" [(length)]="length">
				</mat-paginator>
			</div>
		</div>
	</div>
</div>