import { EscuelaService } from 'src/app/services/escuela.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Alumno } from '../models/alumno.model';
import { Observable } from 'rxjs';
import { FilterExpression, FilterValue, JoinExpression, OrderExpression, Pager } from 'src/models/lookup.filter.data';
import { BaseService } from './base.service';
import { UsuarioService } from './usuario.service';

@Injectable({ providedIn: 'root' })
export class AlumnoService extends BaseService<Alumno> {
	
	// private subject = new BehaviorSubject<Alumno>({} as Alumno);
	// Alumno$: Observable<Alumno> = this.subject.asObservable();

	constructor(protected http: HttpClient,
		protected usuarioService: UsuarioService, protected escuelaService: EscuelaService) {
		super(http);
		this.options = { route: 'alumnos' };
		
	}

	getAlumnoPorIdCompleto(id): Observable<any> {
		const filter = new FilterExpression('AND',
			[new FilterValue('Alumno.Id', 'Equal', [id]),
			//new FilterValue('Alumno.activo', 'Equal', [true]),
			new FilterValue('Alumno.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
			null,
			[new JoinExpression('Grado', 'Alumno.grado'),
			new JoinExpression('Categoria', 'Grado.categoria')]
		);
		return super.getFileredList(filter);
	}

	getAlumnosPorEscuelaCount(): Observable<any> {
		const filter = new FilterExpression('AND',
			[new FilterValue('escuelaId', 'Equal', [this.escuelaService.Escuela().id]),
			new FilterValue('activo', 'Equal', [true])],
			null
		);
		return super.getFileredCount(filter);
	}

	getAlumnosPorEscuelaYGradoCount(gradoId): Observable<any> {
		const filter = new FilterExpression('AND',
			[new FilterValue('escuelaId', 'Equal', [this.escuelaService.Escuela().id]),
			new FilterValue('Alumno.gradoActualId', 'Equal', [gradoId]),
			new FilterValue('activo', 'Equal', [true])],
			null
		);
		return super.getFileredCount(filter);
	}

	getAlumnosPorEscuelaYFiltros(pageIndex, size, filter): Observable<any> {
		const orLikes = new FilterExpression('OR',
			[
				new FilterValue('Alumno.nombre', 'Like', [filter.filterLike]),
				new FilterValue('Alumno.apellido', 'Like', [filter.filterLike]),
				new FilterValue('Alumno.legajo', 'Like', [filter.filterLike])
			]);

		const filterExp = new FilterExpression('AND',
			[new FilterValue('Alumno.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
			new Pager((pageIndex * size), size),
			[new JoinExpression('Grado', 'Alumno.grado'),
			new JoinExpression('Categoria', 'Grado.categoria')],
			filter?.filterLike ? [orLikes] : [],
			[new OrderExpression('Alumno.apellido', true)]
		);
		// if(filter?.filterLike){
		// 	filterExp.filters = {... filterExp.filters, ... orLikes}
		// }
		// if (!filter?.inactivos) {
		// 	filterExp.filters.push(new FilterValue('Alumno.activo', 'Equal', [true]));
		// }
		if (filter?.gradoId > 0) {
			filterExp.filters.push(new FilterValue('Alumno.gradoActualId', 'Equal', [filter.gradoId]));
		}

		return super.getFileredList(filterExp);
	}

	getAlumnosPorEscuelaYGrado(gradoId): Observable<any> {
		const filter = new FilterExpression('AND',
			[new FilterValue('Alumno.gradoActualId', 'Equal', [gradoId]),
			new FilterValue('Alumno.activo', 'Equal', [true]),
			new FilterValue('Alumno.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
			null,
			[new JoinExpression('Grado', 'Alumno.grado'),
			new JoinExpression('Categoria', 'Grado.categoria')],
			[],
			[new OrderExpression('Alumno.apellido', true)]);
		return super.getFileredList(filter);
	}

}
