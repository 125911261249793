import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Escuela } from '../models/escuela.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FilterExpression, FilterValue } from 'src/models/lookup.filter.data';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class EscuelaService extends BaseService<any> {
  escuela: any;
  private subject = new BehaviorSubject<Escuela>({} as Escuela);
  escuela$: Observable<Escuela> = this.subject.asObservable();

  Escuela () : Escuela {
    if(this.subject.getValue() && this.subject.getValue().id){
      return this.subject.getValue();
    }else{
      let esc = JSON.parse(localStorage.getItem('escuela')) as Escuela;
      this.subject.next(esc);
      return esc;
    }
  } 
  constructor(protected http: HttpClient){
    super(http);
    this.options = { route : 'escuelas' };
    //this.escuela = JSON.parse(localStorage.getItem('escuela')) as Escuela;

  }

  getEscuela(): Observable<any> {
    const filter = new FilterExpression(
      'AND',
      [new FilterValue('Escuela.escuelaId', 'Equal', [this.escuela.id])],
      null,
      null);
    return super.getFileredList(filter);
  }

  getEscuelaPorId(id): Observable<Escuela> {
    return super.getById<Escuela>(id).pipe(
      tap((result: any) => {
        this.subject.next(result.result as Escuela);
        localStorage.setItem('escuela', JSON.stringify(result.result));
        return result;
      }));
  }
}
