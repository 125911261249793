import { environment } from '../../environments/environment';
const base_url = environment.base_url;

export class Configuracion {

    constructor(public nombre: string,
                public valor: string,
                public escuelaId: number,
                public id: number
    ) { }
    
}

export enum ConfiguracionesNombre {
    MesInscripcion = 1,
    CantidadHermanosBonificacion = 2
}