<div class="col-md-12">
    <form #f="ngForm" (ngSubmit)="save()">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input #nombre="ngModel" [(ngModel)]="escuela.nombre" name="nombre" type="text"
                                        matInput id="nombre" required placeholder="Nombre" />
                                    <div class="alert alert-danger" *ngIf="nombre.touched && nombre.invalid">
                                        Nombre es requerido
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input #cuit="ngModel" [(ngModel)]="escuela.cuit" name="cuit" type="text" matInput
                                        id="cuit" required placeholder="Cuit" />
                                    <div class="alert alert-danger" *ngIf="cuit.touched && cuit.invalid">
                                        Cuit es requerido
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input #telefono1="ngModel" [(ngModel)]="escuela.telefono1" name="telefono1"
                                        type="text" matInput id="telefono1" required placeholder="Teléfono" />
                                    <div class="alert alert-danger" *ngIf="telefono1.touched && telefono1.invalid">
                                        Teléfono es requerido
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input #telefono2="ngModel" [(ngModel)]="escuela.telefono2" name="telefono2"
                                        type="text" matInput id="telefono2" required
                                        placeholder="Teléfono Alternativo" />
                                    <div class="alert alert-danger" *ngIf="telefono2.touched && telefono2.invalid">
                                        Teléfono Alternativo es requerido
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-12">
                                <mat-form-field>
                                    <input #direccion="ngModel" [(ngModel)]="escuela.direccion" name="direccion"
                                        type="text" placeholder="Direccion" matInput id="direccion" required />
                                    <div class="alert alert-danger" *ngIf="direccion.touched && direccion.invalid">
                                        Direccion es requerido
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input placeholder="Dipregep"  [(ngModel)]="escuela.dipregep"
                                        name="dipregep" type="text" matInput id="dipregep" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input placeholder="Ingresos Brutos" [(ngModel)]="escuela.ingresosBrutos"
                                        name="ingresosBrutos" type="text" matInput id="ingresosBrutos" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input placeholder="Subención 1" 
                                        [(ngModel)]="escuela.subencion1" name="subencion1" type="text" matInput
                                        id="subencion1" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input placeholder="Subención 2" [(ngModel)]="escuela.subencion2"
                                        name="subencion2" type="text" matInput id="subencion2" />
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="form-group col-sm-12">
                                <mat-form-field>
                                    <input placeholder="Titular colegio" [(ngModel)]="escuela.titularColegio"
                                        name="titularColegio" type="text" matInput id="titularColegio" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input placeholder="Punto Venta afip"
                                        [(ngModel)]="escuela.puntoventa" name="puntoventa" type="text" matInput
                                        id="puntoventa" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-6">
                                <mat-form-field>
                                    <input placeholder="Fecha Inscripción Afip"
                                        [(ngModel)]="escuela.fechaInscripcionAfip" name="fechaInscripcionAfip"
                                        type="text" matInput id="fechaInscripcionAfip" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Avatar</h4>
                        <h6 class="card-subtitle">Imagen de la Escuela</h6>

                        <div class="text-center">
                            <img *ngIf="!imgTemp" [src]="escuela.imageUrl" class="img-avatar">
                            <img *ngIf="imgTemp" [src]="imgTemp" class="img-avatar">
                        </div>

                        <input type="file" (change)="cambiarImagen($event.target.files[0])">

                        <br>
                        <div class="text-left">
                            <button type="submit" class="btn btn-success waves-effect waves-light m-r-10"
                                (click)="subirImagen()" [disabled]="!subirImagen">Cambiar imagen</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>
    <div class="action-buttons">
        <button type="button" class="btn btn-primary mr-2" (click)="save()">Guardar</button>
        <button type="button" class="btn btn-danger  mr-2" (click)="delete()">
            Eliminar
        </button>
        <button type="button" class="btn btn-warning mr-2" (click)="volver()">
            Volver
        </button>
    </div>

</div>