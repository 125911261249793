<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img src="../../../assets/images/users/img_avatar2.png" alt="user" /><span class="hide-menu">{{ usuario.nombre }} </span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLink="./perfil">Mi Perfil </a></li>
                        <!-- <li><a href="javascript:void()">My Balance</a></li>
                        <li><a href="javascript:void()">Inbox</a></li> -->
                        <li><a routerLink="account-settings">Configurar Cuenta</a></li>
                        <li><a (click)="logout()" style="cursor: pointer;">Salir</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <!-- <li class="nav-small-cap">Menu</li> -->
                <li *ngFor="let item of menuItems"> 
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <i [class]="item.icono"></i>
                        <span class="hide-menu">
                            {{ item.titulo }} 
                            <span class="label label-rouded label-themecolor pull-right">
                                {{ item.submenu.length }}</span>
                        </span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let subitem of item.submenu">
                            <a [routerLink]="subitem.url" 
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true}">
                                {{ subitem.titulo }} 
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
