import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alumno } from 'src/app/models/alumno.model';
import { Configuracion, ConfiguracionesNombre } from 'src/app/models/configuracion.model';
import { Factura, EstadoFactura, TipoFactura, TipoComprobante } from 'src/app/models/factura.model';
import { FacturaService } from 'src/app/services/factura.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FacturaAfipModalComponent } from '../factura-afip-modal/factura-afip-modal.component';

@Component({
  selector: 'app-facturamodal',
  templateUrl: './facturamodal.component.html',
  styleUrls: ['./facturamodal.component.css']
})
export class FacturaModalComponent implements OnInit, OnDestroy {
  tableHeaders = ['Concepto', 'Monto'];
  tableRows = [];
  headers = ['Concepto', 'Monto'];
  factura: Factura;
  numero = '';
  comentario = '';
  saldo = 0;
  aPagar = 0;
  pagado = 0;
  importePagar = 0;
  descuento = 0;
  intereses = 0;
  total = 0;
  alumno: Alumno;
  subscriptions = [];
  mes = 0;
  anio = 0;
  pagar:boolean;
  constructor(public dialogRef: MatDialogRef<FacturaModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private facturaService: FacturaService,
    private dialog: MatDialog) {
    this.alumno = data.alumno;
    this.mes = data.cc.mes;
    this.anio = data.cc.anio;
    this.pagar = data.pagar;
  }

  ngOnInit(): void {
    // cargo los conceptos asociados a la factura.
    const configuracion = JSON.parse(localStorage.getItem('configuracion')) as Configuracion[];
    const hermanosBonificacionCantidad = configuracion.findIndex(c => c.nombre === ConfiguracionesNombre[ConfiguracionesNombre.CantidadHermanosBonificacion]);
    // aca hay que ver como funciona esto del descuento.
    this.data.cc.conceptos.forEach(con => {
      this.tableRows.push({ conceptoId: con.id, categoriaConceptoId: con.categoriaConcepto.id, concepto: con.categoriaConcepto.concepto.nombre, monto: con.monto, editable: con.categoriaConcepto.concepto.modificable });
    });

    this.calcularImporte(null, null);
  }

  calcularImporte(element, event) {
    this.total = this.tableRows.map(t => t.monto).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    this.aPagar = this.total;
    this.pagado = parseFloat(this.data.cc.pagado);
    this.saldo = this.aPagar - this.pagado;

    this.importePagar = parseFloat((this.saldo - this.descuento + this.intereses).toFixed(2));
    if(element && event.target){
      const monto = parseFloat(event.target.value);
      element.monto = monto;
      let i1 = this.data.cc.conceptos.findIndex(c => c.id === element.conceptoId);
      if(i1 > -1){
        this.data.cc.conceptos[i1].monto = monto;
        //this.data.cc.conceptos[i1].categoriaConcepto.importe = monto;
      }
    }
  }
  cancelar() {
    this.dialogRef.close({ status: false });
  }

  descuentoChange() {
    this.calcularImporte(null, null);
  }

  pagarFactura() {
    if (this.importePagar > 0) {
      const confirmOp = {
        title: "Confirmación",
        text: "¿Desea generar la Factura del Cobro?",
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true
      } as SweetAlertOptions;
      Swal.fire(confirmOp)
        .then((result) => {
          this.data.cc.total = this.total;
          let alumno = { id: this.data.alumno.id } as Alumno;
          this.factura = {
            alumno: alumno, anio: this.data.cc.anio, mes: this.data.cc.mes, cuentaCorriente: this.data.cc,
            escuelaId: this.data.cc.escuelaId, numero: this.numero, monto: this.importePagar, cae: '', fechaVtoCae: '', fechaProcesoAfip:'', id: 0, fechaPago: new Date(), fechaAnulacion: null,
            descuento: this.descuento, intereses: this.intereses, comentario: this.comentario, estado: EstadoFactura.activa, tipo: TipoFactura.factura, tipoComprobante: TipoComprobante.reciboC, descontada: false,
            formaPago: 1, facturaAsociadaId: null, ptoVenta: 0
          };

          //verificar que si cambia el valor del monto del concepto, actualice la cc y sus conceptos modificados.
          const dataAfip = {cc: this.data.cc, factura: this.factura, enviarAfip: result.isConfirmed, tipodocumento: this.data.alumno.tipoDocumentoTitular, cuilTitular: this.data.alumno.cuilTitular, nombreTitular: this.data.alumno.titularFactura              }
          let guardarAlumno = false;
          if(result.isConfirmed){
            const dialogRef = this.dialog.open(FacturaAfipModalComponent, {
              height: '500px',
              width: '800px',
              data: {
                razonSocial: this.data.alumno.titularFactura, tipodocumento: this.data.alumno.tipoDocumentoTitular, cuilTitular: this.data.alumno.cuilTitular, comentario: '',
                total: this.factura.monto, formaPago: this.factura.formaPago
              }
            });

            this.subscriptions.push(dialogRef.afterClosed().subscribe(res => {
              if (res) {
                if(this.data.alumno.titularFactura !== res.datos.razonSocial){
                  guardarAlumno = true;
                  this.data.alumno.titularFactura = res.datos.razonSocial;
                  dataAfip.nombreTitular = res.datos.razonSocial;
                }
                if(this.data.alumno.tipoDocumentoTitular !== res.datos.tipodocumento){
                  guardarAlumno = true;
                  this.data.alumno.tipoDocumentoTitular = res.datos.tipodocumento;
                  dataAfip.tipodocumento = res.datos.tipodocumento;
                }
                if(this.data.alumno.cuilTitular !== res.datos.nroDocumento){
                  guardarAlumno = true;
                  this.data.alumno.cuilTitular = res.datos.nroDocumento
                  dataAfip.cuilTitular = res.datos.nroDocumento;
                }
              }
              dataAfip.enviarAfip = res.enviar;
              //actualizo la cc.
              this.data.cc.pagado = Number(this.pagado) + Number(this.importePagar);
              dataAfip.cc.pagado = this.data.cc.pagado;
              this.factura.formaPago = res.datos.formaPago;
              this.subscriptions.push(this.facturaService.crearPago(dataAfip).subscribe((result: any) => {
                if(result.result){
                  this.factura = result.factura;
                  this.dialogRef.close({ status: true, mensaje: 'Se guardo el pago.', factura: this.factura, actualizarAlumno: guardarAlumno, cc: this.data.cc });
                }else{
                  this.dialogRef.close({ status: false, mensaje: result.mensaje + ' - ' + result.msj, factura: this.factura, actualizarAlumno: guardarAlumno, cc: this.data.cc });
                }
              }));
            }));
          }
        });
    }
    else {
      alert('El monto debe ser mayor a 0!');
    }
  }

  gettoltip(element){
    return `ConceptoId: ${element.conceptoId} CategoriaConceptoId: ${element.categoriaConceptoId}`;
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
