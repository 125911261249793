import { Usuario } from './../../models/usuario.model';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, map, catchError } from 'rxjs/operators';
import { RegisterForm, LoginForm } from '../interfaces/register-form.interface';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Escuela } from '../models/escuela.model';
import { EscuelaService } from './escuela.service';
import { ConfiguracionService } from './configuracion.service';
import { BaseService } from './base.service';
import { FilterExpression, FilterValue, Pager } from 'src/models/lookup.filter.data';

const base_url = environment.base_url;

declare const gapi: any;
@Injectable({
  providedIn: 'root'
})

export class UsuarioService extends BaseService<Usuario> implements OnDestroy {
  public auth2: any;
  public usuario: Usuario;

  subscripciones = [];
  constructor(protected http: HttpClient,
    private router: Router,
    private escuelaService: EscuelaService,
    private configuracionService: ConfiguracionService) {
    super(http);
      this.options = { route: 'usuarios' };
    
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get uid(): string {
    return this.usuario.id || '';
  }

  crearUsuario(formData: RegisterForm) {
    return this.http.post(`${base_url}/usuarios`, formData, this.headers);
  }

  login(formData: LoginForm) {
    return this.http.post(`${base_url}/login`, formData, this.headers)
      .pipe(
        tap((resp: any) => {
          //console.log(resp);
          localStorage.setItem('token', resp.token);
        })
      );
  }

  loginGoogle(token: string) {
    return this.http.post(`${base_url}/login/google`, { token }, this.headers)
      .pipe(
        tap((resp: any) => {
          //console.log(resp);
          localStorage.setItem('token', resp.token);
        })
      );
  }

  validarToken(): Observable<boolean> {
    return this.http.get(`${base_url}/login/renew`, this.headers).pipe(
      map((resp: any) => {
        const { nombre, email, id, imagen, google, rol, escuelaId } = resp.usuario;
        this.usuario = new Usuario(nombre, email, imagen, '', google, rol, id, escuelaId);
        localStorage.setItem('usuarie', JSON.stringify({ nombre: this.usuario.nombre, id: this.usuario.id, email: this.usuario.email }));
        localStorage.setItem('token', resp.token);
        this.setEscuela(escuelaId);
        this.setConfiguracion(escuelaId);
        return true;
      }),
      catchError(error => of(false)) //true hasta migrar renew al backend
    );
  }
  actualizarPerfil(data: { nombre: string, email: string, rol: string }) {
    return this.http.put(`${base_url}/usuarios/${this.uid}`, data, this.headers);
  }

  logout(): void {
    localStorage.removeItem('usuarie');
    localStorage.removeItem('token');
    localStorage.removeItem('escuela');
    localStorage.removeItem('configuracion');
    this.usuario = {} as Usuario;
    this.router.navigateByUrl('/login');
    // this.auth2.signOut().then(() => {
    //   this.ngZone.run(() => {
    //     this.router.navigateByUrl('/login');
    //   });
    // });
  }

  // googleInit() {
  //   return new Promise((resolve) => {

  //     gapi.load('auth2', () => {
  //       // Retrieve the singleton for the GoogleAuth library and set up the client.
  //       this.auth2 = gapi.auth2.init({
  //         client_id: '629650885118-d3gbucco98jih8l3oavfu4qvef1aob98.apps.googleusercontent.com',
  //         cookiepolicy: 'single_host_origin',
  //       });
  //       resolve();
  //     });
  //   });
  // }

  getUsuariosPorEscuela(): Observable<any> {
    const filter = new FilterExpression('AND',
      [new FilterValue('escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
      null
    );
    return super.getFileredList(filter);
  }


  // getUsuarios(desde: number = 0): Observable<usuarioList> {
  //   return this.http.get<usuarioList>(`${base_url}/usuarios`, this.headers);
  // }

  setEscuela(escuelaId: number): void {
    this.subscripciones.push(this.escuelaService.getEscuelaPorId(escuelaId)
      .subscribe(async (resp: any) => {
      }));
  }

  setConfiguracion(escuelaId: number): void {
    this.subscripciones.push(this.configuracionService.getConfiguracionsEscuela(escuelaId)
      .subscribe((resp: any) => {
        localStorage.setItem('configuracion', JSON.stringify(resp.resultList));
      }));
  }
  ngOnDestroy() {
    this.subscripciones.forEach(s => s.unsubscribe());
  }
}
