import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services/common/loader.service';

declare function customInitfunctions();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: [
  ]
})
export class PagesComponent implements OnInit {
  public linkTheme = document.querySelector('#theme');
  showSpinner = false;
  constructor(public spinnerService: LoaderService) { }

  ngOnInit(): void {
    customInitfunctions();
    const url = localStorage.getItem('theme') || './assets/css/colors/default-dark.css';
    this.linkTheme.setAttribute('href', url);
  }
}
