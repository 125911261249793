import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FunctionService {
	constructor() {
    }

    monthDiff(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() + 
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
        }
}