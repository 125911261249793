import { SubCategoria } from './../../../models/subcategoria.model';
import { Component, OnInit } from '@angular/core';
import { GradoService } from '../../../services/grado.service';
import { AlumnoReporteService } from '../../../services/alumno-reporte.service';
import { ResultList } from 'src/app/interfaces/generic.interface';
import { Grado } from 'src/app/models/grado.model';
import { take } from 'rxjs/operators';
import { Escuela } from 'src/app/models/escuela.model';

@Component({
  selector: 'app-alumnos-grado',
  templateUrl: './alumnos-grado.component.html',
  styleUrls: ['./alumnos-grado.component.css']
})
export class AlumnosGradoComponent implements OnInit {
  grados = [];
  gradoId = 0;
  escuela : Escuela;
  constructor(private alumnoReporteService: AlumnoReporteService, private gradosService: GradoService) { }

  ngOnInit(): void {
    this.escuela = JSON.parse(localStorage.getItem('escuela')) as Escuela;
    this.gradosService.all<ResultList<Grado>>().pipe(take(1)).subscribe(c => this.grados = c.resultList);
  }
  generarReporte(){
    this.alumnoReporteService.getAlumnosPorGrado(this.gradoId);
  }
}
