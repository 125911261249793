import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ResultList } from 'src/app/interfaces/generic.interface';
import { Categoria } from 'src/app/models/categoria.model';
import { CategoriaService } from 'src/app/services/categoria.service';

@Component({
  selector: 'app-categoria-list',
  templateUrl: './categoria-list.component.html',
  styleUrls: ['./categoria-list.component.css']
})
export class CategoriaListComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns = ['nombre', 'descripcion'];
  dataSource = new MatTableDataSource<Categoria>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  subscriptions = [];

  constructor(private service: CategoriaService) { }
  ngOnDestroy(): void {
    if (this.subscriptions){
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.service.getCategoriasPorEscuela()
      .subscribe((fs: ResultList<Categoria>) => {
        this.dataSource.data = fs.resultList;
      }));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
