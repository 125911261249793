<div class="col-12">
  <form class="form p-t-20" [formGroup]="categoriaForm" >
    <div class="row">
      <div class="form-group col-sm-4">
        <label for="inpNombre">Nombre *</label>
        <input type="text" class="form-control" id="inpNombre" placeholder="Nombre" formControlName="nombre">
      </div>
      <div class="form-group col-sm-8">
        <label for="inpDescripcion">Descripción *</label>
        <input type="text" class="form-control" id="inpDescripcion" placeholder="Descripción"
          formControlName="descripcion">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Conceptos ({{importeTotal | currency:'$'}})</h4>
            <button type="button" class="btn btn-basic mr-2" (click)="agregarConcepto()"> Agregar </button>
            <br><br>
            <table mat-table [dataSource]="dataSource" formArrayName="conceptos" class="mat-elevation-z8">
              <!-- Row definitions -->
              <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
              <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;" [formGroupName]="i"></tr>

              <!-- Column definitions -->
              <ng-container matColumnDef="concepto">
                <th mat-header-cell *matHeaderCellDef> Concepto </th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                  <select formControlName="concepto" class="mat-table-concepto-column"
                    (change)="onConceptoChange($event.target.value, index)">
                    <option *ngFor="let concepto of conceptos" [value]="concepto.id">{{conceptoDescripcion(concepto)}}
                    </option>
                  </select>
                </td>
              </ng-container>
              <ng-container matColumnDef="inscripcion">
                <th mat-header-cell *matHeaderCellDef> Inscripcion </th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                  <input type="text" readonly formControlName="esInscripcion" placeholder="esInscripcion">
                </td>
              </ng-container>
              <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef> Importe </th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                  <input type="number" formControlName="importe" placeholder="Importe" (focusout)="actualizarTotal()">
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                  <button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="removeFromIndex(index)">Quitar</button>
                  </mat-menu>
                </td>
              </ng-container>
            </table>

          </div>
        </div>
      </div>

    </div>
    <div class="action-buttons">
      <button type="button" class="btn btn-primary mr-2" [disabled]="categoriaForm.invalid" (click)="guardar()">Guardar</button>
      <button *ngIf="id > 0" type="button" class="btn btn-danger  mr-2" (click)="actualizarSaldos()">
        Actualizar Saldos Generados
      </button>
      <button type="button" (click)="volver()" class="btn btn-warning mr-2">Volver</button>
    </div>
  </form>
</div>