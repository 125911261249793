
export class Concepto {

    constructor(public id: number,
                public nombre: string,
                public importe: number,
                public fechaAlta: Date,
                public activo: boolean,
                public modificable: boolean,
                public esInscripcion: boolean,
                public escuelaId: number)
    { }
}


