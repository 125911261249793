import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  centered = false;
  disabled = false;
  unbounded = false;

  radius: number;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  constructor(private router: Router,) { }

  ngOnInit(): void {
    this.color1 = 'green';
    this.color2 = 'red';
    this.color3 = 'yellow';
    this.color4 = 'blue';
  }

  goAlumnos(){
    this.router.navigate(['/alumnos']);
  }

  goCategorias(){
    this.router.navigate(['/categorias']);
  }

  goFacturas(){
    this.router.navigate(['/facturas']);
  }

  goIE(){
    this.router.navigate(['/ingresoegreso']);
  }

}
