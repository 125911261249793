import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UsuarioService } from 'src/app/services/usuario.service';
import { FileUploadService } from 'src/app/services/file-upload.service';

import { Usuario } from 'src/models/usuario.model';
import { Escuela } from 'src/app/models/escuela.model';
import { EscuelaService } from 'src/app/services/escuela.service';
import { ResultList } from 'src/app/interfaces/generic.interface';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  public perfilForm: FormGroup;
  public usuario: Usuario;
  public imagenSubir: File;
  public imgTemp: any;
  public escuelas: Escuela[] = [];


  constructor(private fb: FormBuilder,
              private usurioService: UsuarioService,
              private fileUploadService: FileUploadService,
              private escuelaService: EscuelaService) {
    this.usuario = this.usurioService.usuario;
    this.escuelaService.all<ResultList<Escuela>>().pipe(take(1))
      .subscribe((fs: ResultList<Escuela>) => {
        this.escuelas = fs.resultList;
      });
  }

  ngOnInit(): void {
    this.perfilForm = this.fb.group({
      nombre: [this.usuario.nombre, Validators.required],
      email: [this.usuario.email, [Validators.required, Validators.email]],
      escuelaId: [this.usuario.escuelaId]
    });
    
  }
  
  public actualizarPerfil(){
    this.usurioService.actualizarPerfil(this.perfilForm.value)
    .subscribe(resp =>  {
        const {nombre, email, escuelaId} = this.perfilForm.value;
        this.usuario.nombre = nombre;
        this.usuario.email = email;
        this.usuario.escuelaId = escuelaId;
        this.usurioService.setEscuela(escuelaId);
        Swal.fire('Guardado', 'Cambios fueron guardados!', 'success');
      },
      (err) => {
        Swal.fire('Error', err.error.msg, 'error');

      });
  }

  public cambiarImagen(file: File){
    this.imagenSubir = file;

    if (!file) { return this.imgTemp = null; }

    const reader = new FileReader();
    const url64 = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.imgTemp = reader.result;
      console.log(reader.result);
    };
  }

  public subirImagen(){
    console.log(this.usurioService);
    this.fileUploadService.actualizarFotoPerfil(this.imagenSubir, 'usuarios', this.usurioService.uid)
    .then(img => this.usuario.img = img);
  }

}
