import { FacturaService } from './factura.service';
import { Injectable } from '@angular/core';
// import pdfMake from "../../../node_modules/pdfmake/build/pdfmake";
// import pdfFonts from "../../../node_modules/pdfmake/build/vfs_fonts";

import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CondicionIva } from '../models/alumno.model';
import { Escuela } from '../models/escuela.model';
import { Factura } from '../models/factura.model';
import { ConstService } from './const.service';
import { Observable } from 'rxjs';
import { IngresoEgreso } from '../models/ingresoegreso.model';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class ImpresionService {
  invoice = new FacturaInfo();

  constructor(private constService: ConstService, private facturaService: FacturaService)  { }
  // UTCToLocalTimeString(d, format) {
  //   if (timeOffsetInHours == null) {
  //       timeOffsetInHours = (new Date().getTimezoneOffset()/60) * (-1);
  //   }
  //   d.setHours(d.getHours() + timeOffsetInHours);

  //   return d.strftime(format);
  // }

  //https://github.com/bpampuch/pdfmake/blob/master/examples/vectors.js
  //https://pdfmake.github.io/docs/0.1/document-definition-object/page/
  generatePDF(action = 'open', factura: Factura, datosExtra: any) : Promise<any>{
    return new Promise((resolve, reject) => {
    const escuela = JSON.parse(localStorage.getItem('escuela')) as Escuela;
    const user = JSON.parse(localStorage.getItem('usuarie'));
    const posicionQr = 750;
    const posicionTotal = 650;
    const posicionAtentido = 770;
    const posicionCae = 800;
    const subTotalConceptos = Number(factura.cuentaCorriente.total);
    const cae = factura.cae;
    const atendido = user?.nombre ?? '';
    const gradoNombre = datosExtra?.grado?.nombre ?? factura.alumno.grado.nombre;
    const alumno = datosExtra?.alumno;
    let saldo = 0;
    let montoFactura =  Number(factura.monto);
    let pagosPrevios: Number;
    let imprimirConceptos = false
    const fecha = new Date(factura.fechaPago).toISOString().substring(10,0);
    let conceptoss = [];
    
    pagosPrevios = datosExtra.facturasAnteriores.map(f => f.monto).reduce((acc, value) => acc + Number(value), 0);
    // if(pagosPrevios === montoFactura){
    //   pagosPrevios = 0;
    // }
    saldo = factura.cuentaCorriente.total - (Number(pagosPrevios) + montoFactura);
    if((Number(pagosPrevios) + montoFactura) === subTotalConceptos || subTotalConceptos === montoFactura){
      factura.cuentaCorriente.conceptos.map(c => {
        conceptoss.push({nombre: c.categoriaConcepto.concepto.nombre, monto: c.monto});
      });
      imprimirConceptos = true;
    }

    const periodo = `${factura.mes.toString()}/${factura.anio.toString()}`;
    let comprobanteTipo = this.constService.getTipoComprobanteFacturasDesc(Number(factura.tipoComprobante));
    let condicionIvaTitular = (factura.alumno.condificonIvaTitular > 0 ? Number(factura.alumno.condificonIvaTitular) : Number(CondicionIva.consumidorFinal));
    let condicionIva = this.constService.getCondicionIvaDesc(condicionIvaTitular);
    //codigo verificador
    // let codigobarras = escuela.cuit + factura.tipoComprobante + escuela.puntoventa + cae + factura.fechaVtoCae;
    // let pares = 0, impares = 0, digito = 0;

    // for (let x = 1; x < codigobarras.length; x += 2)
    // {
    //     impares += parseInt(codigobarras[x]);
    // }
    // impares = impares * 3;

    // for (let x = 0; x < codigobarras.length; x += 2)
    // {
    //     pares += parseInt(codigobarras[x]);
    // }
    // pares = pares * 3;
    // let totalx = pares + impares;
    // while ((totalx / 10) * 10 != total)
    // {
    //   totalx = totalx + 1;
    //     digito = digito + 1;
    // }
    // codigobarras = codigobarras + digito;

    let puntoVenta = escuela.puntoventa;
    let docDefinition = {
      pageSize: 'A4',
       pageMargins: [20, 20, 20, 20],
       //watermark: { text: 'test watermark', color: 'blue', opacity: 0.3, bold: true, italics: false },
       content: [

         {
           columns: [
             {
              image:
              `data:image/png;base64, ${escuela.imagebase64}`,
              width: 150,
             },
             [
                 {
                 columns: [
                   {
                     text: comprobanteTipo,
                     color: '#333333',
                     width: '*',
                     fontSize: 22,
                     bold: true,
                     alignment: 'right',
                     margin: [0, 0, 0, 15],
                   },
                   {
                     text: 'Original',
                     color: '#333333',
                     width: '*',
                     fontSize: 16,
                     bold: true,
                     alignment: 'right',
                     margin: [0, 0, 0, 15],
                   }
                  ]
               },
               {
                columns: [
                  {
                    text: fecha,
                    color: '#333333',
                    width: '*',
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                    margin: [0, 0, 0, 15],
                  },
                  {
                    text: `(${factura.numero ?? ''})`,
                    color: '#333333',
                    width: '*',
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                    margin: [0, 0, 0, 15],
                  }
                 ]
              },
               {
                 stack: [
                   {
                     columns: [
                       {
                         text: 'CUIT:',
                         color: '#aaaaab',
                         bold: true,
                         width: '*',
                         fontSize: 12,
                         alignment: 'right',
                       },
                       {
                         text: escuela.cuit,
                         bold: true,
                         color: '#333333',
                         fontSize: 12,
                         alignment: 'right',
                         width: 100,
                       },
                     ],
                   },
                   {
                    columns: [
                      {
                        text: 'Ing. Brutos:',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 12,
                        alignment: 'right',
                      },
                      {
                        text: escuela.ingresosBrutos,
                        bold: true,
                        color: '#333333',
                        fontSize: 12,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                   {
                     columns: [
                       {
                         text: 'F.Insc Act:',
                         color: '#aaaaab',
                         bold: true,
                         width: '*',
                         fontSize: 12,
                         alignment: 'right',
                       },
                       {
                         text: escuela.fechaInscripcionAfip,
                         bold: true,
                         color: '#333333',
                         fontSize: 12,
                         alignment: 'right',
                         width: 100,
                       },
                     ],
                   },
                   {
                     columns: [
                       {
                         text: 'Pto Vta',
                         color: '#aaaaab',
                         bold: true,
                         fontSize: 12,
                         alignment: 'right',
                         width: '*',
                       },
                       {
                         text: puntoVenta,
                         bold: true,
                         fontSize: 14,
                         alignment: 'right',
                         color: 'green',
                         width: 100,
                       },
                     ],
                   },
                 ],
               },
             ],
           ],
         },
         {
           columns: [
             {
               text: `${escuela.nombre} de : ${escuela.titularColegio}`,
               color: '#aaaaab',
               bold: true,
               fontSize: 12,
               alignment: 'left',
               margin: [0, 20, 0, 5],
             }
            //  ,
            //  {
            //   text: 'Dirección',
            //   color: '#aaaaab',
            //   bold: true,
            //   fontSize: 12,
            //   alignment: 'left',
            //   margin: [0, 20, 0, 5],
            // },
            //  {
            //   text: 'Telefono',
            //   color: '#aaaaab',
            //   bold: true,
            //   fontSize: 12,
            //   alignment: 'left',
            //   margin: [0, 20, 0, 5],
            // },
            // {
            //   text: 'Dipregep',
            //   color: '#aaaaab',
            //   bold: true,
            //   fontSize: 12,
            //   alignment: 'left',
            //   margin: [0, 20, 0, 5],
            // },
           ],
         },
         {
          columns: [{
            text: `${escuela.direccion}` ,
            style: 'escuelaStyle'
          }]
         },
         {
          columns: [{
            text:`Telefono: ${escuela.telefono1}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }]
         },
         {
          columns: [{
            text: `Diegep: ${escuela.dipregep}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }]
         },
         {
          columns: [{
            text: `${escuela.subencion1}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }, {
            text: `${escuela.subencion2}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }]
         },
         {
           columns: [
             {
               text: 'Datos del Cliente',
               color: '#aaaaab',
               bold: true,
               fontSize: 12,
               margin: [0, 7, 0, 3],
             }
           ],
         },
         {
           columns: [
             {
               text: alumno.titularFactura,
               style: 'escuelaStyle',
             },
             {
               text: alumno.cuilTitular,
               style: 'escuelaStyle',
             },
             {
              text: condicionIva,
              style: 'escuelaStyle',
            },
           ],
         },
         {
          columns: [
            {
              text: `Alumno: ${alumno.nombre} ${alumno.apellido}`,
              style: 'alumnoStyle',
            },
            {
              text: `Grado: ${gradoNombre}`,
              style: 'alumnoStyle',
            },
            {
             text: `Período: ${periodo}`,
             style: 'alumnoStyle',
           },
           {
            text: `Arancel Base: 0`,
            style: 'alumnoStyle',
          },
          ],
        },
        //  {
        //   canvas: [
        //     {
        //       type: 'line',
        //       x1: -3, y1: 10,
        //       x2: 557, y2: 10,
        //       lineWidth: 2,
        //       lineColor: 'black',
        //       lineCap: 'round'
        //     },
        //   ]
        // },
        '\n',
         {
           width: '100%',
           alignment: 'center',
           text: 'Detalle de la factura',
           bold: true,
           margin: [0, 10, 0, 10],
           fontSize: 15,
         },
         !imprimirConceptos ?
         {
           ul: [
             `Factura correspondiente al período: ${periodo}`,
             `cuyo pago parcial fue de: $ ${montoFactura.toFixed(2)} corresponde a un total de de $ ${subTotalConceptos.toFixed(2)}.`,
             `pagos previos realizados: $ ${pagosPrevios.toFixed(2)}`,
             `el saldo actual es de: $ ${saldo.toFixed(2)}.`
           ],
         }
         :
         {
           layout: {
             defaultBorder: false,
             hLineWidth: function (i, node) {
               return 1;
             },
             vLineWidth: function (i, node) {
               return 1;
             },
             hLineColor: function (i, node) {
               if (i === 1 || i === 0) {
                 return '#bfdde8';
               }
               return '#eaeaea';
             },
             vLineColor: function (i, node) {
               return '#eaeaea';
             },
             hLineStyle: function (i, node) {
               // if (i === 0 || i === node.table.body.length) {
               return null;
               //}
             },
             // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
             paddingLeft: function (i, node) {
               return 10;
             },
             paddingRight: function (i, node) {
               return 10;
             },
             paddingTop: function (i, node) {
               return 2;
             },
             paddingBottom: function (i, node) {
               return 2;
             },
             fillColor: function (rowIndex, node, columnIndex) {
               return '#fff';
             },
           },
           table: {
             headerRows: 1,
             widths: ['*', 80],
             body: [
               [
                 {
                   text: 'Concepto',
                   fillColor: '#eaf2f5',
                   fontSize: 11,
                   border: [false, true, false, true],
                   margin: [0, 5, 0, 5],
                   textTransform: 'uppercase',
                 },
                 {
                   text: 'Monto',
                   border: [false, true, false, true],
                   fontSize: 11,
                   alignment: 'right',
                   fillColor: '#eaf2f5',
                   margin: [0, 5, 0, 5],
                   textTransform: 'uppercase',
                 },
               ],
               ...conceptoss.map(p => ([{text: p.nombre, border: [false, false, false, true], margin: [0, 1, 0, 1], alignment: 'left', fontSize: 8},
               {text: `$ ${p.monto}`, border: [false, false, false, true], fillColor: '#f5f5f5', alignment: 'right', margin: [0, 1, 0, 1], fontSize: 8}]))
             ],
           },
         },
         '\n',
         {
           layout: {
             defaultBorder: false,
             hLineWidth: function (i, node) {
               return 1;
             },
             vLineWidth: function (i, node) {
               return 1;
             },
             hLineColor: function (i, node) {
               return '#eaeaea';
             },
             vLineColor: function (i, node) {
               return '#eaeaea';
             },
             hLineStyle: function (i, node) {
               // if (i === 0 || i === node.table.body.length) {
               return null;
               //}
             },
             // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
             paddingLeft: function (i, node) {
               return 10;
             },
             paddingRight: function (i, node) {
               return 10;
             },
             paddingTop: function (i, node) {
               return 3;
             },
             paddingBottom: function (i, node) {
               return 3;
             },
             fillColor: function (rowIndex, node, columnIndex) {
               return '#fff';
             },
           },
           absolutePosition: { x: 0, y: posicionTotal },
           table: {
             headerRows: 1,
             widths: ['*', 'auto'],
             body: [
               [
                 {
                   text: 'Subtotal',
                   style: 'totalText',
                   margin: [0, 1, 0, 1],
                   fontSize: 10,
                   border: [false, true, false, true]
                 },
                 {
                   text: `$ ${subTotalConceptos.toFixed(2)}`,
                   margin: [0, 1, 0, 1],
                   fontSize: 10,
                   style: 'totalAmmout',
                 },
               ],
               [
                {
                  text: 'Pagos previos',
                  style: 'totalText',
                  margin: [0, 1, 0, 1],
                  fontSize: 10,
                  border: [false, false, false, true]
                },
                {
                  text: `$ ${pagosPrevios.toFixed(2)}`,
                  margin: [0, 1, 0, 1],
                  fontSize: 10,
                  style: 'totalAmmout',
                }
               ],
               [
                 {
                   text: 'Pago actual',
                   style: 'totalText',
                   margin: [0, 1, 0, 1],
                   fontSize: 10,
                   border: [false, false, false, true]
                 },
                 {
                   text: `$ ${montoFactura.toFixed(2)}`,
                   margin: [0, 1, 0, 1],
                   fontSize: 10,
                   style: 'totalAmmout',
                 }
               ],
             ],
           },
         },
          {
            absolutePosition: { x: 50, y: posicionQr },
            columns: [
              [{ qr: 'ssssss', fit: '50' }]
            ]
          },
          '\n\n',
          {
            text: 'Notas',
            style: 'notesTitle',
            absolutePosition: { x: 150, y: posicionQr },
          },
          {
          absolutePosition: { x: 150, y: posicionAtentido },
          columns: [
              {
                text: `Usted fue atendido/a por ${atendido}`,
                style: 'notesText',
              },
              {
                text: `Inicio de actividades: ${escuela.fechaInscripcionAfip}`,
                style: 'notesText',
              },
            ],
          },
          { text: `CAE: ${cae}`,
            alignment: 'left', italics: true, absolutePosition: { x: 50, y: posicionCae },
          }
       ],
       styles: {
         notesTitle: {
           fontSize: 10,
           bold: true,
           margin: [0, 50, 0, 3],
         },
         alumnoStyle: {
          fontSize: 10,
        },
         notesText: {
           fontSize: 10,
         },
         escuelaStyle: {
          fontSize: 11,
          bold: true,
          fillColor: '#f5f5f5',
        },
         totalText: {
           bold: true,
           fontSize: 11,
           alignment: 'right',
           margin: [0, 5, 0, 5],
         },
         totalAmmout: {
           alignment: 'right',
           fillColor: '#f5f5f5',
           margin: [0, 5, 0, 5],
           border: [false, true, false, true],
           fontSize: 11
         }
       },
       defaultStyle: {
         columnGap: 20,
         //font: 'Quicksand',
       },
     };

    if (action === 'download') {
      (<any>pdfMake).createPdf(docDefinition).download();
      resolve(true);
    } else if (action === 'print') {
      (<any>pdfMake).createPdf(docDefinition).print();
      resolve(true);
    } else if (action === 'email') {
      let pdfDoc = (<any>pdfMake).createPdf(docDefinition); 
      pdfDoc.getBase64((data) => {
      resolve(data);
      });
    } else {
      (<any>pdfMake).createPdf(docDefinition).open();
      resolve(true);
    }
  }
  //https://gist.github.com/tusharf5/034d3e0599ae87ec4033c53107965569

  )};

  generateIngresoEgresoPDF(ie: IngresoEgreso) : Promise<any>{
    return new Promise((resolve, reject) => {
    const action = 'open';
    const escuela = JSON.parse(localStorage.getItem('escuela')) as Escuela;
    const user = JSON.parse(localStorage.getItem('usuarie'));
    const posicionQr = 750;
    const posicionTotal = 650;
    const posicionAtentido = 770;
    const posicionCae = 800;
    const atendido = user?.nombre ?? '';
    let saldo = 0;
    let montoFactura =  Number(ie.monto);
    let pagosPrevios: Number;
    let imprimirConceptos = false
    const fecha = new Date(ie.fecha).toISOString().substring(10,0);
    let conceptoss = [];
    
    let comprobanteTipo = ie.tipo;
    let puntoVenta = escuela.puntoventa;
    let docDefinition = {
      pageSize: 'A4',
       pageMargins: [20, 20, 20, 20],
       //watermark: { text: 'test watermark', color: 'blue', opacity: 0.3, bold: true, italics: false },
       content: [

         {
           columns: [
             {
              image:
              `data:image/png;base64, ${escuela.imagebase64}`,
              width: 150,
             },
             [
                 {
                 columns: [
                   {
                     text: comprobanteTipo,
                     color: '#333333',
                     width: '*',
                     fontSize: 22,
                     bold: true,
                     alignment: 'right',
                     margin: [0, 0, 0, 15],
                   },
                   {
                     text: 'Original',
                     color: '#333333',
                     width: '*',
                     fontSize: 16,
                     bold: true,
                     alignment: 'right',
                     margin: [0, 0, 0, 15],
                   }
                  ]
               },
               {
                columns: [
                  {
                    text: fecha,
                    color: '#333333',
                    width: '*',
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                    margin: [0, 0, 0, 15],
                  },
                  {
                    text: `(${ie.numero ?? ''})`,
                    color: '#333333',
                    width: '*',
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                    margin: [0, 0, 0, 15],
                  }
                 ]
              },
               {
                 stack: [
                   {
                     columns: [
                       {
                         text: 'CUIT:',
                         color: '#aaaaab',
                         bold: true,
                         width: '*',
                         fontSize: 12,
                         alignment: 'right',
                       },
                       {
                         text: escuela.cuit,
                         bold: true,
                         color: '#333333',
                         fontSize: 12,
                         alignment: 'right',
                         width: 100,
                       },
                     ],
                   },
                   {
                    columns: [
                      {
                        text: 'Ing. Brutos:',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 12,
                        alignment: 'right',
                      },
                      {
                        text: escuela.ingresosBrutos,
                        bold: true,
                        color: '#333333',
                        fontSize: 12,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                   {
                     columns: [
                       {
                         text: 'F.Insc Act:',
                         color: '#aaaaab',
                         bold: true,
                         width: '*',
                         fontSize: 12,
                         alignment: 'right',
                       },
                       {
                         text: escuela.fechaInscripcionAfip,
                         bold: true,
                         color: '#333333',
                         fontSize: 12,
                         alignment: 'right',
                         width: 100,
                       },
                     ],
                   },
                   {
                     columns: [
                       {
                         text: 'Pto Vta',
                         color: '#aaaaab',
                         bold: true,
                         fontSize: 12,
                         alignment: 'right',
                         width: '*',
                       },
                       {
                         text: puntoVenta,
                         bold: true,
                         fontSize: 14,
                         alignment: 'right',
                         color: 'green',
                         width: 100,
                       },
                     ],
                   },
                 ],
               },
             ],
           ],
         },
         {
           columns: [
             {
               text: `${escuela.nombre} de : ${escuela.titularColegio}`,
               color: '#aaaaab',
               bold: true,
               fontSize: 12,
               alignment: 'left',
               margin: [0, 20, 0, 5],
             }
           ],
         },
         {
          columns: [{
            text: `${escuela.direccion}` ,
            style: 'escuelaStyle'
          }]
         },
         {
          columns: [{
            text:`Telefono: ${escuela.telefono1}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }]
         },
         {
          columns: [{
            text: `Diegep: ${escuela.dipregep}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }]
         },
         {
          columns: [{
            text: `${escuela.subencion1}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }, {
            text: `${escuela.subencion2}`,
            style: 'escuelaStyle',
            alignment: 'left',
          }]
         },
        '\n',
         {
           width: '100%',
           alignment: 'center',
           text: 'Descripcion',
           bold: true,
           margin: [0, 10, 0, 10],
           fontSize: 15,
         },
         {
           ul: [
             `${ie.descripcion}`,
             `Nro Comprobante: $ ${ie.numero}`,
             `Monto: $ ${ie.monto}`
           ],
         },
         '\n',
         {
           layout: {
             defaultBorder: false,
             hLineWidth: function (i, node) {
               return 1;
             },
             vLineWidth: function (i, node) {
               return 1;
             },
             hLineColor: function (i, node) {
               return '#eaeaea';
             },
             vLineColor: function (i, node) {
               return '#eaeaea';
             },
             hLineStyle: function (i, node) {
               // if (i === 0 || i === node.table.body.length) {
               return null;
               //}
             },
             // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
             paddingLeft: function (i, node) {
               return 10;
             },
             paddingRight: function (i, node) {
               return 10;
             },
             paddingTop: function (i, node) {
               return 3;
             },
             paddingBottom: function (i, node) {
               return 3;
             },
             fillColor: function (rowIndex, node, columnIndex) {
               return '#fff';
             },
           },
           absolutePosition: { x: 0, y: posicionTotal },
           table: {
             headerRows: 1,
             widths: ['*', 'auto'],
             body: [
               [
                 {
                   text: 'Monto',
                   style: 'totalText',
                   margin: [0, 1, 0, 1],
                   fontSize: 10,
                   border: [false, true, false, true]
                 },
                 {
                   text: `$ ${ie.monto}`,
                   margin: [0, 1, 0, 1],
                   fontSize: 10,
                   style: 'totalAmmout',
                 },
               ],
             ],
           },
         },
          {
          absolutePosition: { x: 150, y: posicionAtentido },
          columns: [
              {
                text: `Usted fue atendido/a por ${atendido}`,
                style: 'notesText',
              },
              {
                text: `Inicio de actividades: ${escuela.fechaInscripcionAfip}`,
                style: 'notesText',
              },
            ],
          }
       ],
       styles: {
         notesTitle: {
           fontSize: 10,
           bold: true,
           margin: [0, 50, 0, 3],
         },
         alumnoStyle: {
          fontSize: 10,
        },
         notesText: {
           fontSize: 10,
         },
         escuelaStyle: {
          fontSize: 11,
          bold: true,
          fillColor: '#f5f5f5',
        },
         totalText: {
           bold: true,
           fontSize: 11,
           alignment: 'right',
           margin: [0, 5, 0, 5],
         },
         totalAmmout: {
           alignment: 'right',
           fillColor: '#f5f5f5',
           margin: [0, 5, 0, 5],
           border: [false, true, false, true],
           fontSize: 11
         }
       },
       defaultStyle: {
         columnGap: 20,
         //font: 'Quicksand',
       },
     };

    // if (action === 'download') {
    //   (<any>pdfMake).createPdf(docDefinition).download();
    //   resolve(true);
    // } else if (action === 'print') {
    //   (<any>pdfMake).createPdf(docDefinition).print();
    //   resolve(true);
    // } else if (action === 'email') {
    //   let pdfDoc = (<any>pdfMake).createPdf(docDefinition); 
    //   pdfDoc.getBase64((data) => {
    //   resolve(data);
    //   });
    // } else {
      (<any>pdfMake).createPdf(docDefinition).open();
      resolve(true);
    // }
  }
  //https://gist.github.com/tusharf5/034d3e0599ae87ec4033c53107965569

  )};
}



class Concepto {

  constructor(public name: string,
              public precio: number,
              public qty: number
  ) {
    // Initially one empty product row we will show
    //this.conceptos.push(new Concepto());
  }
}
class FacturaInfo {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;

  conceptos: Concepto[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show
    //this.conceptos.push(new Concepto());
  }
}
