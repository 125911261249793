import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { pluck, take } from 'rxjs/operators'
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Grado } from '../../../../models/grado.model';
import { GradoService } from '../../../../services/grado.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gradoalumnopasaje',
  templateUrl: './gradoalumnopasaje.component.html',
  styleUrls: ['./gradoalumnopasaje.component.css']
})
export class GradoalumnopasajeComponent implements OnInit, OnDestroy {

  public grados: Grado[] = [];
  gradoOrigenId = 0;
  public gradoActual= '';
  public gradoDestinoId = 0
  subscriptions = [];
  constructor(public dialogRef: MatDialogRef<GradoalumnopasajeComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              private gradoService: GradoService) { }


  ngOnDestroy(): void {
    if (this.subscriptions){
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnInit(): void {
    this.gradoOrigenId = this.data.grado.id;
    this.gradoActual = this.data.grado.descripcion;
    this.subscriptions.push(this.gradoService.getGradosPorEscuelaNoPaging()
      .pipe(
        take(1),
        pluck('resultList')
      )
      .subscribe((fs: Grado[]) => {
			  this.grados = fs;
		}));
	
  }


  get puedePasarGrado(): boolean {
    return this.gradoDestinoId > 0 && this.gradoOrigenId > 0 && this.gradoOrigenId != this.gradoDestinoId;
  }

  public close(): void {
    this.dialogRef.close();
  }

  apply(): void {
    this.subscriptions.push(this.gradoService.pasarGrado(this.gradoOrigenId, this.gradoDestinoId)
      .subscribe((result: any) => {
        if (result.ok && result.affected > 0){
          Swal.fire('Exito', `El pasaje de grado se realizo sin errores. (Total de alumnos: ${result.affected}).`, 'success')
            .then((result) => {
                if (result.isConfirmed) {
                  this.dialogRef.close();
                }
            });

        } else {
          Swal.fire('Error', 'No fue posible realizar el pasaje de grado.', 'error')
        }
		},
    (err) => {
      Swal.fire('Error', err.error.msg, 'error');

    }));
  }

}
