import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilterExpression } from 'src/models/lookup.filter.data';
import { Usuario } from 'src/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class BaseService<TModel> {

  protected options: { route: string };
  constructor(protected http: HttpClient){
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token,
        'Access-Control-Allow-Origin': `${environment.back_url}`,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,OPTIONS',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Content-Type, Accept, Accept-Language, Origin, User-Agent'
      }
    };
  }

  get url() {
    return `${environment.base_url}/${this.options.route}`;
  }

  get urlAfip() {
    return `${environment.afipUrl}`;
  }


  all<TResult>(): Observable<TResult> {
		return this.http.get<TResult>(this.url, this.headers);
  }
  
	// retreive a specific client
	getById<TResult>(id: any) {
		return this.http.get<TResult>(`${this.url}/${id}`, this.headers);
	}

		// update a client
	update<TResult>(toUpdate: TModel) {
		return this.http.put<TResult>(this.url, toUpdate, this.headers);
	}

	create<TResult>(toNew: TModel) {
		return this.http.post<TResult>(this.url, toNew, this.headers);
	}

	// delete a client
	delete(id) {
		return this.http.delete(`${this.url}/${id}`, this.headers);
  }

  inactivate(uid) {
		return this.http.post(`${this.url}/inactivate/`, { id: uid}, this.headers);
  }

  reactivate(uid) {
		return this.http.post(`${this.url}/reactivate/`, { id: uid }, this.headers);
  }
  
  getFileredList(filter: FilterExpression): Observable<any> {
    return this.http.post(this.url + '/filtered', {
        operator: filter.operator,
        filters: filter.filters,
        pager: filter.pager,
        joins: filter.joins,
        childExpressions: filter.expressionChilds,
        allOrderBys: filter.allOrderBys
    },
    this.headers);
  }

  getFileredCount(filter: FilterExpression): Observable<any> {
    return this.http.post(this.url + '/filteredcount', {
        operator: filter.operator,
        filters: filter.filters,
        //pager: filter.pager,
        joins: filter.joins,
        childExpressions: filter.expressionChilds
    },
    this.headers);
  }
}
