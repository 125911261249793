<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Mi Perfil de Usuario</h4>
                <h6 class="card-subtitle">Ajustes</h6>
                <form class="form p-t-20" [formGroup]="perfilForm" (submit)="actualizarPerfil()">
                    <div class="form-group">
                        <label for="exampleInputuname2">Nombre</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="exampleInputuname2" placeholder="Username" formControlName="nombre">
                            <div class="input-group-addon"><i class="ti-user"></i></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail2">Correo</label>
                        <div class="input-group">
                            <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Enter email" formControlName="email" [readOnly]="usuario.google">
                            <div class="input-group-addon"><i class="ti-email"></i></div>
                        </div>
                    </div>
                    <div *ngIf="usuario.rol == 'ADMIN'" class="form-group">
                        <label for="iEscuela">Escuela</label>
                        <div class="input-group">
                            <select formControlName="escuelaId" style="width: inherit;" [(ngModel)]="usuario.escuelaId">
                                <option *ngFor="let escuela of escuelas" 
                                    [value]="escuela.id">{{escuela.nombre}}
                                </option>
                            </select>
                            <div class="input-group-addon"><i class="ti-home"></i></div>   
                        </div>
                    </div>
                    <div class="text-left">
                        <button type="submit" class="btn btn-success waves-effect waves-light m-r-10" [disabled]="perfilForm.invalid">Guardar</button>
                        <button type="submit" class="btn btn-inverse waves-effect waves-light">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Avatar</h4>
                <h6 class="card-subtitle">Imagen del usuario</h6>

                <div class="text-center">
                    <img *ngIf="!imgTemp" [src]="usuario.imageUrl" class="img-avatar">
                    <img *ngIf="imgTemp" [src]="imgTemp" class="img-avatar">
                </div>

                <input type="file" (change)="cambiarImagen($event.target.files[0])">

                <br>
                <div class="text-left">
                    <button type="submit" class="btn btn-success waves-effect waves-light m-r-10" (click)="subirImagen()" [disabled]="!subirImagen">Cambiar imagen</button>   
                </div>
            </div>
        </div>

    </div>
</div>
