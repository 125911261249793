<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="form-group col-sm-10">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
        </mat-form-field>
      </div>
      <div class="form-group col-sm-2" style="margin-top: 15px;">
        <button mat-flat-button color="primary" routerLink="new">
          <mat-icon>add</mat-icon>
          Nuevo Ingreso Egreso
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-table #table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="numero">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b>Numero</b></mat-header-cell>
            <mat-cell *matCellDef="let element"><a [routerLink]="['/ingresoegreso/', element.id]">{{ element.numero}}</a>
            </mat-cell>
                </ng-container>
          <ng-container matColumnDef="fecha">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha</b></mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.fecha | date:'dd/MM/yyyy' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="formaPago">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Forma Pago</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.formaPago | getDescriptions:'formaPago' }}
						</mat-cell>
					</ng-container>
          <ng-container matColumnDef="cuit">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b>Cuit</b></mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.cuit }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="monto">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b>Monto</b></mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.monto | currency }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="grado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Grado</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.grado?.nombre }}
						</mat-cell>
					</ng-container>
          <ng-container matColumnDef="alumno">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Alumno</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.alumno?.apellido }} {{ element.alumno?.nombre }}
						</mat-cell>
					</ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
            </td>
          </ng-container>
          <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="imprimir(element)">Imprimir</button>
              </mat-menu>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns">

          </mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
    <div class="row pull-right" style="margin-top: 15px;">
      <div class="action-buttons">
        <button type="button" class="btn btn-warning mr-2" (click)="getExcel()">
          <!-- <mat-icon>grid_on</mat-icon> -->
          Exportar a Excel
        </button>
      </div>
    </div>
  </div>
</div>