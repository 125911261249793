import { Injectable } from "@angular/core";
import { GenericList } from '../models/list-item.model';
import { ConstanstIds } from '../const/constantIds';
import { EstadoFactura, FormaPago, TipoComprobante, TipoFactura } from "../models/factura.model";
import { CondicionIva } from "../models/alumno.model";


@Injectable({ providedIn: 'root' })
export class ConstService {
	public listGeneros: GenericList = new GenericList();
	public listAnios: GenericList = new GenericList();
	public listMeses: GenericList = new GenericList();
	public listTipoComprobanteFactura = new GenericList();
	public listEstadoFactura = new GenericList();
    public listTipoFactura = new GenericList();
	public listTipoDocumentoAfip = new GenericList();
	public listCondicionIVa: GenericList = new GenericList();
	public listFormasMovimientos: GenericList = new GenericList();
	constantst = ConstanstIds;
	constructor() {
		this.listGeneros.list.push(
			{ id: this.constantst.generoFemenino, descripcion: 'Femenino' },
			{ id: this.constantst.generoMasculino, descripcion: 'Masculino' },
			{ id: this.constantst.generoOtro, descripcion: 'Otro' });

		for (let i = (new Date().getFullYear() + 1); i > 2019; i--) {
			this.listAnios.list.push({ id: i, descripcion: i.toString() });
		}

		for (let i = 1; i < 13; i++) {
			this.listMeses.list.push({ id: i, descripcion: i.toString() });
		}

		this.listTipoComprobanteFactura.list.push(
			{ id: TipoComprobante.reciboC, descripcion: 'Recibo C' },
			{ id: TipoComprobante.notaCreditoC, descripcion: 'Nota Credito C' });

		this.listEstadoFactura.list.push(
			{ id: EstadoFactura.activa, descripcion: 'Activa' },
			{ id: EstadoFactura.anulada, descripcion: 'Anulada' });

      this.listTipoFactura.list.push(
        { id: TipoFactura.factura, descripcion: 'FC' },
        { id: TipoFactura.notaCredito, descripcion: 'NC' });

		this.listTipoDocumentoAfip.list.push(
			{ id: 86, descripcion: 'CUIL' },
			{ id: 90, descripcion: 'CUIT' },
			{ id: 96, descripcion: 'DNI' });

		this.listCondicionIVa.list.push({descripcion : 'Resp. inscripto', id : CondicionIva.inscripto});
		this.listCondicionIVa.list.push({descripcion : 'Consumidor Final', id : CondicionIva.consumidorFinal});
		this.listCondicionIVa.list.push({descripcion : 'InscResp. Monotributo', id : CondicionIva.responsableMonotributo});
		this.listCondicionIVa.list.push({descripcion : 'Exento', id : CondicionIva.exento});

		this.listFormasMovimientos.list.push({descripcion : 'Efectivo', id : FormaPago.efectivo});
		this.listFormasMovimientos.list.push({descripcion : 'Cheque', id : FormaPago.cheque});
		this.listFormasMovimientos.list.push({descripcion : 'Deposito', id : FormaPago.deposito});
		this.listFormasMovimientos.list.push({descripcion : 'Tarjeta', id : FormaPago.tarjeta});
		this.listFormasMovimientos.list.push({descripcion : 'Transferencia', id : FormaPago.transferencia});
	}


	getFormapago() {
		return this.listFormasMovimientos;
	}

	getFormapagoDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listFormasMovimientos.get(id).descripcion;
		} else {
			return 'Sin forma de pago';
		}
	}

  getTipoFactura() {
		return this.listTipoFactura;
	}

	getTipoFacturaDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listTipoFactura.get(id).descripcion;
		} else {
			return 'Sin tipo factura';
		}
	}

	getCondicionIva() {
		return this.listCondicionIVa;
	}

	getCondicionIvaDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listCondicionIVa.get(id).descripcion;
		} else {
			return 'Sin tipo documento';
		}
	}

	getTipoDocumentoAfip() {
		return this.listTipoDocumentoAfip;
	}

	getTipoDocumentoAfipDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listTipoDocumentoAfip.get(id).descripcion;
		} else {
			return 'Sin tipo documento';
		}
	}

	getEstadoFactura() {
		return this.listEstadoFactura;
	}

	getEstadoFacturaDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listEstadoFactura.get(id).descripcion;
		} else {
			return 'Sin genero';
		}
	}

	getGeneros() {
		return this.listGeneros;
	}

	getGeneroDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listGeneros.get(id).descripcion;
		} else {
			return 'Sin genero';
		}
	}

	getAnios() {
		return this.listAnios;
	}

	getAniosDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listAnios.get(id).descripcion;
		} else {
			return '';
		}
	}

	getMeses(desdeMesActual?: boolean) {
		let result = new GenericList();
		let inicioMes = 1;
		if (desdeMesActual) {
			inicioMes = new Date().getMonth() + 1;
		}
		for (let i = inicioMes; i < 13; i++) {
			result.list.push({ id: i, descripcion: i.toString() });
		}
		return result;
	}

	getMesesDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listMeses.get(id).descripcion;
		} else {
			return '';
		}
	}

	getTipoComprobanteFacturasDesc(id: number): string {
		if (id !== undefined && id > 0) {
			return this.listTipoComprobanteFactura.get(id).descripcion;
		} else {
			return '';
		}
	}
	getTipoComprobanteFacturas() {
		return this.listTipoComprobanteFactura;
	}
}
