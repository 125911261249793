<div class="row">
	<div class="col-md-12">
		<h3 [hidden]="alumno.id > 0">Estado de cuenta</h3>
		<mat-form-field>
			<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
		</mat-form-field>
		<mat-table #table [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="cae">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Cae</b></mat-header-cell>
				<mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id>{{ element.cae }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="numero">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Numero</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.numero }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="periodo">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Periodo</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.mes }} / {{ element.anio }}</mat-cell>
			</ng-container>
			<!-- <ng-container matColumnDef="descuento">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Descuento</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.descuento | currency }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="intereses">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Intereses</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.intereses | currency }}</mat-cell>
			</ng-container> -->
			<ng-container matColumnDef="monto">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Pagado</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.monto | currency }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="fechapago">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha Pago</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.fechaPago | date:"dd/MM/yyyy":"+3" }}</mat-cell>
			</ng-container>
      <ng-container matColumnDef="tipo">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Tipo</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.tipo | getDescriptions:'tipofactura' }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="formaPago">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Forma Pago</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.formaPago | getDescriptions:'formaPago' }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="estado">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Estado</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.estado | getDescriptions:'estadofactura' }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="comentario">
				<mat-header-cell *matHeaderCellDef mat-sort-header><b>Comentario</b></mat-header-cell>
				<mat-cell *matCellDef="let element">{{ element.comentario }}</mat-cell>
			</ng-container>
			
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef> </th>
				<td mat-cell *matCellDef="let element">
					<button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item (click)="imprimir(element, 'open')">Imprimir</button>
						<button mat-menu-item (click)="enviarEmail(element)">Enviar email</button>
						<button mat-menu-item *ngIf="element.estado !== 2 && element.tipo !== 2" (click)="anular(element)">Anular</button>
						<button mat-menu-item (click)="enviarAfip(element)" *ngIf="element.cae === '' && element.estado !== 2" >Enviar Afip</button>
					</mat-menu>
				</td>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
		<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
		</mat-paginator>
	</div>
</div>
