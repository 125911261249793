<div class="row">
	<div class="form-group col-md">
		<mat-form-field>
			<input matInput #razonSocial="ngModel" [(ngModel)]="datos.razonSocial" name="razonSocial" type="text"
			id="razonSocial" required placeholder="Razon Social"/>
		</mat-form-field>
		<small class="text-danger font-14" *ngIf="razonSocial.touched && razonSocial.invalid">
			Razon Social es requerido
		</small>
	</div>
</div>
<div class="row">
	<div class="form-group col-md-6">
		<mat-form-field>
			<mat-select placeholder="Tipo de Documento" #documentoAfip="ngModel" [(ngModel)]="datos.tipodocumento" name="documentoAfip" id="documentoAfip">
			  <mat-option  *ngFor="let c of documentosAfip.list" [value]="c.id"> {{ c.descripcion}}
			  </mat-option>
			</mat-select>
		  </mat-form-field>
		  <small class="text-danger font-14"  *ngIf="documentoAfip.touched && documentoAfip.invalid">Tipo de Documento es requerido</small>
	</div>
	<div class="form-group col-md-6">
		<mat-form-field>
			<input matInput #nroDocumento="ngModel" [(ngModel)]="datos.nroDocumento" name="nroDocumento" type="text"
			id="nroDocumento" required placeholder="Nro Documento"/>
		</mat-form-field>
		<small class="text-danger font-14" *ngIf="nroDocumento.touched && nroDocumento.invalid">
			Nro Documento es requerido
		</small>
	</div>
</div>
<div class="row">
	<div class="form-group col-md-6">
		<mat-form-field>
			<mat-select placeholder="Forma de Pago" #formaPago="ngModel" [(ngModel)]="datos.formaPago" name="formaPago" id="formaPago">
			  <mat-option *ngFor="let c of formaDePago.list" [value]="c.id"> {{ c.descripcion}}
			  </mat-option>
			</mat-select>
		  </mat-form-field>
		  <small class="text-danger font-14"  *ngIf="formaPago.touched && formaPago.invalid">Forma de Pago es requerido</small>
	</div>
	
	<div class="form-group col-md-6">
		<mat-form-field>
			<input matInput  [(ngModel)]="datos.total" name="total" type="number" id="total" required placeholder="Total" readonly/>
		</mat-form-field>
	</div>
</div>
<div class="row">
	<div class="form-group col-md-12">
		<mat-form-field>
			<input matInput #razonSocial="ngModel" [(ngModel)]="datos.description" name="description" type="text"
			id="description" placeholder="Comentario"/>
		</mat-form-field>
	</div>
</div>
<div class="pull-right">
	<button  type="button" class="btn btn-warning mr-2" (click)="cancelar()">
		No Enviar
	</button>
	<button type="button" class="btn btn-primary" (click)="aceptar()">
		Enviar a Afip
	</button>
</div>