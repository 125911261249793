import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { Alumno } from '../models/alumno.model';
import { AlumnoService } from './alumno.service';

@Injectable({
  providedIn: 'root'
})
export class AlumnoResolver implements Resolve<Alumno> {
  id: number | null;
  constructor(private alumnoService: AlumnoService, route: ActivatedRoute) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Alumno> {
    this.id = route.params.id;
    return this.alumnoService.getAlumnoPorIdCompleto(this.id);
  }
}

//https://github.com/jorgetrigueros/angular-resolver-demo/blob/master/src/app/app.module.ts