import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GenericList } from 'src/app/models/list-item.model';
import { AlumnoService } from 'src/app/services/alumno.service';
import { ConstService } from 'src/app/services/const.service';
import { EscuelaService } from 'src/app/services/escuela.service';
import { GradoService } from 'src/app/services/grado.service';

import Swal from 'sweetalert2';

import { IngresoEgreso } from '../../../../models/ingresoegreso.model';
import { IngresoEgresoService } from '../../../../services/ingresosegreso.service';
import { Alumno } from 'src/app/models/alumno.model';
import { Grado } from 'src/app/models/grado.model';

@Component({
  selector: 'app-ingresoegreso-edit',
  templateUrl: './ingresoegreso-edit.component.html',
  styleUrls: ['./ingresoegreso-edit.component.css']

})
export class IngresoEgresoEditComponent implements OnInit {

  public ingresoEgreso: IngresoEgreso = new IngresoEgreso(0, '', 0, new Date(), 0, true,'','','',{} as Alumno,{} as Grado,0);
  id;
  grados = [];
  alumnos = [];
  gradoId = 0;
  alumnoId = 0;
  formaPagoId = 0;
  formaDePagoS: GenericList;
  constructor(private service: IngresoEgresoService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private escuelaService: EscuelaService,
              private gradosService: GradoService,
              private alumnoService: AlumnoService,
              private constService: ConstService) { 
              }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.gradosService.getGradosPorEscuelaNoPaging().pipe(take(1)).subscribe(c => this.grados = c.resultList);
    
    this.formaDePagoS = this.constService.getFormapago();

    if (this.id && this.id !== 'new') {
          this.service.getById<IngresoEgreso>(this.id)
            .subscribe((resp: any) => {
              this.ingresoEgreso = resp.result;
              this.gradoId = this.ingresoEgreso.grado.id;
              this.alumnoId = this.ingresoEgreso.alumno.id;
            });
        }else{
          this.ingresoEgreso = new IngresoEgreso(null, '', 0, new Date(), this.escuelaService.Escuela().id, true,'','','',{} as Alumno,{} as Grado,0);
        }
  }
  gradoChange(valor){
    this.alumnoService.getAlumnosPorEscuelaYGrado(valor).pipe(take(1)).subscribe(c => this.alumnos = c.resultList);
  }
  // tslint:disable-next-line: typedef
  save() {
    let g = this.grados.find(g => g.id === this.gradoId);
		if (g) {
			this.ingresoEgreso.grado = g;
		}

    let a = this.alumnos.find(g => g.id === this.alumnoId);
		if (a) {
			this.ingresoEgreso.alumno = a;
		}

    const save$: Observable<IngresoEgreso> = (this.id && this.id !== 'new') ?
      this.service.update(this.ingresoEgreso) :
      this.service.create(this.ingresoEgreso);

    save$.subscribe(resp => {
      this.router.navigate(['/ingresoegreso']);
    },
    (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    });
  }

  
  setFecha(evento: Date){
		this.ingresoEgreso.fecha = evento;
	}


  volver(): void {
    this.router.navigate(['/ingresoegreso']);
  }
 

}
