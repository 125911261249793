import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { take } from 'rxjs/operators';
import { Concepto } from 'src/app/models/concepto.model';
import { ResultList } from '../../../../interfaces/generic.interface';
import { ConceptoService } from 'src/app/services/concepto.service';

@Component({
  selector: 'app-concepto-list',
  templateUrl: './concepto-list.component.html',
  styleUrls: ['./concepto-list.component.css']
})
export class ConceptoListComponent implements OnInit, AfterViewInit {
  displayedColumns = ['nombre', 'importe', 'modificable', 'inscripcion', 'activo'];
  dataSource = new MatTableDataSource<Concepto>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: ConceptoService) { }

  ngOnInit() {
    this.service.getConceptosPorEscuela()
      .pipe(take(1))
      .subscribe((fs: ResultList<Concepto>) => {
        this.dataSource.data = fs.resultList;
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}




