<div class="container">
  <div class="row">
      <div class="form-group col-sm">
        <mat-form-field>
          <mat-select  #mesessd="ngModel" placeholder="Mes desde"  [(ngModel)]="mesSelectedDesde" name="mesSelectedDesde" id="mesSelectedDesde">
            <mat-option  *ngFor="let c of meses.list" [value]="c.id"> {{ c.descripcion}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small class="text-danger font-14"  *ngIf="mesessd.touched && mesessd.invalid">  Mes es requerido</small>
    </div>
    <div class="form-group col-sm">
      <mat-form-field>
        <mat-select   placeholder="Año desde" #aniossd="ngModel" [(ngModel)]="anioSelectedDesde" name="anioSelectedDesde" id="anioSelectedDesde">
          <mat-option  *ngFor="let c of anios.list" [value]="c.id"> {{ c.descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <small class="text-danger font-14"  *ngIf="aniossd.touched && aniossd.invalid">  Año es requerido</small>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm">
      <mat-form-field>
        <mat-select  placeholder="Mes hasta" #mesessh="ngModel" [(ngModel)]="mesSelectedHasta" name="mesSelectedHasta" id="mesSelectedHasta" >
          <mat-option  *ngFor="let c of meses.list" [value]="c.id"> {{ c.descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <small class="text-danger font-14"  *ngIf="mesessh.touched && mesessh.invalid">  Mes es requerido</small>
      </div>
    <div class="form-group col-sm">
      <mat-form-field>
        <mat-select placeholder="Año hasta" #aniossh="ngModel" [(ngModel)]="anioSelectedHasta" name="anioSelectedHasta" id="anioSelectedHasta">
          <mat-option  *ngFor="let c of anios.list" [value]="c.id"> {{ c.descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <small class="text-danger font-14"  *ngIf="aniossh.touched && aniossh.invalid">  Año es requerido</small>
    </div>
  </div>
  <div class="row pull-right" >
    <div class="action-buttons">
      <button type="button" class="btn btn-success mr-2" (click)="aceptar()">
        Aceptar
      </button>
      <button type="button" class="btn btn-warning" (click)="cancelar()">
        Cancelar
      </button>
    </div>
  </div>

</div>
