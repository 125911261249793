<div class="row">
	<div class="col-md-12">
		<h3 [hidden]="alumno.id > 0">Estado de cuenta</h3>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field>
					<mat-label>Año</mat-label>
					<mat-select (selectionChange)="filtrarAnio($event.value)">
						<mat-option *ngFor="let c of anios.list" [value]="c.id">{{ c.descripcion }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-table #table [dataSource]="dataSource" matSort>
					<ng-container matColumnDef="mes">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Mes - Año</b></mat-header-cell>
						<mat-cell *matCellDef="let element" [title]=element.id [cdkCopyToClipboard]=element.id>{{ element.mes }} / {{ element.anio }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="pagado">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Pagado</b></mat-header-cell>
						<mat-cell *matCellDef="let element" [ngStyle]="{'color': chooseColor(element) }" >{{ element.pagado | currency }}
						</mat-cell>

						<!-- <mat-cell *matCellDef="let element; row" [ngClass]="{'highlight': selectedRowIndex == row.id}"> {{element.bestRider}} </mat-cell> -->
					</ng-container>
					<ng-container matColumnDef="total">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Total</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.total | currency }}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="esInscripcion">
						<mat-header-cell *matHeaderCellDef mat-sort-header><b>Es Inscripcion?</b></mat-header-cell>
						<mat-cell *matCellDef="let element">{{(element.esInscripcion ? 'Si' : 'No')}}
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> </th>
						<td mat-cell *matCellDef="let element">
							<button mat-flat-button-sm class="shrink-2x" [matMenuTriggerFor]="menu">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="pagar(element)" *ngIf="puedePagar(element)">Pagar</button>
								<button mat-menu-item (click)="cancelar(element)">Cancelar</button>
								<button mat-menu-item (click)="actualizarSaldos(element)">Act.Saldo</button>
								<button mat-menu-item (click)="ver(element)">Ver</button>
							</mat-menu>
						</td>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>
				
				<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
				</mat-paginator>
			</div>
		</div>
		<div class="row pull-right" style="margin: 10px;">
			<div class="action-buttons">
				<button type="button" class="btn btn-success" (click)="generarCuenta()">
					Generar Cuenta
				</button>
			</div>
		</div>
	</div>
	
</div>
