import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { UsuarioService } from '../services/usuario.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private usuarioService: UsuarioService,
              private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      //console.log('paso por el guard canactivate');
      return this.usuarioService.validarToken().pipe(
        tap( isAuth => {
          if (!isAuth){
            this.router.navigateByUrl('/login');
          }

        })
      );
  }
}
