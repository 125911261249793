<div class="row">
	<div class="col-md-12">
		<div class="row">
			<h2>Este proceso actualizara todos los saldos de los alumnos, que no esten pagados completamente, desde el mes y año elegido para la categoria:
				<b>{{categoriaNombre}}</b>!
				Puede aplicar solo a el mes y año seleccionado y tambien a un grado especifico!</h2>
		</div>
		<div class="row">
			<div class="col-sm-3">
				<mat-form-field>
					<mat-select name="mes" id="mes" placeholder="Mes Desde"
						(selectionChange)="cambiarMes($event.value)">
						<mat-option *ngFor="let c of meses.list" [value]="c.id"> {{ c.descripcion}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field>
					<mat-select name="anio" id="anio" (selectionChange)="cambiarAnio($event.value)"
						placeholder="Año Desde">
						<mat-option *ngFor="let c of anios.list" [value]="c.id">{{ c.descripcion }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-checkbox name="soloEsteFecha" id="soloEsteFecha" [(ngModel)]="soloEsteFecha" style="margin-top: 15px;">
					Solo esta fecha
				</mat-checkbox>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field>
					<mat-select placeholder="Grado" #gradoIds="ngModel" [(ngModel)]="gradoId" name="gradoId"
						id="gradoId">
						<mat-option *ngFor="let c of grados" [value]="c.id"> {{ c.nombre}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-checkbox name="soloEsteGrado" id="soloEsteGrado" [(ngModel)]="soloEsteGrado" style="margin-top: 15px;">
					Solo este Grado
				</mat-checkbox>
			</div>
		</div>
		<div class="row pull-right">
			<div class="text-center" style="margin-top: 15px;" *ngIf="loading">
				<label class="btn btn-danger mr-2">Procesando, espere un momento!</label>
			</div>
			<div class="action-buttons" style="margin-top: 15px;" *ngIf="!loading">
				<button type="button" class="btn btn-danger mr-2" (click)="actualizarSaldos()">
					Actualizar Saldos
				</button>
				<button type="button" class="btn btn-warning mr-2" (click)="cancelar()">
					Cancelar
				</button>
			</div>
		</div>
	</div>
</div>