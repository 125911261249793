import { AlumnosGradoComponent } from './reportes/alumnos-grado/alumnos-grado.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';

import { PagesComponent } from './pages.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PerfilComponent } from './perfil/perfil.component';
import { UsuariosComponent } from './maestros/usuarios/usuarios.component';
import { GradosListComponent } from './maestros/grados/list/grados-list.component';
import { GradosEditComponent } from './maestros/grados/edit/grados-edit.component';
import { AlumnosListComponent } from './maestros/alumnos/list/alumnos-list.component';
import { AlumnosEditComponent } from './maestros/alumnos/edit/alumnos-edit.component';
import { EscuelaListComponent } from './maestros/escuela/list/escuela-list.component';
import { EscuelaEditComponent } from './maestros/escuela/edit/escuela-edit.component';
import { ConceptoListComponent } from './maestros/conceptos/list/concepto-list.component';
import { ConceptoEditComponent } from './maestros/conceptos/edit/concepto-edit.component';
import { GenerarcuentaalumnoComponent } from './facturas/generarcuentaalumno/generarcuentaalumno.component';
import { CategoriaListComponent } from './maestros/categoria/lista/categoria-list.component';
import { CategoriaEditComponent } from './maestros/categoria/editor/categoria-edit.component';
import { FacturasListComponent } from './facturas/facturas-list/facturas-list.component';
import {AlumnoResolver} from '../services/alumno-resolver.service';
import { CuentasCorrientesComponent } from './facturas/cuentascorrientes/cuentascorrientes.component';
import { IngresoEgresoListComponent } from './maestros/ingresoegreso/list/ingresoegreso-list.component';
import { IngresoEgresoEditComponent } from './maestros/ingresoegreso/edit/ingresoegreso-edit.component';
const moduleAdmin = 'Administracion';
const moduleUser = 'Usuaries';
const moduleReport = 'Reportes';
const moduleFacturacion = 'Facturacion';
const moduleGeneral = 'General';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent, data: { module:moduleGeneral, title: 'Dashboard' } },
      { path: 'account-settings', component: AccountSettingsComponent, data: { module:moduleUser, title: 'Ajustes de Cuenta' } },
      { path: 'perfil', component: PerfilComponent, data: { module:moduleUser, title: 'Mi Perfil' } },

      { path: 'usuarios', component: UsuariosComponent, data: { module:moduleAdmin, title: 'Listado de Usuarias/os' } },
      { path: 'grados', component: GradosListComponent, data: { module:moduleAdmin, title: 'Listado de Grados' } },
      { path: 'grados/:id', component: GradosEditComponent, data: { module:moduleAdmin,title: 'Edición de Grados' } },
      { path: 'grados/new', component: GradosEditComponent, data: { module:moduleAdmin,title: 'Nuevo Grados' } },
      { path: 'alumnos', component: AlumnosListComponent, data: { module:moduleAdmin,title: 'Listado de Alumnas/os' } },
      { path: 'alumnos/:id', component: AlumnosEditComponent, data: { module:moduleAdmin,title: 'Edición de Alumnas/os' }, resolve: { alumno: AlumnoResolver } },
      { path: 'alumnos/new', component: AlumnosEditComponent, data: { module:moduleAdmin,title: 'Nuevo Alumnas/os' } },
      { path: 'escuelas', component: EscuelaListComponent, data: { module:moduleAdmin,title: 'Listado de Escuelas' } },
      { path: 'escuelas/:id', component: EscuelaEditComponent, data: { module:moduleAdmin,title: 'Edición de Escuelas' } },
      { path: 'escuelas/new', component: EscuelaEditComponent, data: { module:moduleAdmin,title: 'Nueva Escuela' } },
      { path: 'conceptos', component: ConceptoListComponent, data: { module:moduleAdmin,title: 'Listado de Conceptos' } },
      { path: 'conceptos/:id', component: ConceptoEditComponent, data: { module:moduleAdmin,title: 'Edición de Concepto' } },
      { path: 'conceptos/new', component: ConceptoEditComponent, data: { module:moduleAdmin,title: 'Nuevo Conceptos' } },
      { path: 'categorias', component: CategoriaListComponent, data: { module:moduleAdmin,title: 'Listado de Categorias' } },
      { path: 'categorias/:id', component: CategoriaEditComponent, data: { module:moduleAdmin,title: 'Edición de Categoria' } },
      { path: 'categorias/new', component: CategoriaEditComponent, data: { module:moduleAdmin,title: 'Nueva Categoria' } },
      { path: 'generarcuentaalumno', component: GenerarcuentaalumnoComponent, data: { module:moduleFacturacion, title: 'Generar Cuenta del Alumna/o' } },
      { path: 'facturas', component: FacturasListComponent, data: { module:moduleFacturacion, title: 'Listado de Facturas' } },
      { path: 'saldos', component: CuentasCorrientesComponent, data: { module:moduleFacturacion,title: 'Estado de Cuenta de Alumnas/os' } },
      { path: 'alumnosporgrado', component: AlumnosGradoComponent, data: { module:moduleReport, title: 'Reporte de Alumnos por Grado' } },
      { path: 'ingresoegreso', component: IngresoEgresoListComponent, data: { module:moduleAdmin, title: 'Listado de Ingresos/Egresos' } },
      { path: 'ingresoegreso/:id', component: IngresoEgresoEditComponent, data: { module:moduleAdmin,title: 'Edición de Ingreso/Egreso' } },
      { path: 'ingresoegreso/new', component: IngresoEgresoEditComponent, data: { module:moduleAdmin,title: 'Nuevo Ingreso/Egreso' } },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PagesRoutingModule { }
