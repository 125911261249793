import { CategoriaConcepto } from './categoria-concepto.model';
import { environment } from '../../environments/environment';
import { Escuela } from './escuela.model';

const base_url = environment.base_url;

export class Categoria {

    constructor(public nombre: string,
                public descripcion: string,
                public escuela: Escuela,
                public id: number,
                public conceptos: CategoriaConcepto []
    ) { }
}
