import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CuentaCorriente, CuentaCorrienteList } from '../models/cuentacorriente.model';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { FilterExpression, FilterValue, JoinExpression, OrderExpression, Pager } from 'src/models/lookup.filter.data';
import { EscuelaService } from './escuela.service';

@Injectable({ providedIn: 'root' })
export class CuentaCorrienteService extends BaseService<any> {

	constructor(protected http: HttpClient, protected escuelaService: EscuelaService) {
		super(http);
		this.options = { route: 'cuentacorrientes' };
		
	}

	getCCPorAlumno(alumnoId, mes = 0, anio = 0): Observable<CuentaCorrienteList> {
		const filter = new FilterExpression('AND',
			[new FilterValue('CuentaCorriente.alumnoId', 'Equal', [alumnoId]),
			new FilterValue('CuentaCorriente.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
			new Pager(0, 500),
			[ new JoinExpression('CuentaCorrienteConcepto', 'CuentaCorriente.conceptos'),
			  new JoinExpression('CategoriaConcepto','CuentaCorrienteConcepto.categoriaConcepto' ),
			  new JoinExpression('Concepto', 'CategoriaConcepto.concepto' )
			], 
			[],
			[
				new OrderExpression('CuentaCorriente.anio', false),
				new OrderExpression('CuentaCorriente.mes', false)
			]);
		return super.getFileredList(filter);
	}

	createCCs<TResult>(ccs: CuentaCorriente[]) {
		return this.http.post<TResult>(this.url + '/ccs', ccs, this.headers);
	}

	generarPorPeriodo<TResult>(mesDesde, anioDesde) {
		const escuelaId = this.escuelaService.Escuela().id;
		return this.http.post<TResult>(this.url + '/generarperiodos', {mesDesde, anioDesde, escuelaId}, this.headers);
	}

  actualizarSaldosCategoria<TResult>(mesDesde, anioDesde, categoriaId, gradoId, soloFecha, soloGrado) {
		const escuelaId = this.escuelaService.Escuela().id;
		return this.http.post<TResult>(this.url + '/actualizarSaldosPorCategoria', {mesDesde, anioDesde, categoriaId, escuelaId, gradoId, soloFecha, soloGrado}, this.headers);
	}

	getMessageLog<TResult>(processId: number, lastId?: number) {
		const _lastId = lastId ? lastId : 0;
		return this.http.get<TResult>(
			`${this.url}/log/${processId}/${_lastId}`,
			this.headers);
	}

	//unificar partes con getFacturaEscuelaYFiltro
	getCuentasCorrientesConFiltros(filtros: any): Observable<CuentaCorrienteList> {
		let filtersExtra = [];
		if (filtros.filterLike && filtros.filterLike !== '') {
			const orLikes = new FilterExpression('OR',
				[
					new FilterValue('alumno.nombre', 'Like', [filtros.filterLike]),
					new FilterValue('alumno.apellido', 'Like', [filtros.filterLike]),
					new FilterValue('alumno.legajo', 'Like', [filtros.filterLike])
				]);
			filtersExtra.push(orLikes);
		}

		let pager = null;
		if(filtros.pageIndex !== null && filtros.size !== null){
			pager = new Pager((filtros.pageIndex * filtros.size) , filtros.size);
		}
		const filter = new FilterExpression('AND',
			[new FilterValue('CuentaCorriente.escuelaId', 'Equal', [this.escuelaService.Escuela().id])],
			pager,
			[new JoinExpression('Alumno', 'CuentaCorriente.alumno'),
			new JoinExpression('Grado', 'Alumno.grado')],
			//new JoinExpression('Factura', 'factura.cuentaCorriente', 'LEFT')
			filtersExtra);

		if (filtros.mes > 0 && filtros.anio > 0) {
			filter.filters.push(new FilterValue('CuentaCorriente.mes', 'Equal', [filtros.mes]));
			filter.filters.push(new FilterValue('CuentaCorriente.anio', 'Equal', [filtros.anio]));
		}

		if (filtros.gradoId > 0) {
			filter.filters.push(new FilterValue('alumno.grado.id', 'Equal', [filtros.gradoId]));
		}
		if (filtros.solodeuda) {
			filter.filters.push(new FilterValue('CuentaCorriente.total', 'GreaterThanField', ['CuentaCorriente.pagado']));
		}

		return super.getFileredList(filter);
	}
}
