import { IngresoEgresoService } from './ingresosegreso.service';
import { CuentaCorriente } from './../models/cuentacorriente.model';
import { FacturaService } from './factura.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ConstService } from './const.service';
import { Configuracion, ConfiguracionesNombre } from '../models/configuracion.model';
import { map, merge, mergeMap } from 'rxjs/operators';
import { Factura, TipoComprobante } from '../models/factura.model';

@Injectable({
	providedIn: 'root'
  })
export class FacturaReporteService implements OnDestroy {
	suscriptions = [];
	fuenteTitulo = 'Verdana,sans-serif';
	tipoFormatoExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	fuenteRow = 'Comic Sans MS';
	constructor(private facturaService: FacturaService, private constService: ConstService, private ingresoEgresoService: IngresoEgresoService) {}

	ngOnDestroy() {
		this.suscriptions.forEach(s => s.unsubscribe());
	}

	// obtiene las facturas por el filtro pasado.
	getExcelFacturas(escuelaId, filterLike, desde, hasta, exportarExcel, alumnoId) {
		this.facturaService.getFacturaEscuelaYFiltro(escuelaId, null, null, filterLike, desde, hasta, exportarExcel, alumnoId).subscribe(result => {
			if(result.resultList.length > 0){
				const columns = ['CAE', 'Numero', 'Fecha Pago', 'Mes/Año', 'Alumno', 'Legajo', 'Grado', 'A Pagar ($)', 'Pagado ($)', 'Forma Pago' , 'Tipo' ,'Estado', 'Comentario'];
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Alumnos por Grado');
				const titleRow = worksheet.addRow(columns);
				titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true,  size: 10 };
				let estado = '';
				let tipo = '';
				let fp = '';
				result.resultList.forEach((f: Factura) => {
					estado = this.constService.getEstadoFacturaDesc(f.estado);
					tipo = this.constService.getTipoComprobanteFacturasDesc(Number(f.tipoComprobante));
					fp = this.constService.getFormapagoDesc(f.formaPago);
					worksheet.addRow([f.cae, f.numero, `${new Date(f.fechaPago).toISOString().substring(10,0)}`,`${f.mes}-${f.anio}`, `${f.alumno.apellido} ${f.alumno.nombre}`, f.alumno.legajo, f.alumno.grado.nombre, f.cuentaCorriente.total, 
					f.monto, fp, tipo, estado, f.comentario ]);
				});

				this.escribirArchivoExcel(workbook, 'Facturas_excel.xlsx');
			}
		})
	}

	setValorMes(i, dato, monto){
		switch(i){
			case 1:
				dato.enero = (parseFloat(dato.enero) || 0) + parseFloat(monto);
				break;
			case 2:
				dato.febrero = (parseFloat(dato.febrero) || 0) + parseFloat(monto);;
				break;
			case 3:
				dato.marzo = (parseFloat(dato.marzo) || 0) + parseFloat(monto);
				break;
			case 4:
				dato.abril = (parseFloat(dato.abril) || 0) + parseFloat(monto);
				break;
			case 5:
				dato.mayo = (parseFloat(dato.mayo) || 0) + parseFloat(monto);
				break;
			case 6:
				dato.junio =  (parseFloat(dato.junio) || 0) + parseFloat(monto);
				break;
			case 7:
				dato.julio =  (parseFloat(dato.julio) || 0) + parseFloat(monto);
				break;
			case 8:
				dato.agosto =  (parseFloat(dato.agosto) || 0) + parseFloat(monto);
				break;
			case 9:
				dato.septiembre = (parseFloat(dato.septiembre) || 0) + parseFloat(monto);
				break;
			case 10:
				dato.octubre = (parseFloat(dato.octubre ) || 0) + parseFloat(monto);
				break;
			case 11:
				dato.noviembre = (parseFloat(dato.noviembre) || 0) + parseFloat(monto);
				break;
			case 12:
				dato.diciembre = (parseFloat(dato.diciembre)  || 0) + parseFloat(monto);
				break;
		}
		return dato;
	}
	setCeroMeses(r: reporteAranceles){
		r.enero = r.enero || 0;
		r.febrero = r.febrero || 0;
		r.marzo = r.marzo || 0;
		r.abril = r.abril || 0;
		r.mayo = r.mayo || 0;
		r.junio = r.junio || 0;
		r.julio = r.julio || 0;
		r.agosto = r.agosto || 0;
		r.septiembre = r.septiembre || 0;
		r.octubre = r.octubre || 0;
		r.noviembre = r.noviembre || 0;
		r.diciembre = r.diciembre || 0;
		return r;
	}
	getExcelAranceles(escuelaId, anio) {
		this.facturaService.getFacturaPorAnio(escuelaId, anio).subscribe(result => {
			const configuracion = JSON.parse(localStorage.getItem('configuracion')) as Configuracion [];
			const indexMesInscripcion = configuracion.findIndex(c => c.nombre === ConfiguracionesNombre[ConfiguracionesNombre.MesInscripcion]);
			let mesInscripcion = -1;
			if(indexMesInscripcion > -1){
				mesInscripcion = parseInt(configuracion[indexMesInscripcion].valor);
			}
			if(result.resultList.length > 0){
				const columns = ['Alumno', 'Grado', 'CAE', 'Inscripcion', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Total', 'Observacion'];
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Libro Aranceles' + String(anio));
				const titleRow = worksheet.addRow(columns);
				titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true,  size: 10 };
				let datos: reporteAranceles[] = [];

				for(let i = 1; i < 13; i++){
					//agarro las facturas por mes.
					let fmes = result.resultList.filter(f => f.mes === i);
					if(fmes.length > 0){
						fmes.forEach(e => {
							let index = datos.findIndex(d => d.alumnoId === e.alumno.id);
							if(index > -1){
								this.setValorMes(i, datos[index], e.monto);
							}
							else{
								datos.push({alumnoId: e.alumno.id, alumno: `${e.alumno.apellido} ${e.alumno.nombre}`, cae: e.cae, legajo: e.alumno.legajo, grado: e.alumno.grado.nombre,
								observacion: e.comentario, inscripcion: (i === mesInscripcion ? 'Si': 'No'), ...this.setValorMes(i, this, e.monto)});
							}
						});
					}
				}
				datos.sort((a, b) => a.alumno.toLowerCase() > b.alumno.toLowerCase() ? 1 :
				a.alumno.toLowerCase() < b.alumno.toLowerCase() ? -1: 0);
				datos.forEach((f: reporteAranceles) => {
					this.setCeroMeses(f);
					let total = f.enero + f.febrero + f.marzo + f.abril + f.mayo + f.junio + f.julio + f.agosto + f.septiembre + f.octubre + f.noviembre + f.diciembre;
					worksheet.addRow([f.alumno, f.grado, f.cae, f.inscripcion, f.enero, f.febrero, f.marzo, f.abril, f.mayo, f.junio, f.julio, f.agosto, f.septiembre,
						 f.octubre, f.noviembre, f.diciembre, total, f.observacion ]);
				});
				this.escribirArchivoExcel(workbook, 'Aranceles.xlsx');
			}
		});
	}


	getExcelFacturasIngresoEgreso(escuelaId, filterLike, desde, hasta, exportarExcel, cajadiaria) {
		//unir dos resultados de obs en una respuesta.
		const ieSub = this.ingresoEgresoService.getIngresoEgresosYFiltro(desde, hasta);
		const facSub = this.facturaService.getFacturaEscuelaYFiltroConDeuda(escuelaId, filterLike, desde, hasta, exportarExcel);
		ieSub.pipe(mergeMap(r1 => {
			return facSub.pipe(
				map(r2 => {
					return {r1, r2};
				})
			)
		})).subscribe(result => {
			if(result){
				const columns = ['CAE', 'Numero', 'Fecha','Mes/Año', 'Alumno', 'Grado', 'Cuil', 'Tipo', 'Monto ($)', 'Forma Pago' , 'Es Ingreso?', 'Comentario'];
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Caja por fechas');
				const encabezado = worksheet.addRow([cajadiaria ? `Reporte de Caja diaria: ${new Date(desde).toISOString().substring(10,0)} `: 
				`Reporte de Caja: ${new Date(desde).toISOString().substring(10,0)} - ${new Date(hasta).toISOString().substring(10,0)}`]);
				encabezado.font = { name: this.fuenteTitulo, family: 4, bold: true, size: 10 };
				worksheet.addRow('');
				const titleRow = worksheet.addRow(columns);
				titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true, size: 10 };
				//facturas
				if(result.r2){
					result.r2.resultList.forEach((f: any) => {
						worksheet.addRow([f.cae, f.numero, `${new Date(f.fechaPago).toISOString().substring(10,0)}`,`${f.mes}-${f.anio}`,`${f.alumno.apellido} ${f.alumno.nombre} - ${f.alumno.legajo}`, f.alumno.grado.nombre,
						'', this.constService.getTipoComprobanteFacturasDesc(Number(f.tipoComprobante)), (f.tipoComprobante === TipoComprobante.notaCreditoC ? (f.monto * -1) : (f.monto * 1)),
						this.constService.getFormapagoDesc(Number(f.formaPago)), 'Si',  f.comentario]);
					});
				}
				//ingresos egresos
				if(result.r1){
					result.r1.resultList.forEach((f: any) => {
						worksheet.addRow(['', f.numero, new Date(f.fecha).toISOString().substring(10,0),'', f.tipo, '', f.cuit, 'Ingreso/Egreso', (f.esEgreso ? (f.monto * -1) : (f.monto * 1)), 
						'',(f.esEgreso ? 'No': 'Si'),  f.descripcion]);
					});
				}
				if(cajadiaria){
					this.escribirArchivoExcel(workbook, `Caja${new Date(desde).toISOString().substring(10,0)}.xlsx`);
				}else{
					this.escribirArchivoExcel(workbook, 'Caja.xlsx');
				}
			}
		});
	}

	getExcelFacturasConDeuda(escuelaId, filterLike, desde, hasta, exportarExcel) {
		this.facturaService.getFacturaEscuelaYFiltroConDeuda(escuelaId, filterLike, desde, hasta, exportarExcel).subscribe(result => {
			if(result.resultList.length > 0){
				const columns = ['Mes/Año', 'Alumno', 'Legajo', 'Grado', 'A Pagar ($)', 'Pagado ($)', 'Saldo ($)' ,'Estado'];
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Cuentas con deuda');
				const titleRow = worksheet.addRow(columns);
				titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true, size: 10 };
				result.resultList.forEach((f: any) => {
					let estado = this.constService.getEstadoFacturaDesc(f.estado);
					worksheet.addRow([`${new Date(f.fechaPago).toISOString().substring(10,0)}`,`${f.alumno.apellido} ${f.alumno.nombre}`, f.alumno.legajo, f.alumno.grado.nombre, f.cuentaCorriente.total, f.monto, (f.cuentaCorriente.total - f.cuentaCorriente.pagado), estado ]);
				});

				this.escribirArchivoExcel(workbook, 'Facturas_conDeuda.xlsx');
			}
		})
	}

	// obtiene las facturas por el filtro pasado.
	getExcelSaldos(datos) {
		if(datos.length > 0){
			const columns = ['Mes/Año', 'Alumno', 'Legajo', 'Grado', 'A Pagar ($)', 'Pagado ($)'];
			const workbook = new Workbook();
			const worksheet = workbook.addWorksheet('Cuentas corrientes');
			const titleRow = worksheet.addRow(columns);
			titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true,  size: 10 };
			datos.forEach((f: CuentaCorriente) => {
				worksheet.addRow([`${f.mes}-${f.anio}`,`${f.alumno.apellido} ${f.alumno.nombre}`, f.alumno.legajo, f.alumno.grado.nombre, f.total, f.pagado ]);
			});

			this.escribirArchivoExcel(workbook, 'CC_SaldosAPagar.xlsx');
		}
	}

	getExcelSaldosAgrupadoAlumno(datos) {
		if(datos.length > 0){
			const columns2 = ['Mes/Año', 'A Pagar ($)', 'Pagado ($)'];
			const columns1 = ['Legajo', 'Grado'];
			const workbook = new Workbook();
			datos.forEach((f: any) => {
				let worksheet = workbook.addWorksheet(f.alumno);
				let titleRow = worksheet.addRow(columns1);
				worksheet.addRow([f.legajo, f.grado]);
				titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true,  size: 10 };
				worksheet.addRow([]);
				let titleRow2 = worksheet.addRow(columns2);
				titleRow2.font = { name: this.fuenteTitulo, family: 4, bold: true,  size: 10 };
				f.css.forEach(cc => {
					worksheet.addRow([`${cc.mes}-${cc.anio}`, cc.total, cc.pagado ]);
				});
			});

			this.escribirArchivoExcel(workbook, 'CC_AgrupadoAlumno.xlsx');
		}
	}


	escribirArchivoExcel(workbook: Workbook, nombreArchivo){
		workbook.xlsx.writeBuffer().then(data => {
			const blob = new Blob([data], { type: this.tipoFormatoExcel });
			fs.saveAs(blob, nombreArchivo);
	  });
	}
}
interface reporteAranceles {
	alumno: string,
	legajo: string,
	grado:string,
	cae:string,
	inscripcion:string,
	observacion:string,
	orden:string,
	enero: number,
	febrero: number,
	marzo: number,
	abril: number,
	mayo: number,
	junio: number,
	julio: number,
	agosto: number,
	septiembre: number,
	octubre: number,
	noviembre: number,
	diciembre: number,
	total: number,
	alumnoId: Number
}
//https://github.com/exceljs/exceljs
//basics queries https://www.youtube.com/watch?v=sKFLI5FOOHs


	// // reporte de entrega con detalle de ordenes


	// parsearSalesEarn = (data: any) => {
	// 	if (data.op) {
	// 		const columns = ['Producto', 'Fecha', 'Compra', 'Venta', 'Diferencia', 'Ultima compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ventas por Fecha');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(data.op, (pack: OrderPackage) => {
	// 			_.forEach(pack.orders, (order) =>{
	// 				_.forEach(order.shoppingCart.items, (item) =>{
	// 					let product = null;
	// 					_.forEach(data.prods, (p: Product) => {
	// 						if(p.key === item.key){
	// 							product = p;
	// 						}
	// 					});
	// 					worksheet.addRow([item.title, this.helperService.obtenerFechaCorta(pack.datePlaced), this.helperService.validarNumero(product.buyprice), this.helperService.validarNumero(item.price),
	// 						(this.helperService.validarNumero(item.price) - this.helperService.validarNumero(product.buyprice)), this.helperService.obtenerFechaCorta(product.lastDateBuy)]);
	// 				});
	// 			});
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Productos_Ganancias.xlsx');
	// 	}
	// }

	// parsearSales = (data: any) => {
	// 	if(data.op){
	// 		const columns = ['Producto', 'Precio Compra', 'Precio Venta', 'Diferencia', 'Ultima compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ventas por Fecha');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(data.op, (pack: OrderPackage) => {
	// 			_.forEach(pack.orders, (order) =>{
	// 				_.forEach(order.shoppingCart.items, (item) =>{
	// 					let product = null;
	// 					_.forEach(data.prods, (p: Product) => {
	// 						if(p.key === item.key){
	// 							product = p;
	// 						}
	// 					});
	// 					worksheet.addRow([item.title, this.helperService.validarNumero(product.buyprice), this.helperService.validarNumero(item.price),
	// 						(this.helperService.validarNumero(item.price) - this.helperService.validarNumero(product.buyprice)), this.helperService.obtenerFechaCorta(product.lastDateBuy)]);
	// 				});
	// 			});
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Ventas.xlsx');
	// 	}
	// }

	// parsearOrdenes = (data: any) => {
	// 	const orders = data.val() as Order [];
	// 	if (orders) {
	// 		const columns = ['Cliente', 'Fecha Compra', 'Estado', 'Total'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ordenes por Fecha');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(orders, (pack: Order) => {
	// 			worksheet.addRow([pack.shipping.name, this.helperService.obtenerFechaCorta(pack.datePlaced), this.constService.getOrderStatusDesc(pack.status),pack.total]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Ordenes.xlsx');
	// 	}
	// }

	// parsearOrdenesToProvider = (data: any) => {
	// 	if (data.orders) {
	// 		const columns = ['Producto', 'Cantidad','Proveedor', 'Telefono', 'Fecha Compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Ordenes a pedir al Proveedor');
	// 		const titleRow = worksheet.addRow(columns);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		let renglones = new Array();
	// 		_.forEach(data.orders, (order: Order) => {
	// 			_.forEach(order.shoppingCart.items, (item) => {
	// 				// item.key -> product
	// 				let proveedor = '';
	// 				let proveedorTelefono = '';
	// 				const producto = data.products[item.key];
	// 				if(producto){
	// 					const providerKey = data.providers[producto.providerkey];
	// 					if(providerKey){
	// 						proveedor = providerKey.name;
	// 						proveedorTelefono = providerKey.telephone;

	// 						const index = renglones.findIndex(x => x.productKey === producto.key && x.providerKey === producto.providerkey);
	// 						if(index > -1){
	// 							renglones[index].quantity = renglones[index].quantity + item.quantity;
	// 						}else{
	// 							renglones.push({productKey: producto.key,providerKey: producto.providerkey,producto: item.title, datePlaced: this.helperService.obtenerFechaCorta(order.datePlaced), quantity: item.quantity, proveedor, proveedorTelefono});
	// 						}
	// 					}
	// 				}
	// 			});
	// 		});
	// 		renglones.forEach(r => {
	// 			worksheet.addRow([r.producto, r.quantity, r.proveedor, r.proveedorTelefono, r.datePlaced]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Ordenes.xlsx');
	// 	}
	// }

	// // parsea los daots para generar  el reporde de productos stock.
	// parsearProductosParaStock = (snap: any) => {
	// 	const products = snap.val() as Product [];
	// 	if (products) {
	// 		const title = ['Producto', 'Precio Compra', 'Precio Venta', 'Stock', 'Ultima Compra'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Stock de Productos');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		//ajustar aca pq ahora hay que tomar las specs para generar el reporte de stock
	// 		// _.forEach(products, (p: Product) => {
	// 		// 	worksheet.addRow([p.title, p.buyprice, p.price, p.stock, this.helperService.obtenerFechaCorta(p.lastDateBuy)]);
	// 		// })
	// 		this.escribirArchivoExcel(workbook, 'Producto_Stock.xlsx');
	// 	}
	// }

	// generardetalleEntregasExcel = (snap: any) => {
	// 	const orderPacks = snap.val() as OrderPackage [];
	// 	const workbook = new Workbook();
	// 	const worksheet = workbook.addWorksheet('Entregas');
	// 	_.forEach(orderPacks, (orderPack: OrderPackage) => {
	// 		let fecha = orderPack.dateEstimated;
	// 		if(fecha === undefined || fecha === null){
	// 			fecha = new Date().getTime();
	// 		}
	// 		const title = ['Detalle de entrega', 'Fecha estimada',  this.helperService.obtenerFechaCorta(fecha), 'Estado', this.constService.getOrderPackageStatusDesc(orderPack.status)];
	// 		const header = ['Cliente', 'Direccion',  'Total de la orden($)']; // necesito 3 valores, mapea columna con valores por cantidad.
	// 		const detail = ['', 'Producto', 'Cantidad', 'Total($)'];
	// 		// https://github.com/ngdevelop-tech/angular-6-export-an-excel-file


	// 		let row;
	// 		let detailRow;
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteRow, family: 4, bold: true };
	// 		titleRow.eachCell((cell, number) => {
	// 		});
	// 		worksheet.addRow([]);
	// 		let direccion;
	// 		_.forEach(orderPack.orders, (order: Order) => {
	// 			row = worksheet.addRow(header);
	// 			row.font = { name: this.fuenteRow, family: 4, bold: true };
	// 			direccion = `${order.shipping.address} entre ${order.shipping?.addressLine1} / ${order.shipping?.addressLine2} , ${order.shipping.city}`;
	// 			row = worksheet.addRow([order.shipping.name, direccion, order.total]);
	// 			detailRow = worksheet.addRow(detail);
	// 			detailRow.font = { name: this.fuenteRow, family: 4, bold: false };
	// 			_.forEach(order.shoppingCart.items, (item) => {
	// 				worksheet.addRow(['', item.title, item.quantity, (item.price * item.quantity)]);
	// 			});

	// 			worksheet.addRow([]);
	// 		});
	// 	});
	// 	this.escribirArchivoExcel(workbook, 'Entregas.xlsx');
	// }

	// // parsea las entregas para generar el reporte de la entrega y su detalle de ordens.
	// generardetalleEntregaExcel = (snap: any) => {
	// 	const orderPack = snap.val() as OrderPackage;
	// 	let fecha = orderPack.dateEstimated;
	// 	if(fecha === undefined || fecha === null){
	// 		fecha = new Date().getTime();
	// 	}
	// 	const title = ['Detalle de entrega', 'Fecha estimada', this.helperService.obtenerFechaCorta(fecha)];
	// 	const header = ['Cliente', 'Direccion', 'Total de la orden($)']; // necesito 3 valores, mapea columna con valores por cantidad.
	// 	const detail = ['', 'Producto', 'Cantidad', 'Total($)'];
	// 	// https://github.com/ngdevelop-tech/angular-6-export-an-excel-file

	// 	const workbook = new Workbook();
	// 	const worksheet = workbook.addWorksheet('Entrega');
	// 	let row;
	// 	let detailRow;
	// 	const titleRow = worksheet.addRow(title);
	// 	titleRow.font = { name: this.fuenteRow, family: 4, bold: true };
	// 	titleRow.eachCell((cell, number) => {
	// 		// cell.fill = {
	// 		//   type: 'pattern',
	// 		//   pattern: 'solid',
	// 		//   fgColor: { argb: 'FFFFFF00' },
	// 		//   bgColor: { argb: 'FF0000FF' }
	// 		// };
	// 		//F:\Development\EShop\node_modules\exceljs\index.d.ts
	// 		//cell.alignment = { shrinkToFit:true }; -> ajusta el texto para que entre en la celda
	// 	});
	// 	//worksheet.mergeCells('A1:C1',);
	// 	worksheet.addRow([]);
	// 	let direccion;
	// 	_.forEach(orderPack.orders, (order: Order) => {
	// 		row = worksheet.addRow(header);
	// 		row.font = { name: this.fuenteRow, family: 4, bold: true };
	// 		direccion = `${order.shipping.address} entre ${order.shipping?.addressLine1} / ${order.shipping?.addressLine2} , ${order.shipping.city}`;
	// 		row = worksheet.addRow([order.shipping.name, direccion, order.total]);
	// 		detailRow = worksheet.addRow(detail);
	// 		detailRow.font = { name: this.fuenteRow, family: 4, bold: true };
	// 		_.forEach(order.shoppingCart.items, (item) => {
	// 			worksheet.addRow(['',item.title, item.quantity, (item.price * item.quantity)]);
	// 		});
	// 	});
	// 	this.escribirArchivoExcel(workbook, 'Entregas.xlsx');
	// }

	// parsearClientes = (snap: any) => {
	// 	const clients = snap.val() as Client [];
	// 	if (clients) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefone'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Clientes');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		let direccion;
	// 		worksheet.addRow([]);
	// 		_.forEach(clients, (p: Client) => {
	// 			direccion = `${p.address} entre ${p?.addresslat1} / ${p?.addresslat2} , ${p?.city}`;
	// 			worksheet.addRow([p.name, p.code, direccion, p.telephone]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Clientes.xlsx');
	// 	}
	// }

	// // este reporte deberia ir a gestion y traer la info de ahi, no a google.
	// parsearClientesConSaldo = (snap: any) => {
	// 	const clients = snap.val() as Client [];
	// 	if (clients) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefone', 'Saldo'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Clientes');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		let direccion;
	// 		worksheet.addRow([]);
	// 		_.forEach(clients, (p: Client) => {
	// 			direccion = `${p.address} entre ${p?.addresslat1} / ${p?.addresslat2} , ${p?.city}`;
	// 			worksheet.addRow([p.name, p.code, direccion, p.telephone, p.saldo]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'ClientesSaldo.xlsx');
	// 	}
	// }

	// parsearProveedores = (snap: any) => {
	// 	const proveedores = snap.val() as Provider [];
	// 	if (proveedores) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefono'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Proveedores');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(proveedores, (p: Provider) => {
	// 			worksheet.addRow([p.name, p.codigo, p.direccion, p.telephone]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Proveedores.xlsx');
	// 	}
	// }

	// parsearProveedoresConSaldo = (snap: any) => {
	// 	const proveedores = snap.val() as Provider [];
	// 	if (proveedores) {
	// 		const title = ['Nombre', 'Codigo', 'Direccion', 'Telefono', 'Saldo'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Proveedores');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		worksheet.addRow([]);
	// 		_.forEach(proveedores, (p: Provider) => {
	// 			worksheet.addRow([p.name, p.codigo, p.direccion, p.telephone, p.saldo]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'ProveedoresSaldo.xlsx');
	// 	}
	// }

	// // parsea los daots para generar  el reporde de productos stock.
	// generarExcelResumenCaja = (datos: any) => {
	// 	if (datos) {
	// 		const title = ['Fecha', 'Ingresos', 'Egresos', 'Direrencia'];
	// 		const titleMovimientos = ['Fecha', 'Numero', 'Monto', 'Tipo', 'Ingreso?', 'CAE'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Resumen de Cajas', {state: 'visible'});
	// 		worksheet.state = 'visible';
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		_.forEach(datos.datos, (p: any) => {
	// 			worksheet.addRow([this.helperService.obtenerFechaCorta(p.fecha), p.ingresos, p.egresos, p.diferencia]);
	// 			const nombreSheet = `Movimientos_Caja_${this.helperService.obtenerFechaCortaGuion(p.fecha)}`;
	// 			const worksheetC = workbook.addWorksheet(nombreSheet, {state: 'visible'});
	// 			worksheetC.state = 'visible';
	// 			// detalle de movimientos por caja
	// 			worksheetC.addRow(titleMovimientos);
	// 			const movs = p.movimientos;
	// 			_.forEach(movs, (mov: any) => {
	// 				worksheetC.addRow([this.helperService.obtenerFechaCorta(mov.fecha), mov.numero,  mov.total, this.constService.getFormaMovimiento(mov.forma),
	// 					(p.ingreso ? 'Si' : 'No'),  mov.afipCAE]);
	// 			});
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Resumen_Cajas.xlsx');
	// 	}
	// }

	// // parsea los daots para generar  el reporde de productos stock.
	// generarExcelMovimientos = (datos: any) => {
	// 	if (datos) {
	// 		const title = ['Fecha', 'Numero', 'Monto', 'Tipo', 'Ingreso?', 'CAE'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Resumen de Movimientos');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		_.forEach(datos, (p: any) => {
	// 			worksheet.addRow([this.helperService.obtenerFechaCorta(p.fecha), p.numero,  p.total, this.constService.getFormaMovimiento(p.forma),
	// 				(p.ingreso ? 'Si' : 'No'),  p.afipCAE]);
	// 		});
	// 		this.escribirArchivoExcel(workbook, 'Resumen_Movimientos.xlsx');
	// 	}
	// }

	// generarExcelMovimientosDetallado = (datos: any) => {
	// 	if (datos) {
	// 		const title = ['Fecha', 'Numero', 'Monto', 'Tipo', 'Ingreso?', 'CAE'];
	// 		const workbook = new Workbook();
	// 		const worksheet = workbook.addWorksheet('Resumen de Movimientos');
	// 		const titleRow = worksheet.addRow(title);
	// 		titleRow.font = { name: this.fuenteTitulo, family: 4, bold: true };
	// 		_.forEach(datos.movimientos, (p: any) => {
	// 			worksheet.addRow([this.helperService.obtenerFechaCorta(p.fecha), p.numero,  p.total, this.constService.getFormaMovimiento(p.forma),
	// 				(p.ingreso ? 'Si' : 'No'),  p.afipCAE]);
	// 		});
	// 		if (datos.cheques) {
	// 			const titleCheque = ['Fecha', 'Numero', 'Monto', 'Vencimiento', 'Deposito', 'Banco', 'Emisor', 'Receptor', 'Ingreso?', 'Propio?', 'Rechazado?'];
	// 			const worksheetC = workbook.addWorksheet(`Cheques`, {state: 'visible'});
	// 			worksheetC.addRow(titleCheque);
	// 			worksheetC.state = 'visible';
	// 			_.forEach(datos.cheques, (c: any) => {
	// 				worksheetC.addRow([this.helperService.obtenerFechaCorta(c.fechaEmision), c.numero,  c.total, this.helperService.obtenerFechaCorta(c.fechaVencimiento),
	// 					this.helperService.obtenerFechaCorta(c.fechaDeposito), c.banco, c.emisor, c.receptor, (c.ingreso ? 'Si' : 'No'), (c.esPropio ? 'Si' : 'No'),
	// 					(c.rechazado ? 'Si' : 'No')]);
	// 			});
	// 		}
	// 		if (datos.tarjetas) {
	// 			const titleTarjeta = ['Fecha', 'Numero', 'Monto', 'Banco', 'Es debito automatico', 'Titular', 'Ingreso?'];
	// 			const worksheetT = workbook.addWorksheet(`Tarjetas`, {state: 'visible'});
	// 			worksheetT.addRow(titleTarjeta);
	// 			worksheetT.state = 'visible';
	// 			_.forEach(datos.tarjetas, (t: any) => {
	// 				worksheetT.addRow([this.helperService.obtenerFechaCorta(t.fecha), t.numeroTarjeta,  t.total, t.banco, (t.esDebitoAutomatico ? 'Si' : 'No'), t.titularTarjeta,
	// 					 (t.ingreso ? 'Si' : 'No')]);
	// 			});
	// 		}
	// 		if (datos.depositos) {
	// 			const titleDepositos = ['Fecha', 'Orden', 'Monto', 'Banco',  'Ingreso?'];
	// 			const worksheetD = workbook.addWorksheet(`Depositos`, {state: 'visible'});
	// 			worksheetD.addRow(titleDepositos);
	// 			worksheetD.state = 'visible';
	// 			_.forEach(datos.depositos, (t: any) => {
	// 				worksheetD.addRow([this.helperService.obtenerFechaCorta(t.fecha), t.numeroOrden, t.total, t.banco, (t.ingreso ? 'Si' : 'No')]);
	// 			});
	// 		}
	// 		if (datos.transferencias) {
	// 			const titleTransferencias = ['Fecha', 'Orden', 'Monto', 'Banco', 'Emisor', 'Receptor', 'Ingreso?'];
	// 			const worksheetTR = workbook.addWorksheet(`Transferencias`, {state: 'visible'});
	// 			worksheetTR.addRow(titleTransferencias);
	// 			worksheetTR.state = 'visible';
	// 			_.forEach(datos.transferencias, (t: any) => {
	// 				worksheetTR.addRow([this.helperService.obtenerFechaCorta(t.fecha), t.numeroOrden, t.total, t.banco, t.emisor, t.receptor, (t.ingreso ? 'Si' : 'No')]);
	// 			});
	// 		}

	// 		this.escribirArchivoExcel(workbook, 'Resumen_Movimientos.xlsx');
	// 	}
	// }
