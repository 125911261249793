import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import Swal from 'sweetalert2'
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    './register.component.css'
  ]
})
export class RegisterComponent implements OnInit {

  public registerForm = this.fb.group({
    nombre: ['', Validators.required],
    email: ['jmb@gmail.com', [Validators.required, Validators.email]],
    password:['1234', Validators.required],
    password2:['1234', Validators.required],
    terminos:[false, Validators.required]

  });

  public formSubmitted: boolean = false;

  constructor(private  fb: FormBuilder,
              private usuarioService: UsuarioService,
              private router: Router ) { }

  ngOnInit(): void {
  }

  crearUsuario(): void {
    this.formSubmitted = true;
    if (this.registerForm.invalid){
      return;
    }
    this.usuarioService.crearUsuario(this.registerForm.value)
    .subscribe( resp => {
        this.router.navigateByUrl('/');
    }, (err) => {
        Swal.fire('Error', err.error.msg, 'error');
    });
  }

  campoNoValido(fieldName: string): boolean {
    return (this.registerForm.get(fieldName).invalid && this.formSubmitted);
  }
}
