<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="form-group col-sm-6">
            <div
              class="example-ripple-container mat-elevation-z4"
              matRipple
              [matRippleCentered]="centered"
              [matRippleDisabled]="disabled"
              [matRippleUnbounded]="unbounded"
              [matRippleRadius]="radius"
              [matRippleColor]="color1"
              (click)="goAlumnos()"
            >
              Alumnos
            </div>
          </div>
          <div class="form-group col-sm-6">
            <div
              class="example-ripple-container mat-elevation-z4"
              matRipple
              [matRippleCentered]="centered"
              [matRippleDisabled]="disabled"
              [matRippleUnbounded]="unbounded"
              [matRippleRadius]="radius"
              [matRippleColor]="color2"
              (click)="goCategorias()"
            >
              Categorias
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <div
              class="example-ripple-container mat-elevation-z4"
              matRipple
              [matRippleCentered]="centered"
              [matRippleDisabled]="disabled"
              [matRippleUnbounded]="unbounded"
              [matRippleRadius]="radius"
              [matRippleColor]="color3"
              (click)="goFacturas()"
            >
              Facturas
            </div>
          </div>
          <div class="form-group col-sm-6">
            <div
              class="example-ripple-container mat-elevation-z4"
              matRipple
              [matRippleCentered]="centered"
              [matRippleDisabled]="disabled"
              [matRippleUnbounded]="unbounded"
              [matRippleRadius]="radius"
              matRippleColor="rgba(255,255,0,.5)"
              (click)="goIE()"
            >
              Ingresos - Egresos
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
