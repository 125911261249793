import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Escuela } from 'src/app/models/escuela.model';
import { EscuelaService } from 'src/app/services/escuela.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/models/usuario.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: [
  ]
})
export class UsuariosComponent implements OnInit {

  displayedColumns = ['correo', 'nombre'];
	dataSource = new MatTableDataSource<any>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
  
  public totalUsuarios: number = 0;
  public usuarios: Usuario[] = [];
  public start: number = 0;
  public cargando = false;
  public escuelas: Escuela[] = [];
  length = 0;
	pageIndex = 0;
	subscriptions = [];

  constructor(public usuarioService: UsuarioService,
    private escuelaService: EscuelaService) { }

  ngOnInit(): void {
    this.cargarUsuarios();
  }

  cargarUsuarios(): void {
    this.cargando = true;
    this.usuarioService.getUsuariosPorEscuela()
      .subscribe(result => {
        this.dataSource.data = result.resultList;
			  this.length = result.total;
			  this.paginator.length = this.length;
      });
  }

  eliminarUsuario(usuario: Usuario) {

    if (usuario.id === this.usuarioService.uid) {
      return Swal.fire('Error', 'No puede borrarse a si mismo', 'error');
    }

    Swal.fire({
      title: '¿Borrar usuario?',
      text: `Esta a punto de borrar a ${usuario.nombre}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {

        this.usuarioService.delete(usuario)
          .subscribe(resp => {

            this.cargarUsuarios();
            Swal.fire(
              'Usuario borrado',
              `${usuario.nombre} fue eliminado correctamente`,
              'success'
            );

          });

      }
    })

  }

  cambiarRole(usuario: Usuario) {

    this.usuarioService.update(usuario)
      .subscribe(resp => {
        console.log(resp);
      })
  }
}
