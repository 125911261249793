import { environment } from '../../environments/environment';
import { Categoria } from './categoria.model';
import { Concepto } from './concepto.model';

const base_url = environment.base_url;

export class CategoriaConcepto {

    constructor(public id: number,
                //public categoria: Categoria, circular reference
                public concepto: Concepto,
                public importe: number
    ) { }
}



export class CategoriaConceptoSimple {

    constructor(public id: number,
                public concepto: number,
                public esInscripcion: boolean,
                public importe: number
    ) { }
}


